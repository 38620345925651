import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BackendHeaderComponent } from './backend-header/backend-header.component';
import { BackendFooterComponent } from './backend-footer/backend-footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {MenuItems} from './backend-sidebar/menu-items';
//backed menu
import {AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective} from './accordion';
import {ToggleFullScreenDirective} from './fullscreen/toggle-fullscreen.directive';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ClickOutsideModule} from 'ng-click-outside';
import { ImageCroppedComponent } from './image-cropped/image-cropped.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CallEditComponent } from './call-edit/call-edit.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import {ReactiveFormsModule} from '@angular/forms';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    BackendHeaderComponent,
    BackendFooterComponent,
    SidebarComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullScreenDirective,
    ImageCroppedComponent,
    CallEditComponent,

  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BackendHeaderComponent,
    BackendFooterComponent,
    SidebarComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ToggleFullScreenDirective,
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    SharedRoutingModule,
    AngularMyDatePickerModule,
    ReactiveFormsModule
  ],
  providers: [
    MenuItems,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  entryComponents:[ImageCroppedComponent,CallEditComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class SharedModule { }