import {
    Component,
    ViewChild,
    ElementRef,
    Output,
    Input,
    EventEmitter,
    Renderer2
} from '@angular/core';
import {
    Participant,
    RemoteTrack,
    RemoteAudioTrack,
    RemoteVideoTrack,
    RemoteParticipant,
    RemoteTrackPublication
} from 'twilio-video';

@Component({
    selector: 'app-participants',
    styleUrls: ['./participants.component.css'],
    templateUrl: './participants.component.html',
})
export class ParticipantsComponent {
    @ViewChild('list', {static: false}) listRef: ElementRef;
    @Output('participantsChanged') participantsChanged = new EventEmitter<boolean>();
    @Output('leaveRoom') leaveRoom = new EventEmitter<boolean>();
    @Output('isAloneSet') isAloneSet = new EventEmitter<boolean>();
    @Input('activeRoomName') activeRoomName: string;
    @Input('screenSharing') screenSharing: boolean;

    get participantCount() {
        return !!this.participants ? this.participants.size : 0;
    }

    get isAlone() {
        this.isAloneSet.emit(this.participantCount == 0);
        return this.participantCount === 0;
    }

    private participants: Map<Participant.SID, RemoteParticipant>;
    private dominantSpeaker: RemoteParticipant;

    constructor(private readonly renderer: Renderer2) { }

    clear() {
        if (this.participants) {
            this.participants.clear();
        }
    }

    initialize(participants: Map<Participant.SID, RemoteParticipant>) {
        this.participants = participants;
        if (this.participants) {
            this.participants.forEach(participant => this.registerParticipantEvents(participant));
        }
    }

    add(participant: RemoteParticipant) {
        if (this.participants && participant) {
            this.participants.set(participant.sid, participant);
            this.registerParticipantEvents(participant);
        }
    }

    remove(participant: RemoteParticipant) {
        if (this.participants && this.participants.has(participant.sid)) {
            this.participants.delete(participant.sid);
        }
    }

    loudest(participant: RemoteParticipant) {
        this.dominantSpeaker = participant;
    }

    onLeaveRoom() {
        this.leaveRoom.emit(true);
    }
    /* onAlone() {
        var aloneCheck = true
        if(this.participantCount === 0){
            aloneCheck = true
        }else{
            aloneCheck = false
        }
        this.isAloneSet.emit(aloneCheck);
    } */

    private registerParticipantEvents(participant: RemoteParticipant) {
        if (participant) {
            participant.tracks.forEach(publication => this.subscribe(publication));
            participant.on('trackPublished', publication => this.subscribe(publication));
            participant.on('trackUnpublished',
                publication => {
                    if (publication && publication.track) {
                        this.detachRemoteTrack(publication.track);
                    }
                });
        }
    }

    private subscribe(publication: RemoteTrackPublication | any) {
        if (publication && publication.on) {
            publication.on('subscribed', (track: RemoteTrack) => this.attachRemoteTrack(track));
            publication.on('unsubscribed', (track: RemoteTrack) => this.detachRemoteTrack(track));
        }
    }

    private attachRemoteTrack(track: RemoteTrack) {
        if (this.isAttachable(track)) {
            console.log("UUUUUUUUUUU",track)
            const element = track.attach();
            element.addEventListener('click', () => { this.zoomTrack(element); });
            this.renderer.data.id = track.sid;
            this.renderer.setStyle(element, 'width', '86%');
            this.renderer.setStyle(element, 'margin-left', '2.5%');
            this.renderer.appendChild(this.listRef.nativeElement, element);
            this.participantsChanged.emit(true);
        }
    }
     zoomTrack(trackElement) {
        let container = document.getElementById('participant-list');
      
        if (!trackElement.classList.contains('participantZoomed')) {
            // zoom in
            container.childNodes.forEach((participant:any) => {
                // console.log("1**",container.childNodes)
                // console.log("2**",participant,participant.className)
                if (participant.className == 'participant') {
                    participant.childNodes[0].childNodes.forEach(track => {
                        // console.log("Prakash",track,track.classList)
                        if (track === trackElement) {
                            //this.renderer.setStyle(track, 'width', 'auto');
                            // 
                            // this.renderer.setStyle(track, 'margin-left', '2.5%');
                            track.classList.add('participantZoomed')
                            // this.renderer.setStyle(track, 'width','100%');
                            // this.renderer.setStyle(track, 'height','100%');
                            this.renderer.setStyle(track, 'margin-left', '0%');
                            this.renderer.setStyle(track, 'width', window.innerWidth+'px');
                            this.renderer.setStyle(track, 'height', window.innerHeight+'px');
                            
                        }
                        else {
                            track.classList.add('participantHidden')
                        }
                    });
                    // participant.childNodes[1].classList.add('participantHidden');
                }
            });
        }
        else {
            // zoom out
            container.childNodes.forEach((participant:any) => {
                if (participant.className == 'participant') {
                    participant.childNodes[0].childNodes.forEach(track => {
                        if (track === trackElement) {
                            this.renderer.setStyle(track, 'width', '86%');
                            this.renderer.setStyle(track, 'margin-left', '2.5%');
                            track.classList.remove('participantZoomed');
                        }
                        else {
                            track.classList.remove('participantHidden');
                        }
                    });
                    participant.childNodes[1].classList.remove('participantHidden');
                }
            });
        }
    };
    

    private detachRemoteTrack(track: RemoteTrack) {
        if (this.isDetachable(track)) {
            console.log("KKKKKKKK",track)
            track.detach().forEach(el => el.remove());
            this.participantsChanged.emit(true);
        }
    }

    private isAttachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
        return !!track &&
            ((track as RemoteAudioTrack).attach !== undefined ||
            (track as RemoteVideoTrack).attach !== undefined);
    }

    private isDetachable(track: RemoteTrack): track is RemoteAudioTrack | RemoteVideoTrack {
        return !!track &&
            ((track as RemoteAudioTrack).detach !== undefined ||
            (track as RemoteVideoTrack).detach !== undefined);
    }
}