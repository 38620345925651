import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { FreelancerserviceService } from '../../../services/freelance-service/freelancerservice.service';

@Component({
  selector: 'app-project-work-history',
  templateUrl: './project-work-history.component.html',
  styleUrls: ['./project-work-history.component.scss']
})
export class ProjectWorkHistoryComponent implements OnInit {

  user_id:any;
  projectId:string ='';
  workListData:any;
  statusList = ['un-paid', 'pending', 'paid'];
  projectsList = ['project 1', 'project 2', 'project 3']
  projectWorkForm: FormGroup;

  constructor(
    private activeRouter: ActivatedRoute,
    private freelancerService: FreelancerserviceService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    var userData = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = userData.id;
    this.projectId = this.activeRouter.snapshot.params['jobId'];

    this.projectWorkForm = this.fb.group({
      freelancer_id: [null, Validators.required],
      project_id: [''],
      paid_status: ['']
    })

    this.getProjectHistory();
    this.getProjects();
  }

  get f() { return this.projectWorkForm.controls; }

  getProjectHistory(){
    console.log('form value before', this.projectWorkForm.value);
    this.projectWorkForm.patchValue({
      freelancer_id: this.user_id,
    })

    if(this.projectId){
      this.projectWorkForm.patchValue({ project_id: this.projectId});
    }

    if(this.projectWorkForm.invalid){
      console.log('invalid form')
      return;
    }

    console.log('form value after', this.projectWorkForm.value);

    this.freelancerService.getUsersAllTimeWork(this.projectWorkForm.value)
    .subscribe(response=> {
      if(response['status']){
        this.workListData = response['data'];
        console.log('list data',this.workListData);
      }
    })
  }

  getProjects(){
    this.freelancerService.getUserProjects(this.user_id).subscribe(response => {
      if(response['status']){
        this.projectsList = response['data'];
        console.log('projects list', this.projectsList);
      }
    })
  }

  dateAgo(date:any){
    const dateTimeAgo = moment(date).fromNow();
    console.log('date ago',dateTimeAgo);
    return dateTimeAgo;
  }

  TestPage() {
    window.scroll(0, 0);
  }

}
