import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { UserService } from '../../services/user.service';
import { AuthenticationService } from '../../services';
@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  loginCUser:any;
  curr_role_id:any;
  returnUrl: string='';

  constructor(private router: Router, private route: ActivatedRoute,
    private userService : UserService, private authenticationService: AuthenticationService) {

        this.router.routeReuseStrategy.shouldReuseRoute = function() {
            return false;
        };
        // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) { 
        //debugger;
        //this.loginCUser=this.authenticationService.currentUserValue;
        //console.log(this.loginCUser);
        //this.loginCUser      = JSON.parse(this.loginCUser);
        //console.log(this.loginCUser);
        
        // this.curr_role_id = (this.loginCUser['roles'] ? this.loginCUser['roles'][0] : '0');
        // console.log("current role",this.curr_role_id);
        // if(this.curr_role_id==1 || this.curr_role_id=="ROLE_ADMIN"){
        //     this.router.navigate(['admin/dashboard']); //super admin
        // }
        // else if(this.curr_role_id==3 || this.curr_role_id=="ROLE_EMPLOYER"){
        //     // this.router.navigate(['employer/dashboard']); //employer
        //     this.getEmployerProgress(this.loginCUser['id']);
        // }
        // else if(this.curr_role_id=="ROLE_FREELANCER"){  //console.log('Freelancer');
        //     // this.router.navigate(['freelancer/user_profile']); //freelancer
        //     this.getFreelancerProgress(this.loginCUser['id']);
        // }
        // else if(this.curr_role_id=="ROLE_JOBSEEKER"){  //console.log('Freelancer');
        //     // this.router.navigate(['freelancer/user_profile']); //freelancer
        //     this.getJobseekerProgress(this.loginCUser['id']);
        // }
        // else if(this.curr_role_id=="ROLE_FREELANCER AGENCY"){  //console.log('Freelancer');
        //     // this.router.navigate(['freelancer/user_profile']); //freelancer
        //     this.getAgencyProgress(this.loginCUser['id']);
        // }
        // else{
        //     this.router.navigate(['/']); //Home
        // }
      }
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    if(this.route.snapshot.queryParams['returnUrl']){
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
      console.log(this.route.snapshot);
    }
  }
  getJobseekerProgress(id){
    // debugger
    this.userService.getUserProfileProgress({user_id : id}).subscribe(data=>{
      if(data['status']){
        /* if(data['data']['resume_completed']==false)
            this.router.navigate(['/authentication/resume/'+id]);
        else if(data['data']['application_completed']==false)
            this.router.navigate(['/authentication/resume/'+id]);
        else */ if(
            data['data']['step1_completed']==false ||
            // data['data']['step1a_completed']==false ||
            data['data']['step2_completed']==false ||
            data['data']['step3_completed']==false ||
            data['data']['step4_completed']==false ||
            data['data']['step5_completed']==false  ||
            data['data']['step6_completed']==false  ||
            data['data']['step7_completed']==false
            )
            this.router.navigate(['/authentication/jobseekerprofile/'+id]);
        else{
          console.log(this.returnUrl)
          // this.router.navigate(['jobseeker/dashboard'])
            if(this.returnUrl!=='')
                this.router.navigate([this.returnUrl]);
            else
                this.router.navigate(['jobseeker/dashboard'])
            // this.router.navigate(['freelancer/user_profile'])
        }
      }
    })
}
getFreelancerProgress(id){
    this.userService.getUserProfileProgress({user_id : id}).subscribe(data=>{
      if(data['status']){
        /* if(data['data']['resume_completed']==false)
            this.router.navigate(['/authentication/resume/'+id]);
        else if(data['data']['application_completed']==false)
            this.router.navigate(['/authentication/resume/'+id]);
        else */ if(
            data['data']['step1_completed']==false ||
            data['data']['step1a_completed']==false ||
            data['data']['step2_completed']==false ||
            data['data']['step3_completed']==false ||
            data['data']['step4_completed']==false ||
            data['data']['step5_completed']==false  ||
            data['data']['step6_completed']==false  ||
            data['data']['step7_completed']==false
            )
            this.router.navigate(['/freelancer/yourprofile/'+id]);
        else{
             if(this.returnUrl!=='')
                this.router.navigate([this.returnUrl]);
            else
                this.router.navigate(['freelancer/dashboard'])
            // this.router.navigate(['freelancer/user_profile'])
        }
      }
    })
}
getAgencyProgress(id){
    this.userService.getUserProfileProgress({user_id : id}).subscribe(data=>{
      if(data['status']){
        /* if(data['data']['resume_completed']==false)
            this.router.navigate(['/authentication/resume/'+id]);
        else if(data['data']['application_completed']==false)
            this.router.navigate(['/authentication/resume/'+id]);
        else */ if(
            data['data']['step1_completed']==false ||
            // data['data']['step1a_completed']==false ||
            //data['data']['step2_completed']==false ||
            //data['data']['step3_completed']==false ||
            //data['data']['step4_completed']==false ||
            data['data']['step5_completed']==false
            //data['data']['step6_completed']==false  ||
            // data['data']['step7_completed']==false
            )
            this.router.navigate(['/authentication/agencyprofile/'+id]);
        else{
             if(this.returnUrl!=='')
                this.router.navigate([this.returnUrl]);
            else
                this.router.navigate(['freelancer-agency/dashboard'])
            // this.router.navigate(['freelancer/user_profile'])
        }
      }
    })
}
getProgress(id){
    this.userService.getUserProfileProgress({user_id : id}).subscribe(data=>{
      if(data['status']){
        if(data['data']['resume_completed']==false)
            this.router.navigate(['/authentication/resume/'+id]);
        else if(data['data']['application_completed']==false)
            this.router.navigate(['/authentication/resume/'+id]);
        else if(
            data['data']['step1_completed']==false ||
            data['data']['step2_completed']==false ||
            data['data']['step3_completed']==false ||
            data['data']['step4_completed']==false ||
            data['data']['step4a_completed']==false ||
            data['data']['step5_completed']==false
            )
            this.router.navigate(['/authentication/yourprofile/'+id]);
        else{
             if(this.returnUrl!=='')
                this.router.navigate([this.returnUrl]);
            else
                this.router.navigate(['freelancer/user_profile'])
            // this.router.navigate(['freelancer/user_profile'])
        }
      }
    })
}

  getEmployerProgress(id){
    this.userService.getEmployerProfileProgress({user_id : id}).subscribe(data=>{
        console.log(data);
        if(data['status']){
            if(!data['data']['profile_step_1'] || !data['data']['profile_step_2'] || !data['data']['profile_step_3'])
                this.router.navigate(['authentication/companyprofile/'+id]);
            //   else if(!data['data']['account_step_1'])
            //   this.router.navigate(['authentication/companyaccount/'+id]);
            else{
                if(this.returnUrl!=='')
                    this.router.navigate([this.returnUrl]);
                else
                    this.router.navigate(['employer/dashboard']);
            }
        }
      })
}

}
