import { connect, ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject , Observable } from 'rxjs';

interface AuthToken {
  token: string;
}

export interface NamedRoom {
  id: string;
  name: string;
  maxParticipants?: number;
  participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable({
  providedIn: 'root'
})
export class VideochatService {
  
  $roomsUpdated: Observable<boolean>;

  private roomBroadcast = new ReplaySubject<boolean>();

  constructor(private readonly http: HttpClient) {
      this.$roomsUpdated = this.roomBroadcast.asObservable();
  }

  private async getAuthToken(name) {
      const auth =
          await this.http
                    // .get<AuthToken>(`api/video/token`)
                    .get<AuthToken>(`http://localhost:5000/rooms/generate-token/${name}`)
                    .toPromise();
//  {'token' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzkyN2EyNzI4YTc0NWIwMjliZjQ1ZGRmN2FmNmMxZDU4LTE2MjMwNTQ2OTkiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJ1c2VyIiwidmlkZW8iOnsicm9vbSI6ImNvb2wgcm9vbSJ9fSwiaWF0IjoxNjIzMDU0Njk5LCJleHAiOjE2MjMwNTgyOTksImlzcyI6IlNLOTI3YTI3MjhhNzQ1YjAyOWJmNDVkZGY3YWY2YzFkNTgiLCJzdWIiOiJBQ2IwMGI3ZWRiNzdmMmQwMjI3NTA5MDMzMTUzYzhmMjE0In0.9l_53dX4863VKrmLfVJNCGEKCGbOifS3MlHn72nnsCQ'}
      return auth.token;
  }

  getAllRooms() {
      return this.http
                //  .get<Rooms>('api/video/rooms')
                 .get<Rooms>('http://localhost:5000/rooms')
                 .toPromise();
  }

  async joinOrCreateRoom(name: string, tracks: LocalTrack[]) {
      let room: Room = null;
      try {
          const token = await this.getAuthToken(name);
          room =
              await connect(
                  token, {
                      name,
                      tracks,
                      dominantSpeaker: true
                  } as ConnectOptions);
      } catch (error) {
          console.error(`Unable to connect to Room: ${error.message}`);
      } finally {
          if (room) {
              this.roomBroadcast.next(true);
          }
      }

      return room;
  }

  nudge() {
      this.roomBroadcast.next(true);
  }
}