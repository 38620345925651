import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin-service/admin.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  aboutUs:any;

  constructor(private adminService:AdminService) { }

  ngOnInit() {
    // this.http.get(environment.apiUrl+ 'admin/about_us/get_about_us_details')
    this.adminService.getAboutUs().subscribe(data =>{
      if(data['status']){
        this.aboutUs = data['data'];
        document.getElementById('contentHere').innerHTML = this.aboutUs.content
        console.log(this.aboutUs);
      }
    })
  }

}
