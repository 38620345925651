import { HomeComponent } from './pages/twilio/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import { BackendLayoutComponent } from './layouts/backend-layout/backend-layout.component';
import { BACKEND_LAYOUT } from './routes/backend-layout-routes';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { DEFAULT_ROUTES } from './routes/default-layout-routes';
import { EmployerLayoutComponent } from './layouts/employer-layout/employer-layout.component';
import { EMPLOYER_LAYOUT } from './routes/employer-layout-routes';
import { FreelancerLayoutComponent } from './layouts/freelancer-layout/freelancer-layout.component';
import { FREELANCER_LAYOUT } from './routes/freelancer-layout-routes';
import { JOBSEEKER_LAYOUT } from './routes/jobseeker-layout-routes';
import {FREELANCER_AGENCY_LAYOUT} from './routes/freelancer-agency-layout-routes';
//import { AuthGuard } from './helpers';
import { Role } from './models';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', component: DefaultLayoutComponent, children: DEFAULT_ROUTES },
  { path: 'admin', component: BackendLayoutComponent,  children: BACKEND_LAYOUT },
  { path: 'employer', component: EmployerLayoutComponent,  children: EMPLOYER_LAYOUT },
  { path: 'freelancer', component: FreelancerLayoutComponent,  children: FREELANCER_LAYOUT },
  { path: 'jobseeker',  component: FreelancerLayoutComponent, children:JOBSEEKER_LAYOUT },
  { path: 'twilio', component: HomeComponent },
  { path: '404', component: PageNotFoundComponent},
  // { path: '**', redirectTo: '/404' },

  { path: 'freelancer-agency', component: FreelancerLayoutComponent, children:FREELANCER_AGENCY_LAYOUT },
  { path: 'video', loadChildren: () => import('./pages/video/video.module').then(m => m.VideoModule) },
  { path: 'video-calander', loadChildren: () => import('./pages/video-calander/video-calander.module').then(m => m.VideoCalanderModule) },
];

/* const routes: Routes = [
{
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'authentication/login',
        pathMatch: 'full',
      },
      {
        path: 'authentication',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      }
    ] 
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard-default/dashboard-default.module').then(m => m.DashboardDefaultModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'role',
        loadChildren: () => import('./pages/roles/role.module').then(m => m.RoleModule),
        canActivate: [AuthGuard]
      },
       
      {
        path: 'header',
        loadChildren: () => import('./pages/header/header.module').then(m => m.HeaderModule),
        canActivate: [AuthGuard]
      },
      
      {
        path: 'study-exam',
        loadChildren: () => import('./pages/study-exam/question.module').then(m => m.QuestionModule),canActivate: [AuthGuard]
      },
      {
        path: 'special',
        loadChildren: () => import('./pages/special-competition/special-competition.module').then(m => m.SpecialCompetitionModule),
        //canActivate: [AuthGuard]
      },
      
      {
        path: 'mobile-users',
        loadChildren: () => import('./pages/mobile-users/mobile-users.module').then(m => m.MobileUsersModule),
        //canActivate: [AuthGuard]
      },
      {
      path: 'sub-category',
        loadChildren: () => import('./pages/subcategory/subcategory.module').then(m => m.SubCategoryModule),
      },
      
      {
        path: 'question',
        loadChildren: () => import('./pages/question/question.module').then(m => m.QuestionModule),
      },
       {
        path: 'users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
      },
      
      {
        path: 'masterdata',
        loadChildren: () => import('./pages/masterdata/masterdata.module').then(m => m.MasterdataModule),
      },
      

       {
        path: 'basic',
        loadChildren: () => import('./pages/ui-elements/basic/basic.module').then(m => m.BasicModule)
      }, {
        path: 'notifications',
        loadChildren: () => import('./pages/ui-elements/advance/notifications/notifications.module').then(m => m.NotificationsModule)
      }, {
        path: 'bootstrap-table',
        loadChildren: () => import('./pages/ui-elements/tables/bootstrap-table/basic-bootstrap/basic-bootstrap.module').then(m => m.BasicBootstrapModule),
      }, {
        path: 'map',
        loadChildren: () => import('./pages/map/google-map/google-map.module').then(m => m.GoogleMapModule),
      }, {
        path: 'user',
        loadChildren: () => import('./pages/user/profile/profile.module').then(m => m.ProfileModule)
      }, {
        path: 'simple-page',
        loadChildren: () => import('./pages/simple-page/simple-page.module').then(m => m.SimplePageModule)
      },
       {
        path: 'setting',
        loadChildren: () => import('./pages/setting/setting.module').then(m => m.SettingModule),
      },
    ]
  },
   // otherwise redirect to home
  { path: '**', redirectTo: '' }
  
]; */

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
