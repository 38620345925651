import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin-service/admin.service';
import { UserService } from '../../../services/user.service';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  privacyPolicy:any;

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.adminService.getPrivacyPolicy().subscribe(data =>{
      if(data['status']){
        this.privacyPolicy = data['data'];
        document.getElementById('privacyPolicy').innerHTML = this.privacyPolicy.content
        console.log(this.privacyPolicy);
      }
    })
  }

}
