import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import 'rxjs/add/operator/filter';

@Component({
  selector: 'app-title',
  template: '<span></span>'
})
export class TitleComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, private titleService: Title) {
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .subscribe(() => {
        let currentRoute = this.route.root;
        let title = '';
        let titles = '';
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach(routes => {
            if (routes.outlet === 'primary') {
              titles = routes.snapshot.data.breadcrumb;
               title = titles && titles[0].toUpperCase() + titles.slice(1)
               console.log(title);
              currentRoute = routes;
            }
          });
        } while (currentRoute);
        this.titleService.setTitle( title  + ' | Remotelly');
      });
  }

  ngOnInit() {
  }

}
