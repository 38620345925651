import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Signup } from '../../models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }
  getAboutUs() {
    return this.http.get(environment.apiUrl + 'admin/about_us/get_about_us_details');
  }

  createAndUpdateAboutUs(formData) {
    return this.http.post(environment.apiUrl + 'admin/about_us/add_about_us', formData);
  }

  getPrivacyPolicy() {
    return this.http.get(environment.apiUrl + 'admin/privacy_policy/get_privacy_policy_details');
  }

  createAndUpdatePrivacyPolicy(formData) {
    return this.http.post(environment.apiUrl + 'admin/privacy_policy/add_privacy_policy', formData);
  }

  getTermsAndConditions() {
    return this.http.get(environment.apiUrl + 'admin/terms_and_conditions/get_terms_and_conditions_details');
  }

  createAndUpdateTermsAndConditions(formData) {
    return this.http.post(environment.apiUrl + 'admin/terms_and_conditions/add_terms_and_conditions', formData);
  }

  getWhyRemotellyContent() {
    return this.http.get(environment.apiUrl + 'admin/why_remotelly/get_why_remotelly_details');
  }

  createAndUpdateWhyRemotellyContent(formData) {
    return this.http.post(environment.apiUrl + 'admin/why_remotelly/add_why_remotelly', formData);
  }

  getServices() {
    return this.http.get(environment.apiUrl + 'admin/services/get_services_details');
  }

  createAndUpdateServices(formData) {
    return this.http.post(environment.apiUrl + 'admin/services/add_services', formData);
  }

  getAllActiveBlogs() {
    return this.http.get(environment.apiUrl + 'admin/blogs_and_articals/get_all_active_blogs_and_articals_list');
  }

  getBlogDetails(formData) {
    return this.http.post(environment.apiUrl + 'admin/blogs_and_articals/get_one_blogs_and_articals_details', formData);
  }
  getallblogsandarticalslist(){
    return this.http.get(environment.apiUrl + 'admin/blogs_and_articals/get_all_blogs_and_articals_list');
  }
  changeActiveStatus(formData) {
    return this.http.post(environment.apiUrl + 'admin/blogs_and_articals/change_active_status', formData);
  }
  deleteblogsandarticals(formData){
    return this.http.post(environment.apiUrl + 'admin/blogs_and_articals/delete_blogs_and_articals', formData);
  }
  addblogsandarticals(formData){
    return this.http.post(environment.apiUrl + 'admin/blogs_and_articals/add_blogs_and_articals', formData)
  }

  // faq api's

  getAllFaqs(){
    return this.http.get(environment.apiUrl + "admin/faq/get-all-faqs");
  }

  getOneFaqDetails(id:string){
    return this.http.get(environment.apiUrl + "admin/faq/get-one-faq-details/" + id);
  }

  addFaq(formData:Object){
    return this.http.post(environment.apiUrl + "admin/faq/addFaq", formData);
  }

  updateFaq(formData: Object) {
    return this.http.post(environment.apiUrl + "admin/faq/updateFaq", formData);
  }
  
  deleteFaq(id:string) {
    return this.http.delete(environment.apiUrl + "admin/faq/delete-one-faq-details/" + id);
  }

  // faq api's end

  getServiceCost(){
    return this.http.get(environment.apiUrl + "masterdata/get_service_cost/" + 1);
  }

  updateServiceCost(formData:Object){
    return this.http.post(environment.apiUrl + "masterdata/update_service_cost",formData);
  }

}

