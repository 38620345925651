import { Routes } from '@angular/router';
import { AuthGuard } from '../helpers';

export const JOBSEEKER_LAYOUT: Routes = 
[
  {
    path: 'user_profile',
    loadChildren: () => import('../pages/jobseeker/userprofile/userprofile.module').then(m => m.UserprofileModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'user_profile/:preview',
    loadChildren: () => import('../pages/jobseeker/userprofile/userprofile.module').then(m => m.UserprofileModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'user_profile/:preview/:userId',
    loadChildren: () => import('../pages/jobseeker/userprofile/userprofile.module').then(m => m.UserprofileModule),
    // canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../pages/jobseeker/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'profilesetting',
    // loadChildren: () => import('../pages/jobseeker/user-settings/user-settings.module').then(m => m.UserSettingsModule),
    loadChildren: () => import('../pages/freelancer/usersetting/usersetting.module').then(m => m.UsersettingModule),

    // canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'profilesetting/:route',
    // loadChildren: () => import('../pages/jobseeker/user-settings/user-settings.module').then(m => m.UserSettingsModule),
    loadChildren: () => import('../pages/freelancer/usersetting/usersetting.module').then(m => m.UsersettingModule),

    // canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'viewjobdetails/:id',
    loadChildren: () => import('../pages/jobseeker/jobsearch/viewjobdetails/viewjobdetails/viewjobdetails.module').then(m => m.ViewjobdetailsModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'editproposal/:jobId/:Id',
    loadChildren: () => import('../pages/jobseeker/jobsearch/editproposal/editproposal/editproposal.module').then(m => m.EditproposalModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'jobseekerjobapply',
    loadChildren: () => import('../pages/jobseeker/jobsearch/freelancerjobapply/freelancerjobapply.module').then(m => m.FreelancerjobapplyModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'savedprojects',
    loadChildren: () => import('../pages/jobseeker/jobsearch/savedjobs/savedjobs.module').then(m => m.SavedjobModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'myjobs',
    loadChildren: () => import('../pages/jobseeker/jobsearch/savedsecondaryjobs/savedsecondaryjobs.module').then(m => m.SavedsecondaryjobsModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'yourprofile/:userId/:sectionId',
    loadChildren: () => import('../pages/auth/jobseekerprofile/jobseekerprofile.module').then(m => m.JobseekerprofileModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'yourprofile/:userId/:sectionId/:idToFetch',
    loadChildren: () => import('../pages/auth/jobseekerprofile/jobseekerprofile.module').then(m => m.JobseekerprofileModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'otherdetails/:userId',
    loadChildren: () => import('../pages/freelancer/otherdetails/otherdetails.module').then(m => m.OtherdetailsModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'otherdetails/:userId/:otherId',
    loadChildren: () => import('../pages/freelancer/otherdetails/otherdetails.module').then(m => m.OtherdetailsModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'editportfolio/:userId/:otherId',
    loadChildren: () => import('../pages/freelancer/editportfolio/editportfolio.module').then(m => m.EditportfolioModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'edit-other-experience/:userId/:otherId',
    loadChildren: () => import('../pages/freelancer/editotherexperiences/editotherexperiences.module').then(m => m.EditotherexperiencesModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'edit-certification/:userId/:otherId',
    loadChildren: () => import('../pages/freelancer/editcertification/editcertification.module').then(m => m.EditcertificationModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'search',
    loadChildren: () => import('../pages/jobseeker/search/search.module').then(m => m.SearchModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'search/:type/:searchId',
    loadChildren: () => import('../pages/jobseeker/search/search.module').then(m => m.SearchModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'search/:country_names/:keywords/:skills',
    loadChildren: () => import('../pages/jobseeker/search/search.module').then(m => m.SearchModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  { 
    path: 'company-jobs/:employer_id',
    loadChildren: () => import('../pages/jobseeker/company-jobs/company-jobs.module').then(m => m.CompanyJobsModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: 'companyprofile/:employer_id/:view',
    loadChildren: () => import('../pages/employer/companyprofile/companyprofile.module').then(m => m.CompanyprofileModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
  {
    path: ':profile_id',
    loadChildren: () => import('../pages/jobseeker/userprofile/userprofile.module').then(m => m.UserprofileModule),
    //canActivate : [AuthGuard],
    data: { roles: ["ROLE_JOBSEEKER"] }
  },
]