import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    currentUserValue:any;
    curr_role_id:any;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        
        const snapshot: RouterStateSnapshot = router.routerState.snapshot;
     }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) { 
        //var currentUserValue = this.authenticationService.currentUserValue;
        //this.currentUserValue = JSON.parse(JSON.stringify(currentUserValue)); // JSON.parse(this.currentUserValue);
        var currentUserValue=this.authenticationService.currentUserValue;
        this.currentUserValue = JSON.parse(JSON.stringify(currentUserValue));
        
        //this.curr_role_id =this.currentUserValue['roles'][0];

        if (this.authenticationService.currentUserValue) { 
            //debugger;
            this.currentUserValue= JSON.parse(localStorage.getItem('currentUser'));
            //this.currentUserValue      = JSON.parse(this.currentUserValue);
            //console.log(this.currentUserValue); 
            this.curr_role_id =this.currentUserValue['roles'][0];
            //console.log(this.curr_role_id);
            
            if (route.data.roles && this.currentUserValue && (route.data.roles[0] !== this.curr_role_id)) {
                // role not authorised so redirect to home page
                this.router.navigate(['/']);
                return false;
            }
            // authorised so return true
            // logged in so return true
            return true;
        }

        //console.log('currentUser', this.currentUserValue)
        // if (this.currentUserValue) {
        //     // check if route is restricted by role
        //     //console.log('route.data', route.data.roles[0]);
        //     //console.log('this.currentUserValue', this.currentUserValue['roles'][0]);
        //     //if (route.data.roles && route.data.roles.indexOf(this.currentUserValue['roles'][0]) === -1) {
        //     if (route.data.roles && this.currentUserValue && (route.data.roles[0] !== this.currentUserValue['roles'][0])) {
        //         // role not authorised so redirect to home page
        //         this.router.navigate(['/']);
        //         return false;
        //     }

        //     // authorised so return true
        //     // logged in so return true
        //     return true;
        // }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}