import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-all-jobs',
  templateUrl: './all-jobs.component.html',
  styleUrls: ['./all-jobs.component.scss']
})
export class AllJobsComponent implements OnInit {

  allJobsData = [];

  isDesc: boolean = false;
  column: string = 'id';
  direction: number;

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100, 150];
  term: string;

  jobTypeList = ['posted', 'draft']
  type = '';

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private titleService: Title,
    private router: Router
  ) { }

  ngOnInit() {
    this.titleService.setTitle('All Jobs | Remotelly');
    this.getAllJobs();
  }

  getAllJobs() {
    console.log(this.type);
    this.userService.getAllJobs(this.type)
      .subscribe(data => {
        if (data['status']) {
          this.allJobsData = data['data'];
          console.log(this.allJobsData);
        }
      })
  }

  filterDataAccordingToJobType(val) {
    this.type = val;
    console.log("selected type", this.type);
    this.getAllJobs()
  }

  jobDetails(jobId){
    console.log(jobId);
    this.router.navigate([`admin/jobs/details/${jobId}`]);
  }

  sort(property) {
    console.log(property);
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  handlePageChange(event): void {
    window.scroll(0, 0);
    this.page = event;
    this.getAllPage();
  }

  handlePageSizeChange(event): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getAllPage();
  }

  getAllPage() {
    let offset = (this.page - 1) * this.pageSize;
    const params = this.getRequestParams(this.term);
    this.userService.getAllPage(offset, this.pageSize, params)
      .subscribe(result => {
        console.log("result");
      })
  }

  getRequestParams(searchText): any {
    let params = {};

    if (searchText) {
      params[`searchText`] = searchText;
    }
    else {
      params[`searchText`] = 0;
    }


    return params;
  }

  searchData(event) {
    //  this.term=event.target.value
    this.page = 1;
    //  console.log("d",this.term)
    //  this.getAllPage();
  }

  replaceContent(html: string) {
    const tmp = document.createElement('DIV');
    let textData = '';
    if (html.length > 50) {
      textData = html.slice(0, 50)
    } else {
      textData = html
    }
    tmp.innerHTML = textData;
    return tmp.textContent || tmp.innerText || '';
  }

}
