import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

userData:any;
employerBalance:any;
appointmentDetails:any;
detectChangeInUserData: Subject<any> = new Subject<any>();
detectChangeInEmployerBalance: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,

  ) {
   }

   updateUserDataValue() {
    this.detectChangeInUserData.next(this.userData);
  }

  updateEmployerBalanceData(){
    this.detectChangeInEmployerBalance.next(this.employerBalance);
  }

  userProfile(user_id) {
     this.http.get<any>(environment.apiUrl+'user/user_profile/'+user_id).subscribe(data => {
      if(data['status'])
      this.userData = data['data'];
      this.updateUserDataValue()
     })
  }

  employerProfileData(user_id){
    this.http.get<any>(environment.apiUrl+ 'user/employer_profile/'+user_id).subscribe(data => {
      if(data['status']){
        this.employerBalance = data['data'];
        this.updateEmployerBalanceData()
      }
    })
  }
}
