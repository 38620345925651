import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
import { User } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(JSON.stringify(localStorage.getItem('currentUser'))));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }


    /* login(email: string, password: string) {
        return this.http.post<any>(environment.apiUrl+'user/login', { email, password })
            .pipe(map(user => 
            {
                if(user.status){
                    //user.authdata = window.btoa(email + ':' + password);
                    localStorage.setItem('currentUser', JSON.stringify(user.data));
                    this.currentUserSubject.next(user.data);
                    return true;
                }
                else{
                    return false;
                }
            }));
    } */
    login(username: string, password: string, is_keep_login) {
        // console.log('before user');
        localStorage.removeItem('currentUser');
        sessionStorage.removeItem("userRole");
        this.currentUserSubject.next(null);
        return this.http.post<any>(`${environment.apiUrl}auth/signin`, { username, password, is_keep_login })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user));
                //this.userSubject.next(user);
                //return user;
                // console.log('auth user', user);
                if(user.status){
                    // console.log('userstat');
                    //user.authdata = window.btoa(email + ':' + password);
                    sessionStorage.setItem("userRole", user.roles[0]);
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.getLoggedInName.emit(JSON.stringify(user));
                    this.currentUserSubject.next(user);

                    return user;
                }
                else{
                    this.getLoggedInName.emit(null);
                    return user;
                }
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        sessionStorage.removeItem("userRole");
        this.getLoggedInName.emit(null);
        this.currentUserSubject.next(null);
        this.router.navigate(['/login']);
    }

    loginForMobile(user_id: string) {
        // console.log('before user');
        localStorage.removeItem('currentUser');
        sessionStorage.removeItem("userRole");
        this.currentUserSubject.next(null);
        return this.http.post<any>(`${environment.apiUrl}auth/get-user-details`, { user_id })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user));
                //this.userSubject.next(user);
                //return user;
                // console.log('auth user', user);
                if(user.status){
                    // console.log('userstat');
                    //user.authdata = window.btoa(email + ':' + password);
                    sessionStorage.setItem("userRole", user.roles[0]);
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.getLoggedInName.emit(JSON.stringify(user));
                    this.currentUserSubject.next(user);

                    return user;
                }
                else{
                    this.getLoggedInName.emit(null);
                    return user;
                }
            }));
    }

}