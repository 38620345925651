import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  if(!localStorage['currentUser']){
   return next.handle(req);
  }
    const token =JSON.parse(localStorage['currentUser']).accessToken; 
    // you probably want to store it in localStorage or something
    //console.log('token----->>>'+token)
    if (!token) {
      return next.handle(req);
    }

    const req1 = req.clone({
      headers: req.headers.set('x-access-token', token),
    });
    return next.handle(req1);
  }

}