import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent,LoadedImage} from 'ngx-image-cropper';
@Component({
  selector: 'app-image-cropped',
  templateUrl: './image-cropped.component.html',
  styleUrls: ['./image-cropped.component.scss']
})
export class ImageCroppedComponent implements OnInit {
  @Input() public event;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  base64Output: any;
  fileToReturn:any;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    console.log(this.event);
    this.imageChangedEvent=this.event;
  }

  fileChangeEvent(event: any): void {
    console.log("***",event)
    this.imageChangedEvent = event;
}
submit(){
  this.activeModal.close(this.fileToReturn);
}
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    )
    console.log("fi",this.fileToReturn)
  }
  base64ToFile(data, filename) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


imageLoaded(image: LoadedImage) {
    // show cropper
}
cropperReady() {
    // cropper ready
}
loadImageFailed() {
    // show message
}
}
