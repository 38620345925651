import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CardDetailsService {

cardData:any = [];

  constructor(
    private http: HttpClient,
    private userService:UserService
  ) { }

  getCards(userID) {
    // this.http.get(environment.apiUrl + 'user/get_user_allbilling_card/' + userID+ '/' + 100)
    this.userService.getUserCardDetails(userID).subscribe(data => {
      if (data['status'] == true) {
        this.cardData = data['data'];
      }
    })
  }

}
