import { NgModule } from '@angular/core';
import { OrderrByPipe } from "./orderby.pipe";
import { FormatTitlePipe } from "./format-title.pipe";
import { Format } from "./format";
import { OrderBy } from "./orderBy";
import { CustomPipe } from "./custom.pipe";
import { SanitizerUrlPipe } from "./sanitize-url.pipe";
import { DateAgoPipe } from './date-ago.pipe';
import { SearchPipe } from './search.pipe';
import { ProjectsListingPipe } from './projects-listing.pipe';
import{FreelancerProjectsReportsPipe} from './freelancerproject-report.pipe';
import{FreelancerProjectsVideoReportsPipe} from './freelancerprojectvideo-report.pipe';
import { JobsListingPipe } from './jobs-listing.pipe';
import { EmployeerProjectsReportsPipe } from './employeerproject-report.pipe';
import { EmployeerProjectsVideoReportsPipe } from './employeerprojectvideo-report.pipe';

@NgModule({
  declarations: [OrderrByPipe, FormatTitlePipe, Format, OrderBy,CustomPipe, SanitizerUrlPipe, DateAgoPipe, SearchPipe, ProjectsListingPipe,FreelancerProjectsReportsPipe,FreelancerProjectsVideoReportsPipe,EmployeerProjectsReportsPipe,EmployeerProjectsVideoReportsPipe,JobsListingPipe],
  imports: [],
  exports: [OrderrByPipe, FormatTitlePipe, Format, OrderBy, CustomPipe, SanitizerUrlPipe, DateAgoPipe, SearchPipe, ProjectsListingPipe,FreelancerProjectsReportsPipe,FreelancerProjectsVideoReportsPipe,EmployeerProjectsReportsPipe,EmployeerProjectsVideoReportsPipe, JobsListingPipe]
})
export class PipesModule {}