import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  menukey: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Navigation',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-home',
        menukey:'dashbiard',
      },
      {
        state: 'informaticpages',
        short_label: 'R',
        name: 'Informatic Pages',
        type: 'sub',
        icon: 'ti-info',
        menukey:'informaticpages',
        children:[
          {
            state: 'blogs',
            short_label: 'ms',
            name: 'Blogs',
            type: 'link',
            icon: 'ti-book',
            menukey:'blogs'
          },
          {
            state: 'aboutus',
            short_label: 'ms',
            name: 'About Us',
            type: 'link',
            icon: 'ti-book',
            menukey:'aboutus'
          },
          {
            state: 'contactus',
            short_label: 'ms',
            name: 'Contact Us',
            type: 'link',
            icon: 'ti-book',
            menukey:'contactus'
          },
          {
            state: 'privacypolicy',
            short_label: 'ms',
            name: 'Privacy Policy',
            type: 'link',
            icon: 'ti-book',
            menukey:'privacypolicy'
          },
          {
            state: 'termsandconditions',
            short_label: 'ms',
            name: 'Terms & Conditions',
            type: 'link',
            icon: 'ti-book',
            menukey:'termsandconditions'
          },
          {
            state: 'services',
            short_label: 'ms',
            name: 'Services',
            type: 'link',
            icon: 'ti-book',
            menukey:'services'
          },
          {
            state: 'whyremotelly',
            short_label: 'ms',
            name: 'Why Remotelly',
            type: 'link',
            icon: 'ti-book',
            menukey:'whyremotelly'
          },
          {
            state: 'faq',
            short_label: 'ms',
            name: 'Faq',
            type: 'link',
            icon: 'ti-book',
            menukey: 'faq'
          }
        ]
      },
      // {
      //   state: 'role',
      //   short_label: 'R',
      //   name: 'Role',
      //   type: 'link',
      //   icon: 'ti-files',
      //   menukey:'view-role'
      // },
      // {
      //   state: 'permissions',
      //   short_label: 'P',
      //   name: 'Permissions',
      //   type: 'link',
      //   icon: 'ti-files',
      //   menukey:'permissions'
      // },

      {
        state: 'users',
        short_label: 'U',
        name: 'Users',
        type: 'link',
        icon: 'ti-user',
        menukey:'view-users'
      },
      {
        state: 'payment',
        short_label: 'P',
        name: 'Payment',
        type: 'link',
        icon: 'ti-money',
        menukey: 'payment'
      },
      {
        state: 'projects',
        short_label: 'P',
        name: 'Projects',
        type: 'link',
        icon: 'ti-agenda',
        menukey: 'projects'
      },
      {
        state: 'jobs',
        short_label: 'P',
        name: 'Jobs',
        type: 'link',
        icon: 'ti-briefcase',
        menukey: 'jobs'
      },
      {
        state: 'masterdata',
        short_label: 'B',
        name: 'Master Data',
        type: 'sub',
        icon: 'ti-layout-grid2-alt',
        menukey:'view-basic',
        children: [
          {
            state: 'country',
            short_label: 'ms',
            name: 'Country',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-country'
          },
          // {
          //   state: 'state',
          //   short_label: 'ms',
          //   name: 'State',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-state'
          // },
          {
            state: 'city',
            short_label: 'ms',
            name: 'City',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-city'
          },
          {
            state: 'service',
            short_label: 'mc',
            name: 'Services',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-service'
          },
          {
            state: 'category',
            short_label: 'mc',
            name: 'Category',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-category'
          },
          {
            state: 'sub-category',
            short_label: 'mc',
            name: 'Sub Categories',
            type: 'link',
            icon: 'ti-book',
            menukey:'sub_category'
          },
          {
            state: 'skill',
            short_label: 'mc',
            name: 'Skills',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-skill'
          },
          // {
          //   state: 'experience',
          //   short_label: 'mc',
          //   name: 'Experience',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-experience'
          // },
          {
            state: 'school',
            short_label: 'mc',
            name: 'School',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-school'
          },
          {
            state: 'areas-of-study',
            short_label: 'mc',
            name: 'Areas of Study',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-areas-of-study'
          },
          {
            state: 'degree',
            short_label: 'mc',
            name: 'Degrees',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-degree'
          },
          // {
          //   state: 'project-type',
          //   short_label: 'mc',
          //   name: 'Interested Project Types',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'project_type'
          // // },
          // {
          //   state: 'certification',
          //   short_label: 'mc',
          //   name: 'Certifications',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-certification'
          // },
          // {
          //   state: 'slots',
          //   short_label: 'mc',
          //   name: 'Slots',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-slots'
          // },
          {
            state: 'feedback-reason',
            short_label: 'mc',
            name: 'Feedback Reasons',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-feedback-reason'
          },
          {
            state: 'university',
            short_label: 'mc',
            name: 'Universities',
            type: 'link',
            icon: 'ti-book',
            menukey:'university'
          },
          {
            state: 'earnings-privacy',
            short_label: 'mc',
            name: 'Earnings Privacy',
            type: 'link',
            icon: 'ti-book',
            menukey:'earnings-privacy'
          },
          // {
          //   state: 'departments',
          //   short_label: 'mc',
          //   name: 'Departments',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'departments'
          // },
          // {
          //   state: 'projectdescribe',
          //   short_label: 'ms',
          //   name: 'Project Describe',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-projectdescribe'
          // },
          // {
          //   state: 'projectlifecycle',
          //   short_label: 'mlc',
          //   name: 'Project Lifecycle',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-projectlifecycle'
          // },
          // {
          //   state: 'projecttype',
          //   short_label: 'mpt',
          //   name: 'Project Type',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-projecttype'
          // },
          // {
          //   state: 'jobtype',
          //   short_label: 'mlc',
          //   name: 'JobType',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-jobtype'
          // },
          // {
          //   state: 'work',
          //   short_label: 'mlc',
          //   name: 'Work Used For',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-work'
          // },
          {
            state: 'language',
            short_label: 'ml',
            name: 'Language',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-language'
          },
          // {
          //   state: 'weekdays',
          //   short_label: 'mw',
          //   name: 'Weekdays',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-weekdays'
          // },
          // {
          //   state: 'time',
          //   short_label: 'mt',
          //   name: 'Time',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-time'
          // },
          {
            state: 'timezone',
            short_label: 'mtz',
            name: 'Timezone',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-timezone'
          },
          // {
          //   state: 'hours',
          //   short_label: 'mh',
          //   name: 'Hours',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-hours'
          // },
          // {
          //   state: 'month',
          //   short_label: 'mm',
          //   name: 'Month',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-month'
          // },
          // {
          //   state: 'year',
          //   short_label: 'my',
          //   name: 'Year',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-year'
          // },
          {
            state: 'industry-groups',
            short_label: 'ig',
            name: 'Industry Groups',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-industry-group'
          },
          {
            state: 'industries',
            short_label: 'ig',
            name: 'Industries',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-industries'
          },
          {
            state: 'sub-industries',
            short_label: 'ig',
            name: 'Sub Industries',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-sub-industries'
          },
          {
            state: 'functional-areas',
            short_label: 'ig',
            name: 'Functional Areas',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-functional-areas'
          },
          // {
          //   state: 'sub-functional-areas',
          //   short_label: 'ig',
          //   name: 'Sub Functional Areas',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-sub-functional-areas'
          // },
          {
            state: 'specializations',
            short_label: 'ig',
            name: 'Specializations',
            type: 'link',
            icon: 'ti-book',
            menukey:'view-specializations'
          },
          // {
          //   state: 'service cost',
          //   short_label: 'ig',
          //   name: 'service cost',
          //   type: 'link',
          //   icon: 'ti-book',
          //   menukey:'view-specializations'
          // }
        ]
      },

    ],
  }

];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
