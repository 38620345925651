import { VideochatService, NamedRoom } from './../../../services/services/videochat.service';
import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import $ from 'jquery';

@Component({
    selector: 'app-rooms',
    styleUrls: ['./rooms.component.css'],
    templateUrl: './rooms.component.html',
})
export class RoomsComponent implements OnInit, OnDestroy {
    @Output() roomChanged = new EventEmitter<string>();
    @Input() activeRoomName: string;

    roomName: string = 'sudhir';
    rooms: NamedRoom[];


    private subscription: Subscription;

    constructor(
        private readonly videoChatService: VideochatService) { }

    async ngOnInit() {
        // await this.updateRooms();
        /* this.subscription =
            this.videoChatService
                .$roomsUpdated
                .pipe(tap(_ => this.updateRooms()))
                .subscribe(); */
        var userData = JSON.parse(localStorage.getItem('currentUser'));
        // console.log(userData.id);
        this.roomName = userData.username;

        await setTimeout(() =>  this.onTryAddRoom(), 2000)
        // $("document").ready(function() {
            
        //     setTimeout(() => 
        //         console.log('sssssssssssssssss'),
        //         //$("#tryadd").trigger('click'),
        //         this.onJoinRoom(this.roomName),
                
        //     3000);
        // });
    }

    /* ngAfterViewInit(){
        this.roomChanged.emit(this.roomName);
    } */
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onTryAddRoom() {
        if (this.roomName) {
            this.onAddRoom(this.roomName);
        }
    }

    onAddRoom(roomName: string) {
        this.roomName = null;
        console.log('roomName', roomName)
        this.roomChanged.emit(roomName);
    }

    onJoinRoom(roomName: string) {
        this.roomChanged.emit(roomName);
    }

    async updateRooms() {
        this.rooms = (await this.videoChatService.getAllRooms()) as NamedRoom[];
        console.log("rooms list");
        console.log(this.rooms);
    }
}