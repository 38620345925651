import { Routes } from '@angular/router';
import { AuthGuard } from '../helpers';
import { WorkSubmissionsComponent } from '../pages/freelancer/work-submissions/work-submissions.component';
import { WorkdiaryComponent } from '../pages/freelancer/workdiary/workdiary.component';

export const EMPLOYER_LAYOUT: Routes = [
    // {
    //     path: 'auth',
    //     loadChildren: () => import('../pages/auth/auth.module').then(m => m.AuthModule)
    // },
    {
        path: 'dashboard',
        loadChildren: () => import('../pages/employer/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    // {
    //     path: 'special',
    //     loadChildren: () => import('../pages/special-competition/special-competition.module').then(m => m.SpecialCompetitionModule)
    // },
    {
        path: 'search-cv',
        loadChildren: () => import('../pages/employer/search-cv/search-cv.module').then(m => m.SearchCvModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'post-job',
        loadChildren: () => import('../pages/auth/postjob/postjob.module').then(m => m.PostjobModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'postjobdetails',
        loadChildren: () => import('../pages/employer/postjobdetails/postjobdetails/Postjobdetails.module').then(m => m.PostjobdetailsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'invitefreelancer/:jobId',
        loadChildren: () => import('../pages/employer/invitefreelancer/invitefreelancer/invitefreelancer.module').then(m => m.InvitefreelancerModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'invitedfreelancerlist',
        loadChildren: () => import('../pages/employer/invitefreelancer/invitedfreelancerlist/invitedfreelancerlist/invitedfreelancerlist.module').then(m => m.InvitedfreelancerlistModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'post-job/:job_id',
        loadChildren: () => import('../pages/auth/postjob/postjob.module').then(m => m.PostjobModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'post-job/:job_id/:job_type',
        loadChildren: () => import('../pages/auth/postjob/postjob.module').then(m => m.PostjobModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'edit-post-job/:jobId',
        loadChildren: () => import('../pages/auth/editpostjob/editpostjob.module').then(m => m.EditpostjobModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'companyprofile',
        loadChildren: () => import('../pages/employer/companyprofile/companyprofile.module').then(m => m.CompanyprofileModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'addmilestone',
        loadChildren: () => import('../pages/employer/milestone/add/addmilestone/addmilestone.module').then(m => m.AddmilestoneModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'viewmilestone',
        loadChildren: () => import('../pages/employer/milestone/view/viewmilestone/viewmilestone.module').then(m => m.ViewmilestoneModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'editmilestone/:id',
        loadChildren: () => import('../pages/employer/milestone/edit/editmilestone/editmilestone.module').then(m => m.EditmilestoneModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'messages',
        loadChildren: () => import('../pages/auth/messaging/messaging.module').then(m => m.MessagingModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'messages/:id',
        loadChildren: () => import('../pages/auth/messaging/messaging.module').then(m => m.MessagingModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'view-proposals',
        loadChildren: () => import('../pages/employer/proposals/proposals.module').then(m => m.ProposalsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'proposal-details/:id',
        loadChildren: () => import('../pages/employer/proposals/view-details/view-proposal-details.module').then(m => m.ViewProposalDetailsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'view-hires',
        loadChildren: () => import('../pages/employer/hires/hires.module').then(m => m.HiresModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'view-post/:job_id',
        loadChildren: () => import('../pages/employer/postjobdetails/postjobdetails/Postjobdetails.module').then(m => m.PostjobdetailsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'review-proposals/:job_id',
        loadChildren: () => import('../pages/employer/review-proposals/review-proposals.module').then(m => m.ReviewProposalsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'hire-freelancer/:freelancer_id/:job_id',
        loadChildren: () => import('../pages/employer/hire-freelancer/hire-freelancer.module').then(m => m.HireFreelancerModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'job-preview',
        loadChildren: () => import('../pages/employer/job-preview/job-preview.module').then(m => m.JobPreviewModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
 {
        path: 'availablejobs',
        loadChildren: () => import('../pages/employer/company/availablejobs/availablejobs/availablejobs.module').then(m => m.AvailablejobsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'contracts',
        loadChildren: () => import('../pages/employer/contracts/contracts.module').then(m => m.ContractsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
	{
        path: 'jobposted',
        loadChildren: () => import('../pages/employer/company/jobposted/jobposted/jobposted.module').then(m => m.JobpostedModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'work-diary/:contract_id',
        loadChildren: () => import('../pages/employer/work-diary/work-diary.module').then(m => m.WorkDiaryModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'view-hires/:job_id',
        loadChildren: () => import('../pages/employer/view-hires/view-hires.module').then(m => m.ViewHiresModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'pay-freelancer/:contract_id/:milestone_id',
        loadChildren: () => import('../pages/employer/pay-freelancer/pay-freelancer.module').then(m => m.PayFreelancerModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'client-feedback/:contract_id',
        loadChildren: () => import('../pages/employer/client-feedback/client-feedback.module').then(m => m.ClientFeedbackModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'availablejobs',
        loadChildren: () => import('../pages/employer/company/availablejobs/availablejobs/availablejobs.module').then(m => m.AvailablejobsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'jobposted',
        loadChildren: () => import('../pages/employer/company/jobposted/jobposted/jobposted.module').then(m => m.JobpostedModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'jobpreview',
        loadChildren: () => import('../pages/employer/company/jobpreview/jobpreview/jobpreview.module').then(m => m.JobpreviewsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'setting',
        // loadChildren: () => import('../pages/employer/settings/setting.module').then(m => m.EmployerSettingModule),
        loadChildren: () => import('../pages/freelancer/usersetting/usersetting.module').then(m => m.UsersettingModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'setting/:route',
        // loadChildren: () => import('../pages/employer/settings/setting.module').then(m => m.EmployerSettingModule),
        loadChildren: () => import('../pages/freelancer/usersetting/usersetting.module').then(m => m.UsersettingModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'postJob',
        loadChildren: () => import('../pages/employer/postjobdetails/postjobdetails/Postjobdetails.module').then(m => m.PostjobdetailsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'dashboard',
        loadChildren: () => import('../pages/employer/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'editProject/:project_id/:type',
        loadChildren: () => import('../pages/employer/reuseEditProject/reuseEditProject.module').then(m => m.ReuseEditProjectModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'postAndInvite/:project_id/:type',
        loadChildren: () => import('../pages/employer/freelancerInvite/freelancerInvite.module').then(m => m.FreelancerInviteModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'editJob/:job_id',
        loadChildren: () => import('../pages/employer/reuseEditJob/reuseEditJob.module').then(m => m.ReuseEditJobModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'applicants/:job_id',
        loadChildren: () => import('../pages/employer/applicants/applicants.module').then(m => m.ApplicantsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'viewJob/:job_id',
        loadChildren: () => import('../pages/employer/viewJob/viewJob.module').then(m => m.ViewJobModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'viewProject/:project_id',
        loadChildren: () => import('../pages/employer/freelancerInvite/freelancerInvite.module').then(m => m.FreelancerInviteModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'viewProjectPreview/:project_id',
        loadChildren: () => import('../pages/employer/viewProject/viewProject.module').then(m => m.ViewProjectModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'proposals/:proposalId/:type/:user_id',
        loadChildren: () => import('../pages/employer/view-offer/view-offer.module').then(m => m.ViewOfferModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'viewProposal/:user_id/:proposalId/:finalhireId/:invitationid',
        loadChildren: () => import('../pages/employer/proposal/proposal.module').then(m => m.ProposalModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'sendOffer/:user_id/:project_id/:inviteId',
        loadChildren: () => import('../pages/employer/offerFreelancer/offerFreelancer.module').then(m => m.OfferFreelancerModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'raiseDispute/:project_id/:freelancer_id',
        loadChildren: () => import('../pages/employer/employerDisputes/raise-a-dispute/raise-a-dispute.module').then(m => m.RaiseADisputeModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'myDisputes',
        loadChildren: () => import('../pages/employer/employerDisputes/my-disputes/my-disputes.module').then(m => m.MyDisputesModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'endProject/:project_id/:freelancer_id',
        loadChildren: () => import('../pages/employer/end-contract/end-contract.module').then(m => m.EndcontractsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'myProjects',
        loadChildren: () => import('../pages/employer/myProjects/myProjects.module').then(m => m.MyProjectsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'myProjects/:selectedValue',
        loadChildren: () => import('../pages/employer/myProjects/myProjects.module').then(m => m.MyProjectsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'myJobs',
        loadChildren: () => import('../pages/employer/myJobs/myJobs.module').then(m => m.MyJobsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'yourprofile/:userId/:sectionId',
        loadChildren: () => import('../pages/auth/companyprofile/companyprofile.module').then(m => m.CompanyprofileModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
      },
      {
        path: 'yourprofile/:userId/:sectionId/:idToFetch',
        loadChildren: () => import('../pages/auth/companyprofile/companyprofile.module').then(m => m.CompanyprofileModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
      },
      {
          path: 'video-call',
          loadChildren: () => import('../pages/employer/video-call/video-call.module').then(m => m.VideoCallModule),
          canActivate : [AuthGuard],
          data: { roles: ["ROLE_EMPLOYER"] }
      },
      {
        path: 'reports',
        loadChildren: () => import('../pages/employer/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
      },

    {
        path: 'reviewPay/:contractId/:payType',
        loadChildren: () => import('../pages/employer/reviewandpay/reviewpay.module').then(m => m.ReviewPayModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'changeRequest/:project_id/:freelancer_id',
        loadChildren: () => import('../pages/employer/change-requests/change-requests.module').then(m => m.ChangeRequestsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
    
    path: 'video/:freelancerId/:employerId/:roomId/:jobId',
    loadChildren: () => import('../pages/video/video.module').then(m => m.VideoModule),
    canActivate : [AuthGuard],
    data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'postJobChoice',
        loadChildren: () => import('../pages/employer/jobChoice/jobChoice.module').then(m => m.JobChoiceModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'postProjectChoice',
        loadChildren: () => import('../pages/employer/projectChoice/projectChoice.module').then(m => m.ProjectChoiceModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'postJobProject/:typeId/:type',
        loadChildren: () => import('../pages/employer/postjobdetails/postjobdetails/Postjobdetails.module').then(m => m.PostjobdetailsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'postJobProject/:typeId/:type/:preview',
        loadChildren: () => import('../pages/employer/postjobdetails/postjobdetails/Postjobdetails.module').then(m => m.PostjobdetailsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    // {
    //     path: 'postJobProject/:typeId/:type/:check/:ref',
    //     loadChildren: () => import('../pages/employer/postjobdetails/postjobdetails/Postjobdetails.module').then(m => m.PostjobdetailsModule),
    
    // },
    {
        path: 'viewJobseeker/:jobseekerId',
        loadChildren: () => import('../pages/employer/viewJobseeker/viewJobseeker.module').then(m => m.ViewJobseekerModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'employerContracts/:type/:contractId',
        loadChildren: () => import('../pages/employer/employerContracts/contracts.module').then(m => m.EmployerContractsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },
    {
        path: 'employerContracts/:type/:contractId/:tab',
        loadChildren: () => import('../pages/employer/employerContracts/contracts.module').then(m => m.EmployerContractsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'payments',
        loadChildren: () => import('../pages/employer/viewPayments/viewPayments.module').then(m => m.ViewPaymentModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'hireFreelancer/:proposalId/:type/:user_id',
        loadChildren: () => import('../pages/employer/freelancerHire/freelancerHire.module').then(m => m.FreelancerHireModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'work-submissions/:contractId/:jobId/:employerId/:freelancerId',
        component: WorkSubmissionsComponent,
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    },

    {
        path: 'work-diary',
        component: WorkdiaryComponent,
        canActivate: [AuthGuard],
        data: { roles: ["ROLE_EMPLOYER"] }
    }
    

]