import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { environment } from '../../../../environments/environment';
import { FreelancerserviceService } from '../../../services/freelance-service/freelancerservice.service';
import { UserService } from '../../../services/user.service';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-workdiary',
  templateUrl: './workdiary.component.html',
  styleUrls: ['./workdiary.component.scss']
})
export class WorkdiaryComponent implements OnInit {

  
  user_id:string ='';
  s3_url = environment.s3_url;
  userProjectsListData:any;
  projectScreenShotsData:any;
  updatedprojectScreenShotsData:any;
  projectId:string='';
  redirect_url:string = 'freelancer'
  currnetUserRole:string;

  selectedDate=new Date();
  maxDate:Date;
  // bsValue = new Date();

  autoTrackedTime:any;
  manualTime:any;
  totalTime:any;
  currentIndex: any = -1;
  showFlag: any = false;
  constructor(
    private userService: UserService,
    private toaster: ToasterService,
    private freelancerService: FreelancerserviceService,
    private titleService: Title
  ) {
   
    this.maxDate = new Date();
   }

  ngOnInit() {
    this.titleService.setTitle("Work Diary | Remotelly");
    var userData = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(userData.id);
    this.user_id = userData.id;
  //   if (userData && userData['data'])
  //     this.currnetUserRole = userData['data']['roles'][0];
  //   //console.log(this.currnetUserRole);

  //  if (this.currnetUserRole == 'ROLE_FREELANCER AGENCY') {
  //     this.redirect_url = 'freelancer-agency';
  //   } else if (this.currnetUserRole == 'ROLE_EMPLOYER') {
  //     this.redirect_url = 'employer';
  //   } else {
  //     this.redirect_url = 'freelancer';
  //   }
    this.getUserProjects();
    // this.getProjectScreenShots();
  }
  showLightbox(index) {
    console.log("UUU",index)
    this.currentIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }
  getUserProjects(){
    // this.user_id = "4e29963d-148b-439d-a282-3105c408b172"
    this.freelancerService.getUserProjects(this.user_id).subscribe(data => {
      if(data['status']){
        this.userProjectsListData = data['data'];
        let project1;
        project1 = this.userProjectsListData[0];
        this.projectId = project1.job_id
        if (this.projectId != ''){
         this.getProjectScreenShots();
        }
        console.log("projects list", this.userProjectsListData);
      }else{
        this.toaster.pop("error", data['message']);
      }
    })
  }

  getProjectScreenShots(){
    console.log("selected Date",this.selectedDate);
    console.log('project ID', this.projectId);
    let projectData = this.userProjectsListData.filter(a => { if (a.job_id == this.projectId) { return a } });
    console.log("project data----------",projectData);
    let formData ={
      freelancer_id: projectData[0].freelancer_id,
      project_id: this.projectId,
      date: this.selectedDate
    }
    // let formData = {
    //   "freelancer_id": "4e29963d-148b-439d-a282-3105c408b172",
    //   "project_id": "35cde7a5-97c2-4798-baae-479faf9255fd",
    //   date: "2022-11-03"
    // }
    this.userService.getProjectScreenShots(formData).subscribe(data => {
      if(data['status']){
        this.projectScreenShotsData = data['data'];
        this.updatedprojectScreenShotsData = this.projectScreenShotsData.map(obj => {
          obj.image=this.s3_url+obj.file
          
          return obj;
         })
        console.log("project screen shots",this.projectScreenShotsData,this.updatedprojectScreenShotsData);
        this.getProjectWorkingTime(formData);
      }else{
        this.toaster.pop('error', data['message']);
      }
    })
  }

  getProjectWorkingTime(formData:object){
    this.userService.getTotalProjectWorkingTime(formData).subscribe(data => {
      if(data['status']){
        this.autoTrackedTime =+data['data'].total_auto_hours;
        this.manualTime =+data['data'].total_manual_hours;
        this.totalTime = Math.round((this.autoTrackedTime + this.manualTime) * 1e12) / 1e12
        console.log("Data",data['data'],"auto", this.autoTrackedTime, "manual", this.manualTime, "Total", this.totalTime);
      }else{
        this.toaster.pop("error", data['message']);
      }
    })
  }

  projectChange(val:string){
    console.log('project ID', val);
    this.projectId = val;
    console.log("after assigning value",this.projectId);
    if (this.projectId){
      this.getProjectScreenShots();
    }
  }

  onValueChange(value: Date): void {
    console.log("date change", value);
    this.selectedDate = value;
    if(this.selectedDate){
      this.getProjectScreenShots();
    }
  }

  splitAndConvertString(val){
    let time = val.toString();
    let str = time.split(".")
    let join = str.join(':');
    return join;
  }

  DownloadScreenshot(downloadLink) {
    window.open(downloadLink);
    // const image = document.getElementById('screenShotImage');
    // image.addEventListener('click', this.downloadImage);

  
  }

  // downloadImage() {
  // const link = document.createElement('a');
  //     link.href = image.src;
  //     link.download = 'myImage.jpg';
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }

}
