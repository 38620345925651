import { Routes } from '@angular/router';
import { AuthGuard } from '../helpers';
import { AllProjectsComponent } from '../pages/admin-panel/projects/all-projects/all-projects.component';
import { PaymentComponent } from '../pages/admin-panel/payment/payment.component';
import { DetailsComponent } from '../pages/admin-panel/projects/details/details.component';
import { AllJobsComponent } from '../pages/admin-panel/jobs/all-jobs/all-jobs.component';
import { JobDetailsComponent } from '../pages/admin-panel/jobs/job-details/job-details.component';

export const BACKEND_LAYOUT: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('../pages/auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('../pages/admin-panel/dashboard/dashboard-default/dashboard-default.module').then(m => m.DashboardDefaultModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }
    },
    {
        path: 'role',
        loadChildren: () => import('../pages/admin-panel/roles/role.module').then(m => m.RoleModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }
    },
    {
        path: 'users',
        loadChildren: () => import('../pages/admin-panel/users/users.module').then(m => m.UsersModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }
    },
    {

        path: 'setting',
        loadChildren: () => import('../pages/admin-panel/setting/setting.module').then(m => m.SettingModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }
    },
    {
        path: 'permissions',
        loadChildren: () => import('../pages/admin-panel/permissions/permissions.module').then(m => m.PermissionsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }
    },
    {
        path: 'masterdata',
        loadChildren: () => import('../pages/admin-panel/masterdata/masterdata.module').then(m => m.MasterdataModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }
    },
    {
        path: 'informaticpages',
        loadChildren: () => import('../pages/admin-panel/informatic-pages/informatic-pages.module').then(m => m.InformaticPagesModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }
    },
    {
        path: 'payment',
        component: PaymentComponent,
        canActivate: [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }
    },
    {
        path: 'projects',
        component: AllProjectsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }
    },
    {
        path: 'projects/details/:id/:employerId',
        component: DetailsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }
    },
    {
        path: 'projects/details/:id/:employerId/:freelancerId',
        component: DetailsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }  
    },
    {
        path: 'jobs',
        component: AllJobsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }
    },
    {
        path: 'jobs/details/:id',
        component: JobDetailsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["ROLE_ADMIN"] }
    },
]
