import { NgModule } from '@angular/core';

import { DatePipe, CommonModule } from '@angular/common';
// import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { VideoRoutingModule } from './video-routing.module';
import { VideoComponent } from './video.component';
import { HomeComponent } from './home/home.component';
import { RoomsComponent } from './rooms/rooms.component';
import { ParticipantsComponent } from './participants/participants.component';
import { CameraComponent } from './camera/camera.component';
import { SettingsComponent } from './settings/settings.component';
import { DeviceSelectComponent } from './settings/device-select.component';
import { ActivityIndicatorComponent } from './activity-indicator/activity-indicator.component';
import { MessagesComponent } from './messages/messaging.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountdownTimerModule } from 'angular-countdown-timer';
import { NgxSpinnerModule } from 'ngx-spinner';

export function getDatepickerConfig(): BsDatepickerConfig {
  return Object.assign(new BsDatepickerConfig(), {
    dateInputFormat: 'YYYY-MM'
  });
}

@NgModule({
  declarations: [VideoComponent,
        HomeComponent,
        RoomsComponent,
        ParticipantsComponent,
        CameraComponent,
        SettingsComponent,
        DeviceSelectComponent,
        ActivityIndicatorComponent,
        MessagesComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    VideoRoutingModule,
    PickerModule,
    BsDatepickerModule.forRoot(), 
    NgbModule, 
    CountdownTimerModule.forRoot(),
    NgxSpinnerModule
  ],
  providers: [DatePipe, { provide: BsDatepickerConfig, useFactory: getDatepickerConfig }],
})
export class VideoModule { }
