import { Injectable } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Role } from '../models/role';
import { Permission } from '../models/permission';
import { environment } from '../../environments/environment'

const apiUrl =environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class RoleService {


  constructor(private http: HttpClient, private router: Router,  private route: ActivatedRoute,) { }


  getAll() {
      return this.http.get<Role[]>(apiUrl+'admin/role/get_all');
  }

  getAllPermission() {
      return this.http.get<Permission[]>(apiUrl+'admin/permission/get_all');
  }


  createRole(role: Role) {
    return this.http.post(apiUrl+'admin/role/create', role);
  }

  getById(id: number) {
        return this.http.get(apiUrl+'admin/role/get_by_id/' + id);
  }

  updateStatus(role: Role,id) {
     return this.http.put(apiUrl+'admin/role/update_status/'+id, role);
  }

  updateRole(role: Role,id) {
     return this.http.put(apiUrl+'admin/role/update_role/'+id, role);
  }

  delete(id: number) {
        return this.http.delete(apiUrl+'user/role/' + id);
  }

  getRolesExcludingAdminAndSubAdmin(){
    return this.http.get(apiUrl+'auth/get-roles');
  }

}
