import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { createLocalAudioTrack, Room, LocalTrack, LocalVideoTrack, LocalAudioTrack, RemoteParticipant } from 'twilio-video';
import { RoomsComponent } from '../rooms/rooms.component';
import { CameraComponent } from '../camera/camera.component';
import { SettingsComponent } from '../settings/settings.component';
import { ParticipantsComponent } from '../participants/participants.component';
import { VideoChatService } from '../../../services/videochat.service';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from "angular2-toaster";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FreelancerserviceService } from '../../../services/freelance-service/freelancerservice.service';
import { io, Socket } from 'socket.io-client';
import { EmployerserviceService } from '../../../services/employer-service/employerservice.service';
import { UserService } from '../../../services';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-home',
    styleUrls: ['./home.component.css'],
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
    @ViewChild('rooms', {static: false}) rooms: RoomsComponent;
    @ViewChild('camera', {static: false}) camera: CameraComponent;
    @ViewChild('settings', {static: false}) settings: SettingsComponent;
    @ViewChild('participants', {static: false}) participants: ParticipantsComponent;

    s3_url = environment.s3_url;
    private url = environment.socket_url;
    socket: Socket;
    activeRoom: Room;
    isMute: any;
    isVideoMute: any;
    freelncerId: any;
    employerId: any;
    jobId:any;
    roomId: any;
    inviteId: any;
    showOffer: boolean = false;
    employerOffer:boolean= false;
    currentUserRole : string = '';
    proposalFromFreelancer:boolean = false;
    showingButton = true;

    payRadio: any;
    hourView: boolean;
    fixView: boolean;
    hourlyRate: any;
    weeklyLimit: any;
    numberOfWeeks: any;
    startDate: any;
    dueDate: any='';
    totalCost: any;
    bidCost: any;
    duration: any;
    startFixDate: any;
    dueFixDate: any='';
    totalFixCost: any;
    fixDescription: any;
    terms: any;
    employer_id: any;
    project_id: any;
    viewHourOnly = []
    viewFixOnly = []
    offerView: boolean;
    editView: boolean;
    editFixView: boolean;
    editHourView: boolean;
    description: string;

    /* freelancer offer */
    offerDetails:any={};
    appointmentDetails: any;
    freelancerOffer : boolean = false;
    downloadURL:any;
    dateTimeAgo:any;
    preview='';
    dataRefresher: any;
    checkIsAlone: boolean = false
    deleteCallLoader: boolean = false;
    listOfQuestions:any = [];
    answerFill:boolean = false;
    submitQuestions:boolean = false;
    user_id: any;
    parentMessage = 'hello from parent';
    submitCounterOffer =false;

  submitProposalForm:FormGroup;
  weeklyLimitData = [
    10,
    20,
    30,
    40
  ];
  submitted = false;
  resumeLoading:boolean = false;
  counterOffer:boolean = false;
  fileSize;
  uploadedFile;
  proposalId:any;
  proposalData:any;
  proposalStartDate:any='';
  proposalDueDate:any='';
  questionsList: any;
  final_hires_id:any;
  makingAmount: any;
  proposalSubmitted:boolean = false;
  acceptProposal:boolean= false;
  sendHourlyOffer:boolean = false;
  sendFixedOffer:boolean = false;
  offerRefuseReason:String ='';
  projectStartDate = true;
  projectStartDate1 = true;
  selectedDueDate:any='';
  offerStartDate:any='';
  offerDueDate:any='';
  employerSendOffer = false
  callRatingData = 0;
  callRatingFlag = false;
  screenSharing =false;
  screenTrack:any;

  display: any;
  public timerInterval: any;
  is_urgently = false;
  bothParticipantsPresent = false;

  endDateAndTime;
  lastTenMinutes;
  lastFiveMinutes;

  callEnded=false;
  notifyBeforeTenMinutes =false
  notifyBeforeFiveMinutes =false;

  timeleft = 11;


    private notificationHub: HubConnection;

    constructor(
      private freelancerservice:FreelancerserviceService,
        private activeRouter : ActivatedRoute, 
        private router: Router, 
        private toasterService: ToasterService,
        private userService:UserService,
        private employeerService:EmployerserviceService,
        private readonly videoChatService: VideoChatService,
        private formBuilder: FormBuilder,
        private spinner: NgxSpinnerService
        ) 
      {
      this.socket = io(this.url, {
        secure: true,
        rejectUnauthorized: false,
        withCredentials: false,
        forceNew: true,
        transports: ["polling"],
      });    
      }

    async ngOnInit() {
        this.getAudioAndVideoPermissions();
        this.isMute = false;
        this.roomId = this.activeRouter.snapshot.params['roomId']
        this.freelncerId = this.activeRouter.snapshot.params['freelancerId']
        this.employerId = this.activeRouter.snapshot.params['employerId']
        this.jobId = this.activeRouter.snapshot.params['jobId']

        var userData = JSON.parse(localStorage.getItem('currentUser'));
        // console.log(userData.id);
        this.user_id = userData.id;
        this.currentUserRole= userData['data']['roles'][0];
        // console.log('currrent user',this.currentUserRole);

        this.submitProposalForm = this.formBuilder.group({
          user_id: [''],
          employer_id:[''] ,
          job_id:[''] ,
          file: [null],
          profile_sepcification:[''] ,
          amount_receivable: [0],
          remotelly_fee:[''] ,
          project_timeperiod:[''] ,
          proposal_type: [''],
          hourly_rate: [''],
          fixed_rate: [''],
          about:[''] ,
          weekly_limit: [0],
          total_week: [0],
          start_date: ['',Validators.required],
          due_date_to_accept: ['',Validators.required],
          total_cost: [''],
          duration: [''],
          invitation_id: [''],
          final_hires_id: [''],
          answers:[]
        })

        // await this.refreshData();  
        await this.projectDetails()
        await this.getProjectDetails(); 
        await this.getAppointmentDetails();
        const builder =
            new HubConnectionBuilder()
                .configureLogging(LogLevel.Information)
                .withUrl(`${location.origin}/notificationHub`);

        this.notificationHub = builder.build();
        this.notificationHub.on('RoomsUpdated', async updated => {
            if (updated) {
                await this.rooms.updateRooms();
            }
        });
        // await this.notificationHub.start();
        this.hourView = false
        this.fixView = false
        this.onisAloneSet();
        // this.onparticipantSize();
        // var userData = JSON.parse(localStorage.getItem('currentUser'));
        // // console.log(userData.id);
        // var roomName = userData.username;
        // await this.onRoomChanged(roomName);
      
      // Creating socket room with roomId  
      this.socket.emit("subscribe_video", this.roomId);

      // Listening to offer when emitted
      this.socket.on('offer', async(data)=> {
        // console.log('socket-----------',data);
        let offerData = {};
        offerData = data.offerDetails;
        console.log("offer details---------------------", offerData);
        if (offerData){
          this.callProjectDetails();
        }
      });
    }

  get f() { return this.submitProposalForm.controls; }



  async callProjectDetails(){
    console.log('after socket call -------');
    await this.projectDetails();
  }

    /* get participantCount() {
      return !!this.participants ? this.participants.size : 0;
    }
    get isAlone() {
      return this.participantCount === 0;
    } */
    hourClick() {
        this.hourView = true
        this.fixView = false
    }
    
    fixClick() {
        this.hourView = false
        this.fixView = true
    }
    totalCosts() {
        this.totalCost = this.numberOfWeeks * this.hourlyRate * this.weeklyLimit
    }
    EditCall() {
      this.employerSendOffer = false;
      this.sendFixedOffer =false;
      this.sendHourlyOffer =false;
        if (this.hourView == true) {
          if ((this.numberOfWeeks == '' || this.numberOfWeeks == undefined) ||
            (this.hourlyRate == '' || this.hourlyRate == undefined) ||
            (this.weeklyLimit == '' || this.weeklyLimit == undefined) ||
            (this.terms == '' || this.terms == undefined) ||
            (this.dueDate == '' || this.dueDate == undefined) ||
            (this.startDate == '' || this.startDate == undefined) ||
            (this.description == '' || this.description == undefined)) {
            this.toasterService.pop('error', 'Please enter all details');
          }
          else {
            let obj = {
              "job_id": this.jobId,
              "freelancer_id": this.freelncerId,
              "employer_id": this.employerId,
              "payment_option": "Pay By Hour",
              "total_week": this.numberOfWeeks,
              "rate": this.hourlyRate,
              "weekly_limit": this.weeklyLimit,
              "total_cost": this.totalCost,
              "is_check": this.terms,
              "due_date_to_accept": this.dueDate,
              "start_date": this.startDate,
              "description": this.description,
              "invitation_id":this.inviteId
            }
            // this.http.post(environment.apiUrl + 'employer/hire-freelancer-final', obj)
            this.employeerService.employerhirefreelancerfinal(obj).subscribe(data => {
              if (data['status'] == true) {
                let result = data['data']
                this.toasterService.pop('success', 'offer Sent');
                this.getProjectDetails();
                this.sendFixedOffer =false;
                this.sendHourlyOffer =true;
                let objd = {
                  "final_hire_id": result.id
                }
                // this.http.post(environment.apiUrl + 'user/get-offer-details', objd)
                this.employeerService.employeergetofferdetails(objd).subscribe(data => {
                  if (data['status'] == true) {
                    let result1 = data['data'];
                    this.employerSendOffer = true;
                    this.viewHourOnly = result1
                    this.offerView = false
                    this.editHourView = true
                    this.editFixView = false
                    this.editView = true;
                    window.scroll(0,0);
                    this.socket.emit("send_offer", {
                      job_id: this.jobId,
                      freelancer_id: this.freelncerId,
                      employer_id: this.employerId,
                      room: this.roomId
                    });
                  }
                })
    
    
              }
            })
    
          }
    
        }
    
        else if (this.fixView == true) {
          if ((this.bidCost == '' || this.bidCost == undefined) ||
            (this.terms == '' || this.terms == undefined) ||
            (this.dueFixDate == '' || this.dueFixDate == undefined) ||
            (this.startFixDate == '' || this.startFixDate == undefined) ||
            (this.fixDescription == '' || this.fixDescription == undefined)) {
            this.toasterService.pop('error', 'Please enter all details');
          }
    
    
    
          else {
            this.totalFixCost = this.bidCost
            let obj1 = {
              "job_id": this.jobId,
              "freelancer_id": this.freelncerId,
              "employer_id": this.employerId,
              "payment_option": "Pay Fixed Price",
              "rate": this.bidCost,
              "total_cost": this.totalFixCost,
              "is_check": this.terms,
              "due_date_to_accept": this.dueFixDate,
              "start_date": this.startFixDate,
              "duration": this.duration,
              "description": this.fixDescription,
              "invitation_id":this.inviteId
            }
            // this.http.post(environment.apiUrl + 'employer/hire-freelancer-final', obj1)
            this.employeerService.employerhirefreelancerfinal(obj1).subscribe(data => {
              if (data['status'] == true) {
                let result = data['data']
                this.toasterService.pop('success', 'offer Sent');
                this.getProjectDetails();
                this.sendFixedOffer =true;
                this.sendHourlyOffer =false;

                let objv = {
                  "final_hire_id": result.id
                }
                // this.http.post(environment.apiUrl + 'user/get-offer-details', objv)
                this.employeerService.employeergetofferdetails(objv).subscribe(data => {
                  if (data['status'] == true) {
                    let result2 = data['data'];
                    this.employerSendOffer = true;
                    this.viewFixOnly = result2
                    this.offerView = false
                    this.editHourView = false
                    this.editFixView = true
                    this.editView = true;
                    window.scroll(0,0);
                    this.socket.emit("send_offer", {
                      job_id: this.jobId,
                      freelancer_id: this.freelncerId,
                      employer_id: this.employerId,
                      room: this.roomId
                    });
                  }
                })
              }
            })
          }
    
        }
    
        else {
          this.toasterService.pop('error', 'Please select payment type');
          this.employerSendOffer = false;
        }
    
      }
    getAppointmentDetails(){
        // this.http.post(environment.apiUrl+ 'user/appointment-details',{room_id:this.roomId})
      this.bothParticipantsPresent = true;
        this.userService.userappointmentdetails({room_id:this.roomId}).subscribe(data=>{
          if(data['status']==true){
            console.log('appointment details',data['data']);
            this.appointmentDetails = data['data'];
            if (!this.appointmentDetails.is_urgently){
              this.is_urgently = false;
            
              this.endDateAndTime = new Date(this.appointmentDetails.user_availability.to);
              this.lastTenMinutes = moment(this.endDateAndTime).subtract(10, 'm').toDate();
              this.lastFiveMinutes = moment(this.endDateAndTime).subtract(5, 'm').toDate();
             
            } else if (this.appointmentDetails.is_urgently){
              this.is_urgently = true;

              if (this.appointmentDetails.freelancer_joined && this.appointmentDetails.employer_joined){
                this.urgentCall(this.appointmentDetails)
              }else{
                this.bothParticipantsPresent = false;
              }
            }
          }
        })
    }

  urgentCall(appointmentData){
    let freelancerTime, employerTime, latestTime, currentTime;
    currentTime =moment();
    freelancerTime = moment(appointmentData.freelancer_joining_time);
    employerTime = moment(appointmentData.employer_joining_time);
    console.log("freelancerTime:", freelancerTime, "employerTime:", employerTime);

    if (freelancerTime >= employerTime){
      latestTime = freelancerTime;
      console.log("f>e freelancer");
    }else{
      latestTime = employerTime;
      console.log("e>f employer");

    }
    let duration = appointmentData.duration;

    let finalTime = moment(latestTime).add(duration, 'm').toDate();
    this.lastTenMinutes = moment(finalTime).subtract(10, 'm').toDate();
    this.lastFiveMinutes = moment(finalTime).subtract(5, 'm').toDate();
    console.log('final time', finalTime);
    this.endDateAndTime = finalTime;
  }

  triggerFunction() {
    // console.log('Timer Ended');
    if (!this.callEnded){
      this.onLeaveRoom()
      this.callEnded = true;
    }
  }

  lastTen(){
    // console.log('last ten minutes');
    if(!this.notifyBeforeTenMinutes){
      this.toasterService.pop('warning', 'Only 10 minutes left...!');
      this.notifyBeforeTenMinutes = true;
    }
  }

  lastFive(){
    // console.log('last five minutes');
    if(!this.notifyBeforeFiveMinutes){
      this.toasterService.pop('warning', 'Only 5 minutes left...!');
      this.notifyBeforeFiveMinutes = true
    }
  }

    projectDates(event){
      console.log('start date-------', event);
      this.selectedDueDate =''
      // let changeDate = new Date(event).getDate()+ +new Date(event).getMonth()+ +new Date(event).getFullYear();
      if(event != '' && this.dueDate != ''){
        this.projectStartDate = false;
        this.startDate='';
        this.selectedDueDate =event;
        console.log('selected start date',this.selectedDueDate);
      } else {
        this.projectStartDate = true;
      }
      
  
      // let existingDate =0;
      // let changedDate =0;
      // // existingDate = new Date(this.proposalData.due_date_to_accept).getDate()+ +new Date(this.proposalData.due_date_to_accept).getMonth()+ +new Date(this.proposalData.due_date_to_accept).getFullYear();
      // changedDate = new Date(event).getDate()+ +new Date(event).getMonth()+ +new Date(event).getFullYear();
      // this.selectedDueDate ='';
      // this.dueDateSelected =true
      // if(existingDate != changedDate){
      //   this.startDate = '';
      //   this.dueDateSelected = false
      //   this.selectedDueDate =new Date(event);
      //   // console.log('not equal',event)
      // }else{
      //   this.dueDateSelected =true
      //   // console.log('due date same');
      // }
    }

    projectDates1(event){
      console.log('start date-------', event);
      this.selectedDueDate =''
      // let changeDate = new Date(event).getDate()+ +new Date(event).getMonth()+ +new Date(event).getFullYear();
      if(event != '' && this.dueFixDate != ''){
        this.projectStartDate1 = false;
        this.startFixDate='';
        this.selectedDueDate =event;
        console.log('selected start date',this.selectedDueDate);
      } else {
        this.projectStartDate1 = true;
      }
      
  
      // let existingDate =0;
      // let changedDate =0;
      // // existingDate = new Date(this.proposalData.due_date_to_accept).getDate()+ +new Date(this.proposalData.due_date_to_accept).getMonth()+ +new Date(this.proposalData.due_date_to_accept).getFullYear();
      // changedDate = new Date(event).getDate()+ +new Date(event).getMonth()+ +new Date(event).getFullYear();
      // this.selectedDueDate ='';
      // this.dueDateSelected =true
      // if(existingDate != changedDate){
      //   this.startDate = '';
      //   this.dueDateSelected = false
      //   this.selectedDueDate =new Date(event);
      //   // console.log('not equal',event)
      // }else{
      //   this.dueDateSelected =true
      //   // console.log('due date same');
      // }
    }

    getProjectDetails(){
      console.log('get projects details api called``````````');
        // this.http.post(environment.apiUrl+ 'freelancer/get-project-details',{job_id:this.jobId, freelancer_id: this.freelncerId, employer_id: this.employerId})
        this.freelancerservice.getProjectPreview({job_id:this.jobId, freelancer_id: this.freelncerId, employer_id: this.employerId}).subscribe(data=>{
          if(data['status']==true){
            console.log('project details',data['data']);
            this.offerDetails = [];
            this.offerDetails = data['data'];
            if (this.offerDetails.final_hire){
              this.offerStartDate = moment(this.offerDetails.final_hire.start_date).format("Do MMM YYYY");
              this.offerDueDate = moment(this.offerDetails.final_hire.due_date_to_accept).format("Do MMM YYYY");
            }

            //this.listOfQuestions = [];
            this.answerFill = false;
            let questionsArr = this.offerDetails.job_questions;
            // let index = this.listOfQuestions.findIndex(x => x.question_id == questionsArr[i].id);
            //   console.log(index);
              
            if(this.listOfQuestions.length == 0) {
              for (let i = 0; i < questionsArr.length; i++) {
                  this.listOfQuestions.push({
                    question: questionsArr[i].questions,
                    question_id: questionsArr[i].id,
                    answer: ''
                  })
              }
            }
            if(this.offerDetails.applied_jobs.length > 0){
              this.proposalId = this.offerDetails.applied_jobs[0].id;
              this.proposalCall();
              console.log('proposal details in freelancer/get projects');
            }
            if(this.offerDetails.employer_profile !== null){
              if(this.offerDetails.employer_profile.profile_img != ''){
                this.preview = this.s3_url + this.offerDetails.employer_profile.profile_img;
              }
            }
            
            // console.log('created at',this.offerDetails.final_hire.createdAt);
            if (this.offerDetails.final_hire){
              this.dateTimeAgo = moment(this.offerDetails.final_hire.createdAt).fromNow();
            }
            //console.log(this.dateTimeAgo);
            this.downloadURL = this.s3_url + this.offerDetails.file;
            // console.log('data of project',this.offerDetails);
            console.log('project details in freelancer/get projects');
          }
        })
    }

    acceptIncomingOffer(){
      
      console.log('questions', this.listOfQuestions);
    document.getElementById('openQuestionModel').click();
    }
    
  acceptOffer() {
    this.submitQuestions = true
    if (this.offerDetails.final_hire.id == '' || this.offerDetails.final_hire.id == null || this.offerDetails.final_hire.id == undefined) {
      return;
    }

    this.answerFill = false;
    if (this.listOfQuestions.length != 0) {
      this.listOfQuestions.forEach(x => {
        if (x.answer == '')
          this.answerFill = true;
        else
          this.answerFill = false;
      })
      if (this.answerFill) {
        this.toasterService.pop('error', 'Please fill all the answers');
        this.submitQuestions = false
        return;
      }
    }
    let formData = {
      job_id: this.offerDetails.final_hire.job_id,
      final_hire_id: this.offerDetails.final_hire.id,
      invitation_id: this.offerDetails.final_hire.invitation_id,
      user_id: this.user_id,
      answers: this.listOfQuestions
    }
    // this.http.post(environment.apiUrl + 'user/accepteOffer', formData)
    this.freelancerservice.getaccepteOffer(formData)
    .subscribe(data => {
      // console.log('accept offer data',data);
      if (data['status'] == true) {
        this.toasterService.pop('success', data['message']);
        this.freelancerOffer = false;
        this.submitQuestions = false;
        document.getElementById('closeQuestionModel').click();
        this.socket.emit("offer_response_freelancer", {
          job_id: this.jobId,
          freelancer_id: this.freelncerId,
          employer_id: this.employerId,
          room: this.roomId
        });
        this.getProjectDetails();
      }
      else {
        this.toasterService.pop('error', data['message']);
        this.submitQuestions = false

      }
    })
  }

  declineOffer(){
    if(this.offerRefuseReason == ''){
      this.toasterService.pop('error', 'Please fill the reason!.');
      return;
    }
    //console.log('refuse reason',this.offerRefuseReason);
    let formData={
      final_hire_id: this.offerDetails.id,
      invitation_id: this.offerDetails.invitation_id,
      reason:this.offerRefuseReason
    }
    // let formData={
    //   final_hire_id: this.pendingOffers[ind].id,
    //   freelancer_id:this.pendingOffers[ind].freelancer_id,
    //   job_id:this.pendingOffers[ind].job_id,
    //   reason: this.offerRefuseReason
    // }
    // this.http.post(environment.apiUrl+ 'user/declineOffer', formData)
    this.freelancerservice.getdeclineOffer(formData)
    .subscribe(data=>{
      if(data['status'] == true){
        this.toasterService.pop('success',data['message']);
        document.getElementById('goBack').click();
        this.getProjectDetails();
        this.socket.emit("offer_response_freelancer", {
          job_id: this.jobId,
          freelancer_id: this.freelncerId,
          employer_id: this.employerId,
          room: this.roomId
        });
      }
      else
      this.toasterService.pop('error', data['message']);
    })
  }

  priceRateHourly(event){
    //console.log(event);
    this.submitProposalForm.patchValue({
      remotelly_fee: event*0.1
      // amount_receivable: this.f.hourly_rate.value - this.f.remotelly_fee.value
    })
    this.submitProposalForm.patchValue({
      amount_receivable: this.f.hourly_rate.value - this.f.remotelly_fee.value
    })
  }
  priceRateFixed(event){
    this.submitProposalForm.patchValue({
      remotelly_fee: event*0.1
      // amount_receivable: this.f.fixed_rate.value - this.f.remotelly_fee.value
    })
    this.submitProposalForm.patchValue({
      amount_receivable: this.f.fixed_rate.value - this.f.remotelly_fee.value
    })
  }

  totalCostOfOffer(){
    //console.log(this.f.weekly_limit.value)
    let weeklyLimit = this.f.weekly_limit.value;
    let hrsWeekly;
    if(weeklyLimit == '30hrs/weekly'){
      hrsWeekly = 30;
    }
    if(weeklyLimit == '40hrs/weekly'){
      hrsWeekly = 40;
    }
    if(weeklyLimit == '50hrs/weekly'){
      hrsWeekly = 50;
    }
    //console.log("this.f.weekly_limit.value",this.f.amount_receivable.value * this.f.weekly_limit.value * this.f.total_week.value);
    let total = this.f.amount_receivable.value * this.f.weekly_limit.value * this.f.total_week.value
    this.submitProposalForm.patchValue({
      total_cost: total
    })
  }

  uploadFile(event){
    const file = (event.target as HTMLInputElement).files[0];
    //console.log('file size',file.size);
    if(file.size / 1024 / 1024 > 10 ){
      this.toasterService.pop('error', 'file size cannot be more than 10MB');
      return;
    }
    if (file) {
      if (file.size > 1024 * 1024)
        this.fileSize = (Math.round(file.size * 100 / (1024 * 1024)) / 100).toString() + 'MB';
      else
        this.fileSize = (Math.round(file.size * 100 / 1024) / 100).toString() + 'KB';
    }
    this.uploadedFile = file;
    // //console.log('file details',this.uploadedFile);
    // if (file.type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type != 'application/msword') {
    //   this.toasterService.pop('error', 'Please upload only doc, docx or pdf file!');
    //   return;
    // }

    this.submitProposalForm.patchValue({
      file: file
    });
    //console.log(this.submitProposalForm.value);
    // this.submitProposalForm.get('file').updateValueAndValidity()
  }


  applyJob() {
    this.submitted = true;
    this.resumeLoading = true;
    this.submitQuestions = true;
    this.proposalSubmitted = false;
    this.submitCounterOffer =false;
    console.log('list of questions', this.listOfQuestions);
    this.submitProposalForm.patchValue({
      job_id: this.offerDetails.final_hire.job_id,
      invitation_id:this.offerDetails.final_hire.invitation_id,
      final_hires_id:this.offerDetails.final_hire.id, 
      employer_id: this.employerId,
      user_id:this.user_id,  
    })

    if (this.f.file.value == null) {
      // this.toasterService.pop('error', 'Please upload file');
      this.resumeLoading = false;
      // this.fileUpload = true;
      return;
    }
    if (this.offerDetails.final_hire.payment_option && this.offerDetails.final_hire.payment_option == 'Pay By Hour') {
      if (this.f.hourly_rate.value == '' || this.f.hourly_rate.value == null) {
        this.resumeLoading = false;
        return;
      }
      if (this.f.weekly_limit.value == '' || this.f.weekly_limit.value == null) {
        this.resumeLoading = false;
        return;
      }
      if (this.f.total_week.value == '' || this.f.total_week.value == null) {
        this.resumeLoading = false;
        return;
      }


    }
    else if (this.offerDetails.final_hire.payment_option && this.offerDetails.final_hire.payment_option == 'Pay Fixed Price') {
      if (this.f.fixed_rate.value == '' || this.f.fixed_rate.value == null) {
        this.resumeLoading = false;
        return;
      }
      if (this.f.duration.value == '' || this.f.duration.value == null) {
        this.resumeLoading = false;
        return;
      }
    }

    this.answerFill = false;
    if (this.listOfQuestions.length != 0) {
      this.listOfQuestions.forEach(x => {
        if (x.answer == '')
          this.answerFill = true;
        else
          this.answerFill = false;
      })
      if (this.answerFill) {
        this.toasterService.pop('error', 'Please fill all the answers');
        this.submitQuestions = false;
        this.resumeLoading = false;
        return;
      }
    }
    this.submitProposalForm.patchValue({
      answers : this.listOfQuestions
    })

    console.log('formData : ', this.submitProposalForm.value);

    if (this.submitProposalForm.invalid) {
      this.toasterService.pop('error', 'One or More fields are required');
      this.resumeLoading = false;
      this.submitQuestions = false;
      return;
    }

    let formData: any = new FormData();
    formData.append("file", this.f.file.value);
    formData.append("job_id", this.f.job_id.value);
    formData.append("employer_id", this.f.employer_id.value);
    formData.append("user_id", this.f.user_id.value);
    formData.append("profile_sepcification", this.f.profile_sepcification.value);
    formData.append("amount_receivable", this.f.amount_receivable.value);
    formData.append("project_timeperiod", this.f.project_timeperiod.value);
    formData.append("proposal_type", this.f.proposal_type.value);
    formData.append("hourly_rate", this.f.hourly_rate.value);
    formData.append("about", this.f.about.value);
    formData.append("remotelly_fee", this.f.remotelly_fee.value);
    formData.append("fixed_rate", this.f.fixed_rate.value);
    formData.append("start_date", this.f.start_date.value);
    formData.append("due_date_to_accept", this.f.due_date_to_accept.value);
    formData.append("total_cost", this.f.total_cost.value);
    formData.append("duration", this.f.duration.value);
    formData.append("total_week", this.f.total_week.value);
    formData.append("weekly_limit", this.f.weekly_limit.value);
    formData.append('invitation_id', this.f.invitation_id.value);
    formData.append('final_hires_id', this.f.final_hires_id.value);
    formData.append('answers', JSON.stringify(this.listOfQuestions));

    // this.http.post<any>(environment.apiUrl + 'user/apply_job', formData)
    this.userService.submitproposal(formData).subscribe(data => {
      if (data['status'] == true) {
        this.toasterService.pop('success', 'Proposal submitted successfully!');// data['message']);
        this.proposalId = data['data']['id'];
        // console.log('proposal ID',this.proposalId);
        // this.getProposalDetails(this.proposalId);
        this.resumeLoading = false;
        this.submitted = false;
        this.proposalSubmitted = true;
        this.submitCounterOffer = true;
        window.scroll(0,0);
        this.getProjectDetails();
        this.proposalCall();
        this.socket.emit("offer_response_freelancer", {
          job_id: this.jobId,
          freelancer_id: this.freelncerId,
          employer_id: this.employerId,
          room: this.roomId
        });
      }
      else
        this.toasterService.pop('error', data['message']);
      this.submitted = false;
      this.resumeLoading = false
      this.proposalSubmitted = false;
      this.submitCounterOffer = false;
    })
  }

  proposalCall() {
    // this.http.get(environment.apiUrl + 'user/getproposalById/' + this.proposalId)
    this.userService.getproposalById(this.proposalId).subscribe(data => {
      if (data['status'] == true) {
        let result = data['data']
        this.proposalData =[];
        this.proposalData = result;
        this.proposalStartDate = moment(result.start_date).format("Do MMM YYYY");
        this.proposalDueDate = moment(result.due_date_to_accept).format("Do MMM YYYY");

        this.questionsList = result.employer_job.job_questions
        this.downloadURL = this.s3_url + this.proposalData['file']
        this.final_hires_id = result.final_hires_id
        if(result.final_hire.payment_option == "Pay Fixed Price"){
          this.makingAmount = result.fixed_rate
        }
        else if(result.final_hire.payment_option == "Pay By Hour"){
          this.makingAmount = result.total_cost
        }
      }
    })
  }

  refuseCall() {
    let obj = {
      proposal_id: this.proposalId
    }
    // this.http.post(environment.apiUrl + 'employer/decline-proposal', obj)
    this.employeerService.employerdeclineproposal(obj).subscribe(data => {
      if (data['status'] == true) {
        let result = data['data']
        this.toasterService.pop('success', 'Proposal Refused');
        // this.proposalStatus = true
        this.acceptProposal =true;
        this.getProjectDetails();
        this.proposalCall()
        this.socket.emit("offer_response_employer", {
          job_id: this.jobId,
          freelancer_id: this.freelncerId,
          employer_id: this.employerId,
          room: this.roomId
        });
        // this.router.navigate([`/employer/dashboard`]);
      }else{
        this.acceptProposal =false
      }
    })
  }

  acceptCall() {
    let obj = {
      proposal_id: this.proposalId,
      invitation_id : this.offerDetails.final_hire.invitation_id
    }
    // this.http.post(environment.apiUrl + 'employer/accept-proposal', obj)
    this.employeerService.employeracceptproposal(obj).subscribe(data => {
      if (data['status'] == true) {
        let result = data['data']
        this.toasterService.pop('success', 'Proposal accepted');
        this.acceptProposal =true;
        this.getProjectDetails();
        // this.proposalStatus = true
        this.proposalCall();
        this.socket.emit("offer_response_employer", {
          job_id: this.jobId,
          freelancer_id: this.freelncerId,
          employer_id: this.employerId,
          room: this.roomId
        });
      }else{
        this.acceptProposal =false;
      }
    })
  }

    refreshData(){ 
        this.dataRefresher =setInterval(() => {
                this.projectDetails();
            //Passing the false flag would prevent page reset to 1 and hinder user interaction
        }, 15000);
    }
    getTodayDate() {
      // return new Date().toISOString().split('T')[0]
      var day = new Date().getDate();
      //var month = ('0' + (new Date().getMonth() + 1)).slice(-2); // new Date().getMonth()
      var month = new Date().getMonth();
      var year = new Date().getFullYear();
      var minDate = new Date(year, month, day, 0, 0);
      //return year + '-' + month + '-' + day;
      return minDate;
    }
    async projectDetails(){
        await this.videoChatService.getProjectDetails({
        "job_id": this.jobId,
        "freelancer_id": this.freelncerId,
        "employer_id": this.employerId
        })
      .subscribe( (data) => {
        if (data["status"]) {
          //console.log(data["data"].final_hire);
          this.inviteId = data["data"].job_invites[0].id;
          //console.log('this.inviteId', this.inviteId)
          // if (typeof data["data"].final_hire !== 'undefined') {
          if (this.acceptProposal){
             this.proposalCall();
            console.log('propoal api call--------')
          }
          if(this.sendFixedOffer || this.sendHourlyOffer){
            console.log('get project details inside send hourly/fixed');
             this.getProjectDetails();
          }
          // this.proposalFromFreelancer = false;
          let appliedJobs = data["data"].applied_jobs;
          if(appliedJobs.length > 0){
            console.log("inside proposal summary!.-----")
            this.proposalId = appliedJobs[0].id;
            this.proposalFromFreelancer =true;
            this.proposalSubmitted =true;
            this.proposalCall();
          }else{
            console.log("outside proposal summary-------")
            this.proposalFromFreelancer =false;
            this.proposalSubmitted =false;
          }
          console.log('proposalFromFreelancer value in api call-----',this.proposalFromFreelancer);
          let finalHire = data["data"].final_hire;
          if(finalHire && finalHire.status == "pending"){
            // this.showOffer =  true;
             this.getProjectDetails();
            this.employerOffer =  false;
            this.freelancerOffer = true;
          }else if(finalHire && finalHire.status == "accepted"){
            this.employerOffer =  false;
            this.freelancerOffer = false;
          }
          else{
            // this.showOffer =  false;
            this.employerOffer =  true;
            this.freelancerOffer = false;
            
          }
        }
      });
    }

    async onSettingsChanged(deviceInfo?: MediaDeviceInfo) { console.log('call setting')
        await this.camera.initializePreview(deviceInfo.deviceId);
        if (this.settings.isPreviewing) {
            const track = await this.settings.showPreviewCamera();
            if (this.activeRoom) {
                const localParticipant = this.activeRoom.localParticipant;
                localParticipant.videoTracks.forEach(publication => publication.unpublish());
                await localParticipant.publishTrack(track);
            }
        }
    }
    /* async onSettingsChanged(deviceInfo: MediaDeviceInfo) {
      await this.camera.initializePreview(deviceInfo.deviceId);
    } */

    // test screen share

checkMedia(){
  const permissionDescriptors:any = [
    { name: 'camera' },
    { name: 'microphone' },
  ];

    navigator.mediaDevices.getUserMedia({audio: true, video: true})
      .then(() => {
        // if (navigator.mediaDevices || navigator.mediaDevices.getUserMedia) {
          // let navigator: Navigator;
          navigator.permissions.query(permissionDescriptors)
            .then(({ state }) => {
              if (state == 'granted') {
                console.log("permission granted!");

              } else if (state == 'denied') {
                console.log("permission denied!");
              }
            })
        // }
      })
      .catch((err) =>{
        console.log(err);
        this.toasterService.pop('error', err.message);
      })
}

getAudioAndVideoPermissions(){
  let mediaStream;
  try {
    const constraints = { audio: true, video: true };
    mediaStream = navigator.mediaDevices.getUserMedia(constraints);
  }
  catch (ex) {
    if (ex instanceof DOMException) {
      if (ex.name === 'NotAllowedError') {
        // handle permission denied
        console.log('not allowed error');
        // this.checkMedia();
      }
      else if (ex.name === 'NotFoundError') {
        // handle media not found
        this.toasterService.pop('error', 'No media found for audio/video!.')
      }
      else {
        // handle unexpected DOMException
      }
    }
    else {
      // handle unexpected error
    }
  }

  if (!mediaStream) {
    // handle no stream
    console.log('not mdeiaStram');
  }
  else {
    // do something with stream
    console.log('mdeiaStram');
  }
}

 shareScreenHandler() {
  const shareScreen = document.getElementById('share_screen')
  event.stopPropagation();
  if (!this.screenTrack) {
    let track = this.activeRoom.localParticipant.tracks;
    console.log('local track-=');
    this.activeRoom.localParticipant.tracks.forEach((track) => {
      console.log('get tracks',track);
      // track.track.stop();
      // track.unpublishTracks(track.track);
    });
    const tracks = Array.from(this.activeRoom.localParticipant.videoTracks.values())
      .map(publication => console.log(publication));
    // this.activeRoom.localParticipant.unpublishTrack();

    const mediaDevices = navigator.mediaDevices as any;
    mediaDevices.getDisplayMedia({
      video: true
    }).then(stream => {
      this.screenTrack = LocalVideoTrack(stream.getTracks()[0]);
      console.log('stream tarcks here-----',stream.getTracks());
      this.activeRoom.localParticipant.publishTrack(this.screenTrack);
      shareScreen.innerHTML = 'Stop sharing';
      this.screenSharing = true;
      this.screenTrack.mediaStreamTrack.onended = () => { this.shareScreenHandler() };
    }).catch(() => {
      alert('Could not share the screen.');
    });
  }
  else {
    this.activeRoom.localParticipant.unpublishTrack(this.screenTrack);
    this.screenTrack.stop();
    this.screenTrack = null;
    shareScreen.innerHTML = 'Share screen';
    this.screenSharing = false;
    // this.activeRoom.localParticipant.tracks.forEach(function (track) {
    //   track.restart();
    // });
  }
};


zoomTrack(trackElement) {
  let container = document.getElementById('container');
  if (!trackElement.classList.contains('participantZoomed')) {
    // zoom in
    (container.children as any).forEach(participant => {
      if (participant.className == 'participant') {
        participant.childNodes[0].childNodes.forEach(track => {
          if (track === trackElement) {
            track.classList.add('participantZoomed')
          }
          else {
            track.classList.add('participantHidden')
          }
        });
        participant.childNodes[1].classList.add('participantHidden');
      }
    });
  }
  else {
    // zoom out
    (container.children as any).forEach(participant => {
      if (participant.className == 'participant') {
        participant.childNodes[0].childNodes.forEach(track => {
          if (track === trackElement) {
            track.classList.remove('participantZoomed');
          }
          else {
            track.classList.remove('participantHidden');
          }
        });
        participant.childNodes[1].classList.remove('participantHidden');
      }
    });
  }
};


    // test end

    async onMuteRoom() { 
        if (this.activeRoom) { 
            if (!this.isMute) {
                this.activeRoom.localParticipant.audioTracks.forEach(track => {
                    track.track.disable();
                    // console.log("audioTrack-- "+track);
                });
                return this.isMute = true;
            } else {
                this.activeRoom.localParticipant.audioTracks.forEach(track => {
                  // console.log("audioTrack-- "+track);
                    track.track.enable();
                });
                return this.isMute = false;
            }
      }
    }

    async onMuteVideoRoom() {
        if (this.activeRoom) {
            if (!this.isVideoMute) {
                this.activeRoom.localParticipant.videoTracks.forEach(trackVideo => {
                  // console.log("videoTrack-- "+trackVideo);
                  //track.track.stop();
                  trackVideo.track.disable();
                });
                return this.isVideoMute = true;
            } else {
                this.activeRoom.localParticipant.videoTracks.forEach(trackVideo => {
                    //console.log("againTrack-- "+trackVideo.track);
                    //track.track.restart(track as any);
                    //track.track.attach();
                    trackVideo.track.enable();
                });
                return this.isVideoMute = false;
            }

        }
    }
    public onisAloneSet(dataBoolean: boolean = true):void {
      // console.log('is alone ', dataBoolean);
      this.checkIsAlone = dataBoolean;
      if (!this.checkIsAlone && this.is_urgently && !this.bothParticipantsPresent){
          this.getAppointmentDetails();
      }
    }

  // public onparticipantSize(event): void{
  //   console.log("participant size-----------",event);
  // }
    
    async onLeaveRoom() {
        if (this.activeRoom) {
          this.activeRoom.localParticipant.tracks.forEach(function(track) { 
            track.track.stop()
          });
          
          // const mediaTracks = [localVideoTrack, localAudioTrack];
          // const tracks = [...mediaTracks, localDataTrack];

          // mediaTracks.forEach(track => track.stop());
          // this.activeRoom.localParticipant.unpublishTracks(tracks);
          // mediaTracks.forEach(track => track.detach());

            this.activeRoom.disconnect();
            this.activeRoom = null;
        }

        const videoDevice = this.settings.hidePreviewCamera();
        await this.camera.initializePreview(videoDevice && videoDevice.deviceId);

        this.participants.clear();
        // this.deleteCallLoader = true;
        document.getElementById('closeCardModel').click();
        this.openRatingModel();
        this.shareScreenHandler();
        // this.deleteCallLoader = false;
    }
  openRatingModel(){
    document.getElementById('callRatingModel').click();

  }
  rateCall(type: string) {
    console.log('rating call', this.callRatingData);
    if (type == 'yes') {
      this.callRatingFlag =true;
      let user = '';
      if (this.currentUserRole == 'ROLE_EMPLOYER') {
        user = 'employer';
      } else {
        user = 'freelancer'
      }
      let formData = {
        review_by: user,
        room_id: this.roomId,
        freelancer_id: this.freelncerId,
        employer_id: this.employerId,
        rating_star: this.callRatingData
      }
      if (this.callRatingData == 0){
        this.toasterService.pop('error', 'Please give min 1 star rating!.')
        return;
      }
      console.log('call rating form data', formData);
      this.videoChatService.ratingCall(formData)
        .subscribe(response => {
          if (response['status']) {
            this.callRatingFlag =false;
            this.toasterService.pop('success', response['message']);
            this.endCallBetweenUserAndRedirect();
            this.callRatingData = 0;
          } 
          else {
            this.callRatingFlag = false;
            this.toasterService.pop('error', response['message']);
          }
        })
    } else if ('no') {
      this.endCallBetweenUserAndRedirect();
      this.callRatingData = 0;
    }

  }

  endCallBetweenUserAndRedirect() {
    if (this.currentUserRole == 'ROLE_FREELANCER') {
      //this.router.navigate(['/freelancer/dashboard']);
      window.location.href = '/freelancer/dashboard';
    }
    if (this.currentUserRole == 'ROLE_FREELANCER AGENCY') {
      window.location.href = '/freelancer-agency/dashboard';
    }
    if (this.currentUserRole == 'ROLE_EMPLOYER') {
      this.transferAmount();
    }
  }

  transferAmount(){
    document.getElementById('closeRatingModal').click();
    this.spinner.show('transaction');
    var hourlyRate = this.appointmentDetails.hourly_rate
    if (this.appointmentDetails.is_urgently) {
      var duration = this.appointmentDetails.duration
      hourlyRate = (hourlyRate * duration) / 60
    }
    // console.log(hourlyRate)
    var job_id = this.appointmentDetails.job_id
    this.videoChatService.transferAmoutTransfer(this.freelncerId, this.employerId, hourlyRate, job_id).subscribe(data => {
      if (data['status']) {
        let transferDetails = data['data']
        this.spinner.hide('transaction');

        this.spinner.show('timeLeft');
        // set inerval to intimatre user for payment api response
        this.timeleft = 11;
        let timer = setInterval(() => {
          this.timeleft--;
          if (this.timeleft <= 0) {
            clearInterval(timer);
            console.log('time ended');
            this.videoChatService.getPaymentActions(transferDetails.id)
              .subscribe(response => {
                if (response['status']) {
                  let checkStatus = response['data'];
                  if (checkStatus.length && checkStatus[0].approved) {
                    setTimeout(() => {
                      this.statusChange();
                      this.spinner.hide('timeLeft');
                      window.location.href = '/employer/dashboard';

                    }, 1500)
                  }
                  else if (checkStatus.length && !checkStatus[0].approved) {
                    this.toasterService.pop('error', checkStatus[0].response_summary + ' at freelancer end');
                    setTimeout(() => {
                      this.statusChange();
                      this.spinner.hide('timeLeft');
                      window.location.href = '/employer/dashboard';
                    }, 1500)
                  }
                } else {
                  this.toasterService.pop('error', response['message']);
                  setTimeout(() => {
                    this.statusChange();
                    this.spinner.hide('timeLeft');
                    window.location.href = '/employer/dashboard';
                  }, 1500)
                }
              })
          } else {
            console.log('time remaining:', this.timeleft)
          }
        }, 1000)
        // end
         
      } else if (!data['status'] && data['statusCode'] == 200){
        this.toasterService.pop('error', data['message']);
        setTimeout(() => {
          this.statusChange();
          this.spinner.hide('timeLeft');
          window.location.href = '/employer/dashboard';
        }, 1500)
      }else{
        setTimeout(() => {
          this.statusChange();
          this.spinner.hide('timeLeft');
          window.location.href = '/employer/dashboard';
        }, 1500)
      }
    }, error => {console.log('error in transaction',error)});
  }

statusChange(){
  this.videoChatService.changeStatusAftercall(this.roomId).subscribe(data => {
    if (data['status']) {
      //console.log('status', data['status'])
      // this.AllPortfolio = data['data'];
      // this.router.navigate(['/employer/dashboard']);
      window.location.href = '/employer/dashboard';
    }
  })
}

    async onRoomChanged(roomName: string) {
        if (roomName) {
            if (this.activeRoom) {
                this.activeRoom.disconnect();
            }
            console.log('ddd', roomName)
            this.camera.finalizePreview();

            const tracks = await Promise.all([
                createLocalAudioTrack(),
                this.settings.showPreviewCamera()
            ]);

            this.activeRoom =
                await this.videoChatService
                          .joinOrCreateRoom(roomName, this.roomId, tracks);

            this.participants.initialize(this.activeRoom.participants);
            this.registerRoomEvents();

            // this.notificationHub.send('RoomsUpdated', true);
        }
    }

    onParticipantsChanged(_: boolean) {
        this.videoChatService.nudge();
    }

    joinReminderCall(){
      if (this.currentUserRole == 'ROLE_FREELANCER' || this.currentUserRole == 'ROLE_FREELANCER AGENCY'){
        this.videoChatService.joinReminderCallFreelancer(this.freelncerId, this.employerId, this.roomId).subscribe(data=>{
          if(data['status']){
            // this.AllPortfolio = data['data'];
            // this.updateParticipantService.getAppointmentDetails(this.roomId);
            this.getAppointmentDetails();
          }
        })
      }
      if(this.currentUserRole == 'ROLE_EMPLOYER'){
        this.videoChatService.joinReminderCallEmployer(this.freelncerId, this.employerId, this.roomId).subscribe(data=>{
          if(data['status']){
            // this.updateParticipantService.getAppointmentDetails(this.roomId);
            this.getAppointmentDetails()
            //console.log('status', data['status'])
            // this.AllPortfolio = data['data'];
          }
        })
      }
    }

    private registerRoomEvents() { console.log('registerRoomEvents')
    // console.log('this.participants', this.participants)
    this.onisAloneSet();
    // this.onparticipantSize();
    this.joinReminderCall();
        this.activeRoom
            .on('disconnected',
                (room: Room) => room.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track)))
            .on('participantConnected',
                (participant: RemoteParticipant) => {
                this.participants.add(participant);
                let count = this.participants.participantCount;
                console.log("count size",count);
                  if (this.is_urgently && !this.bothParticipantsPresent) {
                    this.getAppointmentDetails();
                  }
                // this.updateParticipantService.getAppointmentDetails(this.roomId);
                // if(!this.bothParticipantsPresent){
                //   console.log()
                //   this.getAppointmentDetails();
                // }
                console.log("participant count",this.participants.participantCount);
                })
            // .on('trackDisabled',
            //     (participant: RemoteParticipant) => this.participants.remove(participant))
            // .on('trackEnabled',
            //     (participant: RemoteParticipant) => this.participants.add(participant))
            .on('participantDisconnected',
                (participant: RemoteParticipant) => this.participants.remove(participant))
            .on('dominantSpeakerChanged',
                (dominantSpeaker: RemoteParticipant) => this.participants.loudest(dominantSpeaker));
    }

    private detachLocalTrack(track: LocalTrack) {
        if (this.isDetachable(track)) {
            track.detach().forEach(el => el.remove());
        }
    }

    private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
        return !!track
            && ((track as LocalAudioTrack).detach !== undefined
                || (track as LocalVideoTrack).detach !== undefined);
    }

    cancelPageRefresh(){
        if(this.dataRefresher){
            clearInterval(this.dataRefresher);
        }    
    }
    // ngOnDestroy(){
    //     this.cancelPageRefresh();
    // }

  // countdown timer
  start(minutes, seconds) {
    console.log("minutes",minutes);
    this.timer(minutes, seconds);
  }
  stop() {
    clearInterval(this.timerInterval);
  }

  timer(minute, second) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let totalSeconds = second
    let statSec: number = 60;

    if (totalSeconds >=60){
      statSec = 60
    }else{
      statSec = totalSeconds;
    }

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      // console.log(this.display);
      if (this.display.toString() == '10:00') {
        this.toasterService.pop("info", 'Only 10 minutes left!...')
      }

      if (this.display.toString() == '05:00'){
        this.toasterService.pop("warning", 'Only 5 minutes left!...')
      }

      if (seconds == 0) {
        console.log('finished');
        clearInterval(this.timerInterval);
        this.onLeaveRoom();
      }
    }, 1000);
  }
  // countdown timer end
}
