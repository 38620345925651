import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { environment } from "../../../environments/environment";
import { EmployerserviceService } from '../../services/employer-service/employerservice.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  user_id :string = '';
  s3_url =environment.s3_url;
  public Isloggedin=true;
  currnetUserRole='';
  employerType: any;
  postJobRadio: boolean;
  userData:any;
  id: any;
  cardData:any;
  postUrl:any;

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private employeerService:EmployerserviceService
  ) { }

  ngOnInit() {
    if (document.location.href.indexOf('/authentication/') > -1 || document.location.href.indexOf('/login') > -1) {
      this.Isloggedin = false;
    }
    let userData = JSON.parse(localStorage.getItem('currentUser'));
    if(userData && userData['data'])
      this.currnetUserRole=userData['data']['roles'][0];
      console.log('current user footer-----',this.currnetUserRole);
      this.Isloggedin = true;
      this.userData=userData;
    if(userData != null && this.currnetUserRole == 'ROLE_EMPLOYER'){
      this.id = userData.id;
      
      // this.http.get(environment.apiUrl + 'employer/get-employertype/'+ this.id)
      this.employeerService.getEmployertype(this.id).subscribe(data => {
        if (data['status'] == true) {
          let result = data['data']
          this.employerType = result
          if(this.employerType.employer_type == 1){
            this.postJobRadio = true
          }
          else{
            this.postJobRadio = false
          }
        }
      });
  }
}

goToUrl(url){
  this.router.navigate(['freelancer/'+url]);
}
goToJobseekerUrl(url){
  this.router.navigate(['jobseeker/'+url]);
}

goToEmployerUrl(url){
  console.log(url,'url')
  this.router.navigate(['employer/'+url]);
}
goToAgencyUrl(url){
  this.router.navigate(['freelancer-agency/'+url]);
}

postAJobProject(url){
  this.userService.getUserCardDetails(this.id).subscribe(data =>{
    if(data['status']){
      console.log('card data', data['data']);
      this.cardData = data['data'];
      this.postUrl = url;
  if(this.cardData.length > 0){
    console.log(url,'url')
    this.router.navigate(['employer/'+url]);
  }else{
    document.getElementById('verifypayment').click()
  }
    }
  });
  
}

addCardVerify(){
  this.router.navigate(['employer/setting'],{queryParams: { post:this.postUrl}});
  document.getElementById('closeverify').click();
}

  contactUs() {
    this.router.navigate(['/contact-us']);
  }

  termsOfUse() {
    this.router.navigate(['/terms-of-use']);
  }

  faq() {
    this.router.navigate(['/faq']);
  }

  services() {
    this.router.navigate(['/services']);
  }

}
