import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Signup } from '../../models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmployerserviceService {

  constructor(private http: HttpClient) { }
  getDataForPostJob(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-data-for-postjob', formData);
  }
  getMasterdataForPostJob() {
    return this.http.get(environment.apiUrl + 'masterdata/get-for-post-job');
  }
  getsecondaryjobsapplicants(job_id){
    return this.http.get(environment.apiUrl + 'user/get-secondary-jobs-applicants/' + job_id)
  }
  getPostedSecondaryjobs(obj){
    return this.http.post(environment.apiUrl + 'employer/get-posted-secondaryjobs', obj)
  }
  masterdatalanguagesweb(){
    return this.http.get(environment.apiUrl + 'masterdata/languages/web')
  }
  getallapplicantbysecondaryjobid(obj){
    return this.http.post(environment.apiUrl + 'employer/get-all-applicantby-secondary-jobid', obj)
  }
  unsavejobseekerbySecondaryjobid(obj){
    return this.http.post(environment.apiUrl + 'employer/unsave-jobseeker-bySecondaryjobid', obj)
  }
  savejobseekerbySecondaryjobid(obj){
    return this.http.post(environment.apiUrl + 'employer/save-jobseeker-bySecondaryjobid', obj)
  }
  getEmployerContracts(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-all-contracts', formData);
  }
  getEmployertype(employer_id){
    return this.http.get(environment.apiUrl + 'employer/get-employertype/' + employer_id)
  }
  reScheduleAppointmentRoom(data:any){
    return this.http.post<any>(environment.apiUrl + 'user/reScheduleAppointmentRoom', data)
  }
  getpostedprojects(obj){
    return this.http.post(environment.apiUrl + 'employer/get-posted-projects', obj)
  }
  getemployerUserCardDetails(userId) {
    return this.http.get(environment.apiUrl + 'user/get_user_allbilling_card/' + userId + '/' + 10);
  }
  employeraddpostedjob(obj){
    return this.http.post(environment.apiUrl + 'employer/add-posted-job', obj)
  }
  employeeaddpostedsecondaryjobs(obj){
    return this.http.post(environment.apiUrl + 'employer/add-posted-secondaryjobs', obj)
  }
  deleteSecondaryJob(formData) {
    return this.http.post(environment.apiUrl + 'user/remove-secondary-job', formData);
  }
  deleteJob(formData) {
    return this.http.post(environment.apiUrl + 'user/remove-postjob', formData);
  }
  employersearchfreelancerforweb(obj){
    return this.http.post(environment.apiUrl + 'employer/search-freelancerforweb', obj)
  }
  employersearchjobseeker(obj){
    return this.http.post(environment.apiUrl + 'employer/search-jobseeker', obj)
  }
  getUsercontractmilestones(obj){
    return this.http.post(environment.apiUrl + 'user/get-User-contract-milestones', obj)
  }
  getemployeeruserresolveddispute(formData){
    return this.http.post(environment.apiUrl + 'user/get_user_resolved_dispute', formData)
  }
  getemployeeruserunresolveddispute(formData){
    return this.http.post(environment.apiUrl + 'user/get_user_unresolved_dispute', formData)
  }
  employeerchangeDisputeStatus(formData) {
    return this.http.post(environment.apiUrl + 'user/change_dispute_status', formData);
  }
  getemployeeradduserdispute(formData) {
    return this.http.post(environment.apiUrl + 'user/add_user_dispute', formData);
  }
  employeerendContract(formData) {
    return this.http.post(environment.apiUrl + 'user/end-contract', formData);
  }
  getProjectPreview(formData) {
    return this.http.post(environment.apiUrl + 'freelancer/get-project-details', formData);
  }
  getUserAvailabilities(formData){
    return this.http.post(environment.apiUrl+ 'user/getUserAvailabilities',formData)
  }
  deleteemployerconnectedfreelancer(obj){
    return this.http.post(environment.apiUrl + 'user/delete-employer-connected_freelancer', obj)
  }
  getEmployerAllAppointmentRoom(obj){
    return this.http.post(environment.apiUrl + 'user/getEmployerAllAppointmentRoom', obj)
  }
  employerPaymentToCreditBalanceInBusinessAccount(obj){
    return this.http.post(environment.apiUrl + 'employer/employerPaymentToCreditBalanceInBusinessAccount', obj)
  }
  employerPaymentToCreditBalanceInBusinessAccount1(obj){
    return this.http.post(environment.apiUrl + 'employer/employerPaymentToCreditBalanceInBusinessAccountNew', obj)
  }
  onvalidatemerchant(obj){
    return this.http.post(environment.apiUrl + 'employer/validateMerchantUrl',obj)
  }
  getPaymentDetailsById(payObj){
    return this.http.post(environment.apiUrl + 'employer/getPaymentDetailsById', payObj)
  }
  getReviewProposalslist(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-job-proposals', formData);
  }
  getemployeerfreelancerlist(){
    return this.http.get(environment.apiUrl + 'employer/get-freelancerlist/1000')
  }
  getArchivedProposals(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-job-archived-proposals', formData);
  }
  getMessageProposalsList(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-job-messaged-proposals', formData);
  }
  getJobStats(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-job-stats', formData);
  }
  getAllHires(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-hire-list', formData);
  }
  hireFreelancer(formData) {
    return this.http.post(environment.apiUrl + 'employer/hire-freelancer', formData);
  }
  startConversation(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/start-conversation', formData);
  }
  getAllJobProposals(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-proposals', formData);
  }
  getFreelancerListById(id) {
    return this.http.get(environment.apiUrl + 'user/user_by_id/' + id);
  }
  sendJobInviteToFreelancer(id, userID, jobid, data): Observable<any> {
    let formData = {
      freelancer_id: id,
      employer_id: userID,
      job_id: jobid,
      message: data.message
    }
    return this.http.post(environment.apiUrl + 'employer/invite-freelancer', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  searchFreelancerToInvite(params) {
    return this.http.get(environment.apiUrl + 'employer/search-freelancer', { params });
  }
  getActiveInvitedFreelancersList(formData) {
    return this.http.post(environment.apiUrl + 'employer/view-sent-active-invites', formData);
  }
  getFreelancerList(jobid) {
    return this.http.get(environment.apiUrl + 'employer/get-freelancer-recommendation/' + jobid);
  }
  removeFromSavedFreelancer(formData) {
    return this.http.post(environment.apiUrl + 'employer/remove-saved-freelancer', formData)
  }
  savefreelancerprofle(id, userID, jobId): Observable<any> {
    let formData = {
      freelancer_id: id,
      employer_id: userID,
      job_id: jobId
    }
    return this.http.post(environment.apiUrl + 'employer/save-freelancer', formData)
  }
  getSavedFreelancerList(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-saved-freelancers', formData);
  }
  
  getProposalDetails(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-proposal-details', formData);
  }
  getHiresForAJob(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-hires-for-job', formData);
  }
  employeeradduserContract(obj){
    return  this.http.post(environment.apiUrl + 'user/adduserContract', obj)
  }
  employeeruserprofile(userId){
    return this.http.get(environment.apiUrl + 'user/user_profile/' + userId)
  }
  getproposalById(id: string) {
    return this.http.get(environment.apiUrl + 'user/getproposalById/' + id);
  }
  employeergetofferdetails(obj){
    return this.http.post(environment.apiUrl + 'user/get-offer-details', obj)
  }
  getAllEmployment(userId) {
    return this.http.get(environment.apiUrl + 'user/getAll-employment_details/' + userId);
  }
  getAllEducation(userId) {
    return this.http.get(environment.apiUrl + 'user/getAll-education_details/' + userId);
  }
  getAllAdditionalDetails(userId) {
    return this.http.get(environment.apiUrl + 'user/getAll-additional_details/' + userId);
  }
  getAllSettingDetails(userId) {
    return this.http.get(environment.apiUrl + 'user/get-setting_details/' + userId);
  }
  getAllPortfolioDetails(userId) {
    return this.http.get(environment.apiUrl + 'user/getAll-portfolio_details/' + userId);
  }
  sendMessage(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/send', formData);
  }
  saveFreelancerProfile(formData) {
    return this.http.post(environment.apiUrl + 'employer/save-profile', formData);
  }
  unsaveemployeerprofile(obj){
    return this.http.post(environment.apiUrl + 'employer/unsave-profile', obj)
  }
  getsavedprofilesbyjobId(obj){
    return this.http.post(environment.apiUrl + 'employer/get-saved-profilesbyjobId', obj)
  }
  getallacceptedoffer(obj){
    return this.http.post(environment.apiUrl + 'user/get-allaccepted-offer ', obj)
  }
  gethiredfreelancerbyjobid(obj){
    return this.http.post(environment.apiUrl + 'user/get-hired-freelancerbyjobid', obj)
  }
  masterdataskillsweb(){
    return this.http.get(environment.apiUrl + 'masterdata/skills/web')
  }
  masterdatacountriesweb(){
   return this.http.get(environment.apiUrl + 'masterdata/countries/web')
  }
  editJobDetails(formData) {
    return this.http.post(environment.apiUrl + 'employer/edit-job-details', formData);
  }
  employeerviewsentinvites(obj){
    return this.http.post(environment.apiUrl + 'employer/view-sent-invites', obj)
  }
  getJobAndUserDetails(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-project-user-details', formData);
  }
  finalHireFreelancer(formData, milestone_list, job_id, freelancer_id, employer_id) {
    formData['milestones_list'] = milestone_list;
    formData['job_id'] = job_id;
    formData['freelancer_id'] = freelancer_id;
    formData['employer_id'] = employer_id;
    return this.http.post(environment.apiUrl + 'employer/hire-freelancer-final', formData);
  }
  getdraftssecondaryjobs(obj){
    return this.http.post(environment.apiUrl + 'employer/get-drafts-secondaryjobs', obj)
  }
  employergetallongoingcontracts(obj){
    return  this.http.post(environment.apiUrl + 'employer/get-all-ongoingcontracts', obj)
  }
  employergetallCompletedcontracts(obj){
    return this.http.post(environment.apiUrl + 'employer/get-all-Completedcontracts', obj)
  }
  employeercreateAppointmentRoom(obj1){
    return this.http.post(environment.apiUrl + 'user/createAppointmentRoom', obj1)
  }
  employerinvitefreelancer(obj2){
    return this.http.post(environment.apiUrl + 'employer/invite-freelancer', obj2)
  }
  employergetdraftsprojects(obj){
    return this.http.post(environment.apiUrl + 'employer/get-drafts-projects', obj)
  }
  employerhirefreelancerfinal(obj){
    return this.http.post(environment.apiUrl + 'employer/hire-freelancer-final', obj)
  }
  employergetlastproject(){
    return this.http.get(environment.apiUrl + 'employer/get-last-project')
  }
  getpostedprojectsforreuse(obj){
    return this.http.post(environment.apiUrl + 'employer/get-posted-projects-for-reuse', obj)
  }
  postEmployerProjectDetails(formData) {
    return this.http.post(environment.apiUrl + 'employer/post-job', formData);
  }
  addProjectQuestions(formData) {
    return this.http.post(environment.apiUrl + 'employer/post-job-questions', formData);
  }
  addProjectPreferences(formData) {
    return this.http.post(environment.apiUrl + 'employer/post-job-preference', formData);
  }
  employeradddraftjob(obj){
    return this.http.post(environment.apiUrl + 'employer/add-draft-job', obj)
  }
  employergetlastjob(){
    return this.http.get(environment.apiUrl + 'employer/get-last-job')
  }
  employerpostsecondaryjob(savePostJob){
    return this.http.post(environment.apiUrl + 'employer/post-secondary-job', savePostJob)
  }
  employergetsecondaryjobdetails(obj){
    return this.http.post(environment.apiUrl + 'employer/get-secondary-job-details', obj)
  }
  employereditsecondaryjobdetails(postJob){
    return this.http.post(environment.apiUrl + 'employer/edit-secondary-job-details', postJob)
  }
  employeradddraftsecondaryjobs(obj){
    return this.http.post(environment.apiUrl + 'employer/add-draft-secondaryjobs', obj)
  }
  employeracceptproposal(obj){
    return this.http.post(environment.apiUrl + 'employer/accept-proposal', obj)
  }
  employergettransactions(obj){
    return this.http.post(environment.apiUrl + 'employer/get-transactions', obj);
  }
  getContractMilestones(formData) {
    return this.http.post(environment.apiUrl + 'user/get-contract-milestones', formData);
  }
  transferAmountToFreelancer(formData){
    return this.http.post(environment.apiUrl + 'employer/transferAmountToFreelancer', formData);
  }
  getPaymentActions(paymentId){
    return this.http.post(environment.apiUrl + 'employer/getPaymentActions', { paymentId: paymentId });
  }
  submitWorkStatus(formData){
    return this.http.post(environment.apiUrl + 'user/submit_work_status', formData);
  }
  rejectSubmittedWork(formData) {
    return this.http.post(environment.apiUrl + 'user/reject_submit_work', formData);
  }
  employergetjobseekerlist(){
   return this.http.get(environment.apiUrl + 'employer/get-jobseekerlist/1000')
  }
  getUserJobs(user_id) {
    return this.http.get(environment.apiUrl + 'user/getjobs/' + user_id);
  }
  createmilestone(user_id, jobData): Observable<any> {
    console.log('job', jobData);
    let formData = {
      totalnumberofmilestone: jobData.totalnumberofmilestone,
      fixed_rate: jobData.fixed_rate,
      milestoneNumber: jobData.milestoneNumber,
      paypermilestone: jobData.paypermilestone,
      start_date: jobData.start_date,
      end_date: jobData.end_date,
      descrpition: jobData.descrpition,
      job_id: jobData.job_id,
      user_id: user_id
    }
    return this.http.post<any>(environment.apiUrl + 'user/createmilestone', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  getmilestonebyid(id) {
    return this.http.get(environment.apiUrl + 'user/getmilestonebyid/' + id);
  }
  updateMilestoneById(id, userId, Data): Observable<any> {
    console.log('Job', Data);
    let formData = {
      totalnumberofmilestone: Data.totalnumberofmilestone,
      fixed_rate: Data.fixed_rate,
      milestoneNumber: Data.milestoneNumber,
      paypermilestone: Data.paypermilestone,
      description: Data.description,
      start_date: Data.start_date,
      end_date: Data.end_date,
      job_id: Data.job_id,
      user_id: userId
    }
    return this.http.post<any>(environment.apiUrl + 'user/update_milestone/' + id, formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  getUserMilestones(user_id) {
    return this.http.get(environment.apiUrl + 'user/getmilestone/' + user_id);
  }
  employerdeclineproposal(obj){
    return this.http.post(environment.apiUrl + 'employer/decline-proposal', obj)
  }
  employeersearchjobseeker(obj){
    return this.http.post(environment.apiUrl + 'employer/employer/search-jobseeker', obj)
  }
  getemployerconnectedfreelancers(data){
    return this.http.post(environment.apiUrl + 'user/get-employer-connected_freelancers', data)
  }

  getAllRescheduledVideoCallInvitations(data){
    return this.http.post(environment.apiUrl + 'user/getAllRescheduledVideoCallInvitaions', data)
  }
  getemployerReport(id:any){
    return this.http.get(environment.apiUrl +'user/employer-reports/'+id);
  }
  getemployerVideoReport(id:any){
    return this.http.get(environment.apiUrl +'user/employer-videos-reports/'+id);
  }
}
