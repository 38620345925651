import { Routes } from '@angular/router';
import { AuthComponent } from '../layout/auth/auth.component';
import { AuthGuard } from '../helpers';
import { AboutUsComponent } from '../pages/stationary-pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from '../pages/stationary-pages/privacy-policy/privacy-policy.component';
import { WhyRemotellyComponent } from '../pages/stationary-pages/why-remotelly/why-remotelly.component';
import { ViewBlogDetailsComponent } from '../pages/stationary-pages/blogs/view-blog-details/view-blog-details.component';


export const DEFAULT_ROUTES: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        loadChildren: () => import('../pages/home-page/home-page.module').then(m => m.HomePageModule)
      },
      {
        path: 'authentication',
        loadChildren: () => import('../pages/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'search',
        loadChildren: () => import('../pages/home-page/search/search.module').then(m => m.SearchModule)
      },
      {
        path: 'search-user',
        loadChildren: () => import('../pages/home-page/search-users/search-user.module').then(m => m.SearchUserModule)
      },

    ]
  },

  {
    path: 'home',
    loadChildren: () => import('../pages/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('../pages/auth/login/basic-login/basic-login.module').then(m => m.BasicLoginModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('../pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'agency_invitation/accept/:freelancerId/:freelanceragencyId',
    loadChildren: () => import('../pages/freelancer-agency/accept-invitation/accept-invitation.module').then(m => m.AcceptInvitationModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('../pages/stationary-pages/contact-us/contact-us.module').then(m => m.ContactUsModule),
  },
  {
    path: 'terms-of-use',
    loadChildren: () => import('../pages/stationary-pages/termsofuse/termsofuse.module').then(m => m.TermsofuseModule),
  },
  {
    path: 'faq',
    loadChildren: () => import('../pages/stationary-pages/faq/faq.module').then(m => m.FaqModule),
  },
  {
    path: 'services',
    loadChildren: () => import('../pages/stationary-pages/services/services.module').then(m => m.ServicesModule),
  },
  {
    path: 'blogs',
    loadChildren: () => import('../pages/stationary-pages/blogs/blogs.module').then(m => m.BlogsModule),
  },
  {
    path: 'blogs/:id',
    component: ViewBlogDetailsComponent,
    data: {
      title: 'view details | Blogs'
    }
  },
  {
    path: 'career-tips',
    loadChildren: () => import('../pages/career-tips/career-tips.module').then(m => m.CareerTipsModule),
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'why-remotelly',
    component: WhyRemotellyComponent
  },
  {
    path: 'freelancerprofile/:userId/:mobile',
    loadChildren: () => import('../pages/freelancer/yourprofile/yourprofile.module').then(m => m.YourprofileModule),
    //canActivate : [AuthGuard],
    //data: { roles: ["ROLE_FREELANCER"] }
  },

]