// @ts-nocheck
import { connect, ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject , Observable } from 'rxjs';
import { environment } from '../../environments/environment';

interface AuthToken {
    token: string;
}


export interface NamedRoom {
    id: string;
    name: string;
    maxParticipants?: number;
    participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable()
export class VideoChatService implements OnDestroy {
    $roomsUpdated: Observable<boolean>;
    codec: string = 'VP8';


    private roomBroadcast = new ReplaySubject<boolean>();

    constructor(private readonly http: HttpClient) {
        this.$roomsUpdated = this.roomBroadcast.asObservable();
    }

    ngOnDestroy(): void {
        if (this.roomBroadcast) {
            this.roomBroadcast.unsubscribe();
        }
    }

    transferAmoutTransfer(freelancer_id, employer_id, amount, job_id){
        let formData = {
            "currency": "USD",
            "amount": amount,
            "job_id": job_id,
            "employer_id":employer_id,
            "freelancer_id":freelancer_id,
            "customer_id":"cus_6lhhb4unj4gunfbvptts7yyxpe",
            "type":"id",
            "payment_type": "video_call"
        }
        return this.http.post<any>(environment.apiUrl+'employer/transferAmountToFreelancer', formData);
    }
    changeStatusAftercall(room_id){
        let formData = {
            "room_id": room_id
        }
        return this.http.post<any>(environment.apiUrl+'user/change_status_aftercall', formData);
    }
    getProjectDetails(formData){
        return this.http.post<any>(environment.apiUrl+'user/get-project-details', formData);
    }
    joinReminderCallEmployer(freelancer_id, employer_id, room_id){
        let formData = {
            "freelancer_id": freelancer_id,
            "employer_id": employer_id,
            "appointment_id": room_id
        }
        return this.http.post<any>(environment.apiUrl+'user/join_call-reminder-to-freelancer', formData);
    }
    joinReminderCallFreelancer(freelancer_id, employer_id, room_id){
        let formData = {
            "freelancer_id": freelancer_id,
            "employer_id": employer_id,
            "appointment_id": room_id
        }
        return this.http.post<any>(environment.apiUrl+'user/join_call-reminder-to-employer', formData);
    }

    private async getAuthToken(name, room_id) {
        const body = {'username': name, room: room_id};
        const auth =
            await this.http
                .post<any>(environment.apiUrl+'user/create-stream-token', body)
                .toPromise();
                //{'data' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTSzkzMTI1N2QxMGRmZTIxNjUxZGJjZTM2NWJiNTA3MDk3LTE2NTI1MjQwMjYiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJ0ZXN0IiwidmlkZW8iOnsicm9vbSI6InJvb21OYW1lIn19LCJpYXQiOjE2NTI1MjQwMjYsImV4cCI6MTY1MjUyNzYyNiwiaXNzIjoiU0s5MzEyNTdkMTBkZmUyMTY1MWRiY2UzNjViYjUwNzA5NyIsInN1YiI6IkFDMWJiMzk2YzQwYzAwNDhmZjRkZTYwY2Y2ZTY3MDMxMGMifQ.3BlCKke1RQjOvqjQROdFuAtwN4h0k20FLEudH2w7bxQ'}
        return auth.data;
    }

    getAllRooms() {
        return this.http
                   .get<Rooms>(environment.apiUrl+'video/rooms')
                   .toPromise();
    }

    async joinOrCreateRoom(name: string, room_id, tracks: LocalTrack[]) {
        console.log('browser-----',navigator.userAgent);
        this.getBrowserName();
        let room: Room = null; console.log('roome', name)
        try {
            const token = await this.getAuthToken(name, room_id);
            console.log('getAuthToken', token)
            if (token) {
                console.log('token-->', tracks);
                room =
                    await connect(
                        token, {
                            name,
                            tracks,
                            dominantSpeaker: true,
                            // preferredAudioCodecs: ['opus'],
                            // preferredVideoCodecs: ['VP8']
                        } as ConnectOptions);
            }
        } catch (error) {
            console.error(`Unable to connect to Room: ${error.message}`);
        } finally {
            console.log('room-->', room);
            if (room) {
                this.roomBroadcast.next(true);
            }
        }

        return room;
    }

    getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf('edge') > -1:
                this.codec = 'VP8';
                console.log('edge');
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                this.codec = 'VP8';
                console.log('opr');
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                this.codec = 'VP8';
                console.log('chrome');
                return 'chrome';
            // case agent.indexOf('trident') > -1:
            //     console.log('trident');
            //     return 'ie';
            case agent.indexOf('firefox') > -1:
                this.codec = 'VP8';
                console.log('firefox');
                return 'firefox';
            case agent.indexOf('safari') > -1:
                this.codec = 'H264';
                console.log('safari');
                return 'safari';
            default:
                console.log('other');
                return 'other';
        }
    }

    nudge() {
        this.roomBroadcast.next(true);
    }

    ratingCall(formData:object){
        return this.http.post<any>(environment.apiUrl +'user/submit_call_rating',formData);
    }

    getPaymentActions(paymentId) {
        return this.http.post(environment.apiUrl + 'employer/getPaymentActions', { paymentId: paymentId });
    }
}
