import { Routes } from '@angular/router';
import { AuthGuard } from '../helpers';
import { ProjectWorkHistoryComponent } from '../pages/freelancer/project-work-history/project-work-history.component';
import { WorkSubmissionsComponent } from '../pages/freelancer/work-submissions/work-submissions.component';
import { WorkdiaryComponent } from '../pages/freelancer/workdiary/workdiary.component';

export const FREELANCER_LAYOUT: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    loadChildren: () => import('../pages/auth/userporfile/userporfile.module').then(m => m.UserporfileModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../pages/freelancer/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'job-offer/:job_id/:employerId/:freelancerId',
    loadChildren: () => import('../pages/freelancer/offersandproposal/job-offer-details/job-offer-details.module').then(m => m.JobOfferDetailsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'view-offer/:jobId/:employerId/:freelancerId',
    loadChildren: () => import('../pages/freelancer/offersandproposal/view-offer/view-offer.module').then(m => m.ViewOfferModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'view-project/:jobId/:employerId/:freelancerId',
    loadChildren: () => import('../pages/freelancer/offersandproposal/view-project/view-project.module').then(m => m.ViewProjectModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'submit-proposal/:jobId/:employerId/:freelancerId',
    loadChildren: () => import('../pages/freelancer/offersandproposal/submit-proposal/submit-proposal.module').then(m => m.SubmitProposalModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'edit-proposal/:proposalId',
    loadChildren: () => import('../pages/freelancer/offersandproposal/edit-proposal/edit-proposal.module').then(m => m.EditProposalModule),
    canActivate: [AuthGuard],
    data: { role: ["ROLE_FREELANCER"] }
  },
  {
    path: 'change-request/:jobId/:employerId/:freelancerId',
    loadChildren: () => import('../pages/freelancer/offersandproposal/change-requests/change-requests.module').then(m => m.ChangeRequestsModule),
    canActivate: [AuthGuard],
    data: { role: ["ROLE_FREELANCER"] }
  },
  {
    path: 'user_profile',
    loadChildren: () => import('../pages/freelancer/userprofile/userprofile.module').then(m => m.UserprofileModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'user_profile/:preview',
    loadChildren: () => import('../pages/freelancer/userprofile/userprofile.module').then(m => m.UserprofileModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'user_profile/:preview/:userId',
    loadChildren: () => import('../pages/freelancer/userprofile/userprofile.module').then(m => m.UserprofileModule),
    // canActivate : [AuthGuard],
    // data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'profile/:profile_id',
    loadChildren: () => import('../pages/freelancer/userprofile/userprofile.module').then(m => m.UserprofileModule),
    // data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'profilesetting',
    loadChildren: () => import('../pages/freelancer/usersetting/usersetting.module').then(m => m.UsersettingModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'profilesetting/:route',
    loadChildren: () => import('../pages/freelancer/usersetting/usersetting.module').then(m => m.UsersettingModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'usercontact',
    loadChildren: () => import('../pages/freelancer/usercontact/usercontact.module').then(m => m.UsercontactModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'tax_information',
    loadChildren: () => import('../pages/freelancer/usertax/usertax.module').then(m => m.UsertaxModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'userpaid',
    loadChildren: () => import('../pages/freelancer/userpaid/userpaid.module').then(m => m.UserpaidModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'teams',
    loadChildren: () => import('../pages/freelancer/userteam/userteam.module').then(m => m.UserteamModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'freelancerjobapply',
    loadChildren: () => import('../pages/freelancer/jobsearch/freelancerjobapply/freelancerjobapply.module').then(m => m.FreelancerjobapplyModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'savedprojects',
    loadChildren: () => import('../pages/freelancer/jobsearch/savedjobs/savedjobs.module').then(m => m.SavedjobModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'savedjobs',
    loadChildren: () => import('../pages/freelancer/jobsearch/savedsecondaryjobs/savedsecondaryjobs.module').then(m => m.SavedsecondaryjobsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'active-contracts',
    loadChildren: () => import('../pages/freelancer/myjobs/active_contracts/active-contracts/active-contracts.module').then(m => m.ActivecontractsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'contracts',
    loadChildren: () => import('../pages/freelancer/myjobs/contracts/contracts/contracts.module').then(m => m.ContractsbModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'contract-details/:contract_id',
    loadChildren: () => import('../pages/freelancer/myjobs/contractdetails/milestone/milestone.module').then(m => m.MilestoneModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'viewjobdetails/:id',
    loadChildren: () => import('../pages/freelancer/jobsearch/viewjobdetails/viewjobdetails/viewjobdetails.module').then(m => m.ViewjobdetailsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'viewproposals',
    loadChildren: () => import('../pages/freelancer/proposal/view/viewproposals/viewproposals.module').then(m => m.ViewproposalsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'applyjob/:jobId',
    loadChildren: () => import('../pages/freelancer/applyjob/add/applyjob/applyjob.module').then(m => m.ApplyjobModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'appliedjobs',
    loadChildren: () => import('../pages/freelancer/applyjob/view/viewappliedjobs/viewappliedjobs.module').then(m => m.viewAppliedjobModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'editproposal/:jobId/:Id',
    loadChildren: () => import('../pages/freelancer/jobsearch/editproposal/editproposal/editproposal.module').then(m => m.EditproposalModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'connected_services',
    loadChildren: () => import('../pages/freelancer/userservice/userservice.module').then(m => m.UserserviceModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'security',
    loadChildren: () => import('../pages/freelancer/userpassword/userpassword.module').then(m => m.UserpasswordModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'identity',
    loadChildren: () => import('../pages/freelancer/useridentity/useridentity.module').then(m => m.UseridentityModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'notifications',
    loadChildren: () => import('../pages/freelancer/notification/notification.module').then(m => m.NotificationModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'otherdetails/:userId',
    loadChildren: () => import('../pages/freelancer/otherdetails/otherdetails.module').then(m => m.OtherdetailsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'otherdetails/:userId/:otherId',
    loadChildren: () => import('../pages/freelancer/otherdetails/otherdetails.module').then(m => m.OtherdetailsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'editportfolio/:userId/:otherId',
    loadChildren: () => import('../pages/freelancer/editportfolio/editportfolio.module').then(m => m.EditportfolioModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'edit-other-experience/:userId/:otherId',
    loadChildren: () => import('../pages/freelancer/editotherexperiences/editotherexperiences.module').then(m => m.EditotherexperiencesModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'edit-certification/:userId/:otherId',
    loadChildren: () => import('../pages/freelancer/editcertification/editcertification.module').then(m => m.EditcertificationModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'yourprofile/:userId',
    loadChildren: () => import('../pages/freelancer/yourprofile/yourprofile.module').then(m => m.YourprofileModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'yourprofile/:userId/:sectionId',
    loadChildren: () => import('../pages/freelancer/yourprofile/yourprofile.module').then(m => m.YourprofileModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'yourprofile/:userId/:sectionId/:idToFetch',
    loadChildren: () => import('../pages/freelancer/yourprofile/yourprofile.module').then(m => m.YourprofileModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'messages',
    loadChildren: () => import('../pages/auth/messaging/messaging.module').then(m => m.MessagingModule),
  },
  {
    path: 'messages/:id',
    loadChildren: () => import('../pages/auth/messaging/messaging.module').then(m => m.MessagingModule),
  },

  {
    path: 'work-diary',
    // loadChildren: () => import('../pages/freelancer/myjobs/work-diary/work-diary/work-diary.module').then(m => m.WorkDiaryModule),
    component: WorkdiaryComponent,
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'view-offer/:contractid',
    loadChildren: () => import('../pages/freelancer/myjobs/view-offer/view-offer.module').then(m => m.ViewOfferModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'previous-offer/:jobid',
    loadChildren: () => import('../pages/freelancer/myjobs/previous-offer/previous-offer.module').then(m => m.PreviousOfferModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'new-contract/:contract_id',
    loadChildren: () => import('../pages/freelancer/myjobs/contracts/new-contract/new-contract/new-contract.module').then(m => m.NewcontractsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'end-contract/:jobId/:employerId/:freelancerId',
    loadChildren: () => import('../pages/freelancer/myjobs/contracts/end-contract/end-contract/end-contract.module').then(m => m.EndcontractsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'closed-job/:contract_id',
    loadChildren: () => import('../pages/freelancer/myjobs/closed-job/closed-job.module').then(m => m.ClosedJobModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'originalproposal/:final_hire_id',
    loadChildren: () => import('../pages/freelancer/myjobs/originalproposal/originalproposal/originalproposal.module').then(m => m.OriginalproposalModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'reports',
    loadChildren: () => import('../pages/freelancer/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'transactions',
    loadChildren: () => import('../pages/freelancer/transactions/transactions.module').then(m => m.TransactionsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'job-preview/:job_id',
    loadChildren: () => import('../pages/freelancer/job-preview/job-preview.module').then(m => m.JobPreviewModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'view-all-projects',
    loadChildren: () => import('../pages/freelancer/myprojects/projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'view-all-proposals',
    loadChildren: () => import('../pages/freelancer/myprojects/proposals/proposals.module').then(m => m.ProposalsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'raise-a-dispute/:jobId/:employerId/:freelancerId',
    loadChildren: () => import('../pages/freelancer/disputes/raise-a-dispute/raise-a-dispute.module').then(m => m.RaiseADisputeModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'my-disputes',
    loadChildren: () => import('../pages/freelancer/disputes/my-disputes/my-disputes.module').then(m => m.MyDisputesModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'withdraw-edit/:proposalId',
    loadChildren: () => import('../pages/freelancer/myprojects/withdraw-edit-proposal/withdraw-edit-proposal.module').then(m => m.WithdrawEditProposalModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'video-call',
    loadChildren: () => import('../pages/freelancer/video-call/videocall/videocall.module').then(m => m.VideocallModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'video/:freelancerId/:employerId/:roomId/:jobId',
    loadChildren: () => import('../pages/video/video.module').then(m => m.VideoModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'payments',
    loadChildren: () => import('../pages/freelancer/viewPayments/viewPayments.module').then(m => m.ViewPaymentModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'freelancerContracts/:type/:contractId',
    loadChildren: () => import('../pages/employer/employerContracts/contracts.module').then(m => m.EmployerContractsModule),
    canActivate: [AuthGuard],
    data: { role: ["ROLE_FREELANCER"] }
  },
  {
    path: 'freelancerContracts/:type/:contractId/:tab',
    loadChildren: () => import('../pages/employer/employerContracts/contracts.module').then(m => m.EmployerContractsModule),
    canActivate: [AuthGuard],
    data: { role: ["ROLE_FREELANCER"] }
  },
  {
    path: 'work-submissions/:contractId/:jobId/:employerId/:freelancerId',
    component: WorkSubmissionsComponent,
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER"] }
  },
  {
    path: 'work-history',
    component: ProjectWorkHistoryComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_FREELANCER'] }
  },
  {
    path: 'work-history/:jobId',
    component: ProjectWorkHistoryComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_FREELANCER'] }
  }

]