import { Router } from '@angular/router';
import { Component, Inject, OnDestroy, OnInit, Renderer2, HostListener, ElementRef } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { AuthenticationService, UserService } from '../../services';
import { environment } from "../../../environments/environment";
import { Console } from 'console';
// import { CardDetailsService } from 'src/app/services/card-details.service';
//import { getMessaging, getToken, onMessage } from "firebase/messaging";
import $ from 'jquery';
import { UserDataService } from '../../services/user-data.service';
import { DOCUMENT } from '@angular/common';
import { io, Socket } from "socket.io-client";
import { EmployerserviceService } from '../../services/employer-service/employerservice.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  socket: Socket;
  private url = environment.socket_url;

  s3_url = environment.s3_url;
  public Isloggedin = true;
  currnetUserRole = '';
  employerType: any;
  postJobRadio: boolean;
  id: any;
  userData: any;
  employer_Data: any;
  previewProfileImg: any;
  cardData: any;

  postUrl: any;
  countryListData: any;
  industryListData: any
  show: boolean = false;

  title = 'af-notification';
  message: any = null;
  user_id: string = '';
  dataRefresher: any;
  notificationCount: number;
  messagesCount: number;
  notificationData: any = [];

  freelancerProfileData: any;
  employerBalance: any;
  // jobseekerProfileData: any;

  freelancerUserData: any;
  employerUserData: any;
  // jobseekerUserData:any;
  currentUser: any;
  totalUnreadMessagesCount: number = 0;


  /* @HostListener('document:click', ['$event'])
    onClick(event) {
      debugger;
      if(this.eRef.nativeElement.contains(event.target)) {
        alert('hi')
        $('ul.dropdown-menu').dropdown("toggle");
      } else {
        alert('hello')
        $('ul.dropdown-menu').dropdown("toggle");
      }
  } */
  constructor(private authenticationService: AuthenticationService,
    private router: Router,
    private employeerService:EmployerserviceService,
    private userService: UserService,
    private userProfileDataService: UserDataService,
    // private card: CardDetailsService
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private eRef: ElementRef
  ) {
    // this.socket = io(this.url, {
    //   secure: true,
    //   rejectUnauthorized: false,
    //   withCredentials: false,
    //   forceNew: true,
    //   transports: ["polling"],
    // });

    /* this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    }; */
    let userCurrent = JSON.parse(localStorage.getItem('currentUser')); //this.authenticationService.currentUserValue;
    console.log(userCurrent)
    //authenticationService.getLoggedInName.subscribe(userCurrent => {
    //console.log(userCurrent)
    if (userCurrent == null) {
      this.currnetUserRole = '';
    }
    let userData = userCurrent //JSON.parse(userCurrent);

    if (userData && userData['data']) {
      //console.log('inside');
      this.user_id = userData.id
      // this.requestPermission();
      // this.listen();
      this.refreshData();

      this.currnetUserRole = userData['data']['roles'][0];
      //console.log(this.currnetUserRole);
      this.Isloggedin = true;
      this.userData = userData;
      if (userData != null && this.currnetUserRole == 'ROLE_EMPLOYER') {
        this.id = userData.id;

        // this.http.get(environment.apiUrl + 'employer/get-employertype/' + this.id)
        this.employeerService.getEmployertype(this.id).subscribe(data => {
          if (data['status'] == true) {
            let result = data['data']
            this.employerType = result
            if (this.employerType.employer_type == 1) {
              this.postJobRadio = true
            }
            else {
              this.postJobRadio = false
            }
          }
        });

        this.userProfileDataService.employerProfileData(this.id);

      }
      if (userData != null && this.currnetUserRole == 'ROLE_JOBSEEKER') {
        this.userService.getTopSearchJob(this.id).subscribe(data => {
          if (data['status']) {
            //console.log('card data', data['data'].country_list);
            this.countryListData = data['data'].country_list;
            this.industryListData = data['data'].industry_list;
          }
        });
        console.log('user id', this.user_id)
        this.userProfileDataService.userProfile(this.user_id);
        // this.userProfileDataService.jobseekerProfile(this.id); 
        console.log('profile image', this.freelancerProfileData)
      }
      if (userData != null && this.currnetUserRole == 'ROLE_FREELANCER') {
        this.id = userData.id;

        this.userProfileDataService.userProfile(this.id);

      }
      if (userData != null && this.currnetUserRole == 'ROLE_FREELANCER AGENCY') {
        this.userProfileDataService.userProfile(this.user_id);
      }
    }

    //});
    // this.currentUser=this.authenticationService.currentUserValue;
    //console.log(this.currentUser)
    this.freelancerUserData = this.userProfileDataService.detectChangeInUserData.subscribe(data => {
      this.freelancerProfileData = data;
    })
    this.employerUserData = this.userProfileDataService.detectChangeInEmployerBalance.subscribe(data => {
      this.employerBalance = data;
    })
    // this.jobseekerUserData = this.userProfileDataService.detectChangeInJobseekerData.subscribe(data=>{
    //   this.jobseekerProfileData=data;
    // })

  }

  ngOnInit() {
    if (document.location.href.indexOf('/authentication/') > -1 || document.location.href.indexOf('/login') > -1) {
      this.Isloggedin = false;
    }

    //  this.socket.on("conversation private post", function (data) {
    //    console.log('socket on ', data.totalUnreadMessagesCount)
    //    if (data.message) {
    //      this.totalUnreadMessagesCount = data.totalUnreadMessagesCount;
    //      console.log('unread counbt', this.totalUnreadMessagesCount);
    //    }
    //  });
    let userData = JSON.parse(localStorage.getItem('currentUser'));
    if (userData && userData['data']) {
      this.user_id = userData.id
      // this.requestPermission();
      // this.listen();
      this.userNotification();
      this.refreshData();
    }
    console.log('euryuewyruiweyru');

    if (userData && userData['data'])
      this.currnetUserRole = userData['data']['roles'][0];
    //console.log(this.currnetUserRole);
    this.Isloggedin = true;
    this.userData = userData;
    if (userData != null && this.currnetUserRole == 'ROLE_EMPLOYER') {
      this.id = userData.id;

      // this.http.get(environment.apiUrl + 'employer/get-employertype/' + this.id)
      this.employeerService.getEmployertype(this.id).subscribe(data => {
        if (data['status'] == true) {
          let result = data['data']
          this.employerType = result
          if (this.employerType.employer_type == 1) {
            this.postJobRadio = true
          }
          else {
            this.postJobRadio = false
          }
        }
      });

      this.userProfileDataService.employerProfileData(this.id);

    }
    if (userData != null && this.currnetUserRole == 'ROLE_JOBSEEKER') {
      this.userService.getTopSearchJob(this.id).subscribe(data => {
        if (data['status']) {
          //console.log('card data', data['data'].country_list);
          this.countryListData = data['data'].country_list;
          this.industryListData = data['data'].industry_list;
        }
      });
      console.log('user id', this.user_id)
      this.userProfileDataService.userProfile(this.user_id);
      // this.userProfileDataService.jobseekerProfile(this.id); 
      console.log('profile image', this.freelancerProfileData)
    }
    if (userData != null && this.currnetUserRole == 'ROLE_FREELANCER') {
      this.id = userData.id;

      this.userProfileDataService.userProfile(this.id);

    }
    if (userData != null && this.currnetUserRole == 'ROLE_FREELANCER AGENCY') {
      this.userProfileDataService.userProfile(this.user_id);
    }



  }

  toggleMenu() {
    //console.log(event)
    //$('.dropdown-toggle').dropdown('toggle');
    $('ul.multi-dropdown-custom').removeClass('show');
  }
  refreshData() {
    this.dataRefresher = setInterval(() => {
      this.userNotification();
      this.messagesCountNotification();
      //Passing the false flag would prevent page reset to 1 and hinder user interaction
    }, 5000);
  }
  userNotification() {
    this.userService.getUserNotificationCount(this.user_id).subscribe(data => {
      if (data['status']) {
        this.notificationCount = data['data']
        // console.log(data['data'].length)

      }
    });
  }

  messagesCountNotification() {
    this.userService.getCountUnreadByUser(this.user_id).subscribe(data => {
      if (data['status']) {
        this.messagesCount = data['data'].count;
        //console.log('messages count',this.messagesCount)
      }
    })
  }

  cancelPageRefresh() {
    if (this.dataRefresher) {
      clearInterval(this.dataRefresher);
    }
  }

  showAllNotification() {
    this.userService.getUserNotification(this.user_id).subscribe(data => {
      if (data['status']) {
        this.notificationData = data['data'];
         console.log("------------",this.notificationData);
        this.userService.markAllNotification(this.user_id).subscribe(data => {

        });
      }
    });

  }

  logout() {
    this.userService.statusChange(this.user_id, { "is_user_online": false })
    .subscribe(data => {
      if(data['status']){
        console.log("Logged out succesfully", data['message']);
        this.authenticationService.logout();
      }else{
        console.log(data['message']);
      }
    });
  }

  goToUrl(url) {
    this.router.navigate(['freelancer/' + url]);
  }
  goToJobseekerUrl(url) {
    this.router.navigate(['jobseeker/' + url]);
  }

  goToEmployerUrl(url) {
    this.router.navigate(['employer/' + url]);
  }
  goToAgencyUrl(url) {
    this.router.navigate(['freelancer-agency/' + url]);
  }
  /*   goToSearch(type, searchId){
      console.log(type, searchId)
      var object = {"type" : type, "search": searchId }
      this.router.navigateByUrl('jobseeker/search', {
        state: {object}
    }); 
    }*/

  goToSearch(type, searchId) {
    console.log(type, searchId)
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    //var object = {"type" : type, "search": searchId }
    this.router.navigate(['jobseeker/search/' + type + '/' + searchId]);
  }

  // employer verify card before post a job

  postAJobProject(url) {
    this.userService.getUserCardDetails(this.id).subscribe(data => {
      if (data['status']) {
        console.log('card data', data['data']);
        this.cardData = data['data'];
        this.postUrl = url;
        if (this.cardData.length > 0) {
          this.router.navigate(['employer/' + url]);
        } else {
          document.getElementById('verifypayment').click()
        }
      }
    });

  }

  addCardVerify() {
    this.router.navigate(['employer/setting'], { queryParams: { post: this.postUrl } });
    document.getElementById('closeverify').click();
  }

  // employer verify card before post a job end

  ngAfterViewInit() {
    console.log("---Inside ngAfterViewInit---");
    $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
      if (!$(this).next().hasClass('show')) {
        $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
      }
      var $subMenu = $(this).next('.dropdown-menu');
      $subMenu.toggleClass('show');


      $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
        $('.dropdown-submenu .show').removeClass('show');
      });


      return false;
    });
  }

  services() {
    this.router.navigate(['/services']);
  }
  blogs() {
    this.router.navigate(['/blogs']);
  }
  careertips() {
    this.router.navigate(['/career-tips']);
  }

  clearAllNotifications() {
    // this.http.get(environment.apiUrl + 'notification/delete-all/' + this.user_id)
    this.userService.notificationdeleteall(this.user_id).subscribe(data => {
      if (data['status']) {
        console.log(data['message']);
        this.showAllNotification();
      }
    })
  }
  addBodyClass() {
    this.renderer.addClass(this.document.body, 'navbar-overlay');
  }
  removeBodyClass() {
    this.renderer.removeClass(this.document.body, 'navbar-overlay');
  }
  removeShowClass() {
    this.renderer.removeClass('#navbarSupportedContent', 'show');
  }

  notificationLink(notificationUrl,data) {
    console.log("click",notificationUrl,data);
    //this.router.navigateByUrl(notificationUrl);
    if (notificationUrl != null) {
      var newWindow = window.open(location.origin + notificationUrl, '_blank');
      newWindow["myVar"] = data;
    }
  }
  ngOnDestroy() {
    this.employerUserData.unsubscribe();
    this.freelancerUserData.unsubscribe();
    // this.jobseekerUserData.unsubscribe();
    this.cancelPageRefresh();
    this.renderer.removeClass(this.document.body, 'navbar-overlay');
  }

}
