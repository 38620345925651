import { Routes } from '@angular/router';
import { AuthGuard } from '../helpers';
import { ProjectWorkHistoryComponent } from '../pages/freelancer/project-work-history/project-work-history.component';
import { WorkSubmissionsComponent } from '../pages/freelancer/work-submissions/work-submissions.component';
import { WorkdiaryComponent } from '../pages/freelancer/workdiary/workdiary.component';

export const FREELANCER_AGENCY_LAYOUT: Routes = [
    {
        path:'dashboard',
        loadChildren:()=> import('../pages/freelancer/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path: 'view-all-projects',
      loadChildren: () => import('../pages/freelancer/myprojects/projects/projects.module').then(m=> m.ProjectsModule),
      canActivate : [AuthGuard],
      data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path: 'view-all-proposals',
      loadChildren: () => import('../pages/freelancer/myprojects/proposals/proposals.module').then(m=> m.ProposalsModule),
      canActivate : [AuthGuard],
      data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path:'payments',
      loadChildren:()=> import('../pages/freelancer/viewPayments/viewPayments.module').then(m => m.ViewPaymentModule),
      canActivate : [AuthGuard],
      data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path: 'messages',
      loadChildren: () => import('../pages/auth/messaging/messaging.module').then(m => m.MessagingModule),
    },
  {
    path: 'work-diary',
    // loadChildren: () => import('../pages/freelancer/myjobs/work-diary/work-diary/work-diary.module').then(m => m.WorkDiaryModule),
    component: WorkdiaryComponent,
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER AGENCY"] }
  },
  {
    path: 'reports',
    loadChildren: () => import('../pages/freelancer-agency/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard],
    data: { roles: ["ROLE_FREELANCER AGENCY"] }
  },
    {
      path: 'my-disputes',
      loadChildren: () => import('../pages/freelancer/disputes/my-disputes/my-disputes.module').then(m => m.MyDisputesModule),
      canActivate : [AuthGuard],
      data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path: 'video-call',
      loadChildren: () => import('../pages/freelancer/video-call/videocall/videocall.module').then(m => m.VideocallModule),
      canActivate : [AuthGuard],
      data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path: 'profilesetting',
      loadChildren: () => import('../pages/freelancer/usersetting/usersetting.module').then(m => m.UsersettingModule),
      canActivate : [AuthGuard],
      data: { roles: ["ROLE_FREELANCER AGENCY"] }

    },
    {
      path: 'profilesetting/:route',
      loadChildren: () => import('../pages/freelancer/usersetting/usersetting.module').then(m => m.UsersettingModule),
      canActivate : [AuthGuard],
      data: { roles: ["ROLE_FREELANCER AGENCY"] }

    },
// test
{
  path:'job-offer/:job_id/:employerId/:freelancerId',
  loadChildren:()=> import('../pages/freelancer/offersandproposal/job-offer-details/job-offer-details.module').then(m => m.JobOfferDetailsModule),
  canActivate: [AuthGuard],
  data: { roles: ["ROLE_FREELANCER AGENCY"]}
},
{
  path:'view-offer/:jobId/:employerId/:freelancerId',
  loadChildren:()=> import('../pages/freelancer/offersandproposal/view-offer/view-offer.module').then(m=> m.ViewOfferModule),
  canActivate: [AuthGuard],
  data: { roles: ["ROLE_FREELANCER AGENCY"]}
},
{
  path:'view-project/:jobId/:employerId/:freelancerId',
  loadChildren:()=> import('../pages/freelancer/offersandproposal/view-project/view-project.module').then(m=> m.ViewProjectModule),
  canActivate: [AuthGuard],
  data: { roles: ["ROLE_FREELANCER AGENCY"]}
},
{
  path: 'raise-a-dispute/:jobId/:employerId/:freelancerId',
  loadChildren: () => import('../pages/freelancer/disputes/raise-a-dispute/raise-a-dispute.module').then(m => m.RaiseADisputeModule),
  canActivate : [AuthGuard],
  data: { roles: ["ROLE_FREELANCER AGENCY"] }
},
{
  path: 'end-contract/:jobId/:employerId/:freelancerId',
  loadChildren: () => import('../pages/freelancer/myjobs/contracts/end-contract/end-contract/end-contract.module').then(m => m.EndcontractsModule),
  canActivate : [AuthGuard],
  data: { roles: ["ROLE_FREELANCER AGENCY"] }
},
{
  path:'submit-proposal/:jobId/:employerId/:freelancerId',
  loadChildren:()=> import('../pages/freelancer/offersandproposal/submit-proposal/submit-proposal.module').then(m=> m.SubmitProposalModule),
  canActivate: [AuthGuard],
  data: {roles: ["ROLE_FREELANCER AGENCY"]}
},
{
  path:'edit-proposal/:proposalId',
  loadChildren: ()=> import('../pages/freelancer/offersandproposal/edit-proposal/edit-proposal.module').then(m => m.EditProposalModule),
  canActivate: [AuthGuard],
  data: {role: ["ROLE_FREELANCER AGENCY"]}
},
{
  path:'change-request/:jobId/:employerId/:freelancerId',
  loadChildren: ()=> import('../pages/freelancer/offersandproposal/change-requests/change-requests.module').then(m=> m.ChangeRequestsModule),
  canActivate: [AuthGuard],
  data: {role: ["ROLE_FREELANCER AGENCY"]}
},
{
  path: 'withdraw-edit/:proposalId',
  loadChildren: () => import('../pages/freelancer/myprojects/withdraw-edit-proposal/withdraw-edit-proposal.module').then(m=> m.WithdrawEditProposalModule),
  canActivate : [AuthGuard],
  data: { roles: ["ROLE_FREELANCER AGENCY"] }
},
{
  path: 'freelancerContracts/:type/:contractId',
  loadChildren: () => import('../pages/employer/employerContracts/contracts.module').then(m => m.EmployerContractsModule),
  canActivate: [AuthGuard],
  data: {role: ["ROLE_FREELANCER AGENCY"]} 
},
{
  path: 'video/:freelancerId/:employerId/:roomId/:jobId',
  loadChildren: () => import('../pages/video/video.module').then(m => m.VideoModule),
  canActivate : [AuthGuard],
  data: { roles: ["ROLE_FREELANCER AGENCY"] }
},
// testend


    {
        path: 'user_profile',
        loadChildren: () => import('../pages/freelancer-agency/userprofile/userprofile.module').then(m => m.UserprofileModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
        path: 'user_profile/:preview',
        loadChildren: () => import('../pages/freelancer-agency/userprofile/userprofile.module').then(m => m.UserprofileModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
        path: 'user_profile/:preview/:userId',
        loadChildren: () => import('../pages/freelancer-agency/userprofile/userprofile.module').then(m => m.UserprofileModule),
        // canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path: 'profile/:profile_id',
      loadChildren: () => import('../pages/freelancer-agency/userprofile/userprofile.module').then(m => m.UserprofileModule),

    },
    {
        path: 'yourprofile/:userId/:sectionId',
        loadChildren: () => import('../pages/auth/agencyprofile/agencyprofile.module').then(m => m.AgencyprofileModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
      },
      {
        path: 'yourprofile/:userId/:sectionId/:idToFetch',
        loadChildren: () => import('../pages/auth/agencyprofile/agencyprofile.module').then(m => m.AgencyprofileModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
      },
      {
        path: 'otherdetails/:userId',
        loadChildren: () => import('../pages/freelancer/otherdetails/otherdetails.module').then(m => m.OtherdetailsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
      },
      {
        path: 'otherdetails/:userId/:otherId',
        loadChildren: () => import('../pages/freelancer/otherdetails/otherdetails.module').then(m => m.OtherdetailsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
      },
      {
        path: 'editportfolio/:userId/:otherId',
        loadChildren: () => import('../pages/freelancer/editportfolio/editportfolio.module').then(m => m.EditportfolioModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
      },
      {
        path: 'edit-other-experience/:userId/:otherId',
        loadChildren: () => import('../pages/freelancer/editotherexperiences/editotherexperiences.module').then(m => m.EditotherexperiencesModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
      },
      {
        path: 'edit-certification/:userId/:otherId',
        loadChildren: () => import('../pages/freelancer/editcertification/editcertification.module').then(m => m.EditcertificationModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
      },
    {
        path: 'profilesetting',
        loadChildren: () => import('../pages/freelancer-agency/user-settings/user-settings.module').then(m => m.UserSettingsModule),
        canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path: 'profilesetting/:route',
      loadChildren: () => import('../pages/freelancer-agency/user-settings/user-settings.module').then(m => m.UserSettingsModule),
      canActivate : [AuthGuard],
      data: { roles: ["ROLE_FREELANCER AGENCY"] }
  },
    {
      path:'add-member/:freelancer_id',
      loadChildren: () => import('../pages/freelancer-agency/add-member/add-member.module').then(m=> m.AddMemberModule),
      canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path:'invite-member',
      loadChildren: () => import('../pages/freelancer-agency/invite-member/invite-member.module').then(m => m.InviteMemberModule),
      canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path:'view-offer/:jobId/:employerId/:freelancerId',
      loadChildren: () => import('../pages/freelancer-agency/offersprojectscontracts/view-offer/view-offer.module').then(m=> m.ViewOfferModule),
      canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path:'view-project/:jobId/:employerId/:freelancerId',
      loadChildren: () => import('../pages/freelancer-agency/offersprojectscontracts/view-project/view-project.module').then(m => m.ViewProjectModule),
      canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path:'change-request/:jobId/:employerId/:freelancerId',
      loadChildren: () => import('../pages/freelancer-agency/offersprojectscontracts/change-request/change-request.module').then(m => m.ChangeRequestModule),
      canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path:'view-freelancer-profile/:freelancerId',
      loadChildren: () => import('../pages/freelancer-agency/offersprojectscontracts/view-freelancer-profile/view-freelancer-profile.module').then(m=> m.ViewFreelancerProfileModule),
      canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path:'view-all-projects',
      loadChildren: () => import('../pages/freelancer-agency/my-projects/projects/projects.module').then(m => m.ProjectsModule),
      canActivate : [AuthGuard],
        data: { roles: ["ROLE_FREELANCER AGENCY"] }
    },
    {
      path: 'work-submissions/:contractId/:jobId/:employerId/:freelancerId',
      component: WorkSubmissionsComponent,
      canActivate: [AuthGuard],
      data: {roles: ["ROLE_FREELANCER AGENCY"]}
    },
    // {
    //   path: 'contact-us',
    //   loadChildren: () => import('../pages/contact-us/contact-us.module').then(m => m.ContactUsModule),
    //   canActivate : [AuthGuard],
    //     data: { roles: ["ROLE_FREELANCER AGENCY"] }
    // }

  {
    path: 'work-history',
    component: ProjectWorkHistoryComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_FREELANCER AGENCY'] }
  },
  {
    path: 'work-history/:jobId',
    component: ProjectWorkHistoryComponent,
    canActivate: [AuthGuard],
    data: { roles: ['ROLE_FREELANCER AGENCY'] }
  }

]