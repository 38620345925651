import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { ToasterService } from "angular2-toaster";
import { UserService } from "../../../services";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
import { io, Socket } from "socket.io-client";

@Component({
  selector: "app-messages",
  templateUrl: "./messaging.component.html",
  styleUrls: ["./messaging.component.scss"],
})
export class MessagesComponent implements OnInit {
  @Input() childMessage: string;
  imgPreview:any;
  fileuploadflag:boolean=false;
  private url = environment.socket_url;
  socket: Socket;
  s3_url = environment.s3_url;
  user_id;
  conversations = [];
  messages: any = [ ]; 
  selectedConversation = "";
  selectConverstaionDetails = [];
  conversationHeading = "";
  conversationSubHeading = "";
  message = "";
  userData: any;
  uploadedFile = null;
  firstLoad = true;
  room: any;
  emitData: any;
  declined:boolean;
  pending:boolean;
  employerId:any;
  freelancerId:any;
  jobId: any;
  currentUserRole='';

  personalNotesToggle:boolean = false;

  notes:any;
  getNotes =[];

  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set = 'twitter';

  constructor(
    private activeRouter: ActivatedRoute,
    private userService: UserService,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder
  ) {
    this.socket = io(this.url, {
      secure: true,
      rejectUnauthorized: false,
      withCredentials: false,
      forceNew: true,
      transports: ["polling"],
    });
   
  }

  ngOnInit() {
    if (this.activeRouter.snapshot.params["userId"]) {
      this.user_id = this.activeRouter.snapshot.params["userId"];
    } else {
      this.userData = JSON.parse(localStorage.getItem("currentUser"));
      // console.log(this.userData.data);
      this.user_id = this.userData.id;
      this.currentUserRole=this.userData['data']['roles'][0];
      console.log('currrent message user',this.currentUserRole);
    }
    this.freelancerId = this.activeRouter.snapshot.params['freelancerId']
    this.employerId = this.activeRouter.snapshot.params['employerId']
    this.jobId = this.activeRouter.snapshot.params['jobId']

    this.getConversations();
    let that = this;
    console.log('tesdt-----------', this.socket.on)
    this.socket.on("conversation private post", function (data) {
      //display data.message
      console.log('test socket:-----')
      if(data.message){
        let lastMessage = data.message;
        that.messages.push(lastMessage);
        that.scrollToBottom();
      }
    });
  }

  toggleEmojiPicker() {
    console.log(this.showEmojiPicker);
        this.showEmojiPicker = !this.showEmojiPicker;
    
  }

  addEmoji(event) {
    console.log(this.message)
    const { message } = this;
    console.log(message);
    console.log(`${event.emoji.native}`)
    const text = `${message}${event.emoji.native}`;

    this.message = text;
    // this.showEmojiPicker = false;
  }

  onFocus() {
    console.log('focus');
    this.showEmojiPicker = false;
  }

  getConversations() {
    this.userService
      .getOneConversations({
        "employer_id":this.employerId,
        "freelancer_id": this.freelancerId,
        "job_id": this.jobId
    })
      .subscribe((data) => {
        if (data["status"]) {
        console.log('message:----------------',this.childMessage);

          console.log(data["data"]);
          this.conversations = data["data"];
          console.log("conversations", this.conversations);
          this.selectedConversation = this.conversations[0].id;
          if (this.selectedConversation != "") {
            this.viewConversations(this.selectedConversation);
          }
          if (this.activeRouter.snapshot.params["id"] && this.firstLoad) {
            let conversation_id = this.activeRouter.snapshot.params["id"];
            this.viewConversations(conversation_id);
            this.firstLoad = false;
          }
        } else this.toasterService.pop("error", "No conversations found!");
      });
  }
  viewConversations(id) {
    console.log('sss', id)
    this.selectedConversation = id;
    this.socket.emit("subscribe", this.selectedConversation);
    let index = this.conversations.findIndex((x) => x.id == id);
    if (index != -1) {
      if (this.user_id == this.conversations[index]["freelancer_id"]) {
        this.conversationHeading =
          this.conversations[index]["employer_user"]["firstname"] +
          " " +
          this.conversations[index]["employer_user"]["lastname"];
        this.conversationSubHeading =
          this.conversations[index]["employer_user"]["username"];
      } else {
        this.conversationHeading =
          this.conversations[index]["freelancer_user"]["firstname"] +
          " " +
          this.conversations[index]["freelancer_user"]["lastname"];
        this.conversationSubHeading =
          this.conversations[index]["freelancer_user"]["username"];
      }
      this.selectConverstaionDetails = this.conversations[index];
      this.messages = this.conversations[index]["user_messages"];
      this.employerId = this.conversations[index].employer_id;
      this.freelancerId = this.conversations[index].freelancer_id;
      console.log('emp id',this.employerId);
      console.log('free id',this.freelancerId);

      if(this.conversations[index].status == 'Declined'){
        this.declined =true;
      } else {
        this.declined = false
      }
      if(this.conversations[index].status ==  'Pending'){
        this.pending = true;
      }else{
        this.pending =false;
      }
      // console.log(this.messages)
      // var objDiv = document.getElementById("chatMessages");
      // objDiv.scrollTop = objDiv.scrollHeight;
      this.scrollToBottom();
      this.message = "";
      //this.getPersonalNotes();
    }
  }

  sendMessage() {
    let id = this.selectedConversation;
    let index = this.conversations.findIndex((x) => x.id == id);
    if (index != -1) {
      let receiverId;
      if (this.user_id == this.conversations[index]["freelancer_id"])
        receiverId = this.conversations[index]["employer_id"];
      else receiverId = this.conversations[index]["freelancer_id"];
      let data = {
        conversation_id: this.selectedConversation,
        sender_id: this.user_id,
        receiver_id: receiverId,
        message: this.message,
      };
      this.userService.sendMessage(data).subscribe((data) => {
        if (data["status"]) {
          // this.getConversationMessages(this.selectedConversation);
          this.getConversations();
          this.markAllAsRead(this.selectedConversation);
        } else
          this.toasterService.pop(
            "error",
            "Something went wrong! Try again later."
          );
      });
    }
  }

  sendMessageWithAttachment() {
    let id = this.selectedConversation;
    let index = this.conversations.findIndex((x) => x.id == id);
    if (index != -1) {
      let receiverId;
      if (this.user_id == this.conversations[index]["freelancer_id"])
        receiverId = this.conversations[index]["employer_id"];
      else receiverId = this.conversations[index]["freelancer_id"];
      let data = {
        conversation_id: this.selectedConversation,
        sender_id: this.user_id,
        receiver_id: receiverId,
        message: this.message,
      };
      let formData = new FormData();
      formData.append("conversation_id", this.selectedConversation);
      formData.append("sender_id", this.user_id);
      formData.append("receiver_id", receiverId);
      formData.append("message", this.message);
      formData.append("message_attachment", this.uploadedFile);
      this.userService.sendMessageWithAttachment(formData).subscribe((data) => {
        if (data["status"]) {
          // this.getConversationMessages(this.selectedConversation);
          this.uploadedFile = null;
          this.fileuploadflag=false;
          this.getConversations();
          this.markAllAsRead(this.selectedConversation);
        } else
          this.toasterService.pop(
            "error",
            "Something went wrong! Try again later."
          );
      });
    }
  }
  removefileupload(){
    this.fileuploadflag=false;
     this.uploadedFile = null;
     this.message='';
     this.getConversations();
  }
  checkAttachmentAndSendMessage() {
    if ( this.uploadedFile != null) {
      this.sendMessageWithAttachment()
    }
    if(this.message == "" && this.uploadedFile == null){
      this.toasterService.pop("error", "Enter message before sending!");
      return;
    }
    let id = this.selectedConversation;
    let index = this.conversations.findIndex((x) => x.id == id);
    if (index != -1) {
      let receiverId;
      if (this.user_id == this.conversations[index]["freelancer_id"])
        receiverId = this.conversations[index]["employer_id"];
      else receiverId = this.conversations[index]["freelancer_id"];
      let data = {
        conversation_id: this.selectedConversation,
        sender_id: this.user_id,
        receiver_id: receiverId,
        message: this.message,
        createdAt:new Date()
      };
      this.socket.emit("send message", {
        room: this.selectedConversation,
        message: data,
      });
    }
    if (this.uploadedFile == null) this.sendMessage();
    // else this.sendMessageWithAttachment();
  }

  markAllAsRead(id){
    let formData ={
      conversation_id: id,
      receiver_id: this.user_id
    }
    this.userService.markAllAsRead(formData).subscribe(data => {
      if(data['status']){
        console.log('data unread', data['data']);
        // this.getConversations()
      }
    })
  }
  getConversationMessages(id) {
    this.userService
      .getConversationMessages({ conversation_id: id })
      .subscribe((data) => {
        if (data["status"]) {
          this.messages = data["data"];
          this.message = "";
          // var objDiv = document.getElementById("chatMessages");
          // objDiv.scrollTop = objDiv.scrollHeight;
          this.scrollToBottom();
        } else
          this.toasterService.pop(
            "error",
            "Something went wrong! Try again later."
          );
      });
  }

  updateUrl(val) {
    let img = <HTMLImageElement>document.getElementById(val.srcElement.id);
    img.src = "assets/images/no-preview.png";
  }


  uploadFile(event) {
    console.log("&&&&",event)
      if (event.target.files && event.target.files[0]) {
        const file = (event.target as HTMLInputElement).files[0];
        this.uploadedFile = file;
        this.message=this.uploadedFile.name;
       
        console.log(this.uploadedFile,this.isFileImage(file));
        if(this.isFileImage(file)){
          var reader = new FileReader();
  
          reader.readAsDataURL(event.target.files[0]); // read file as data url
    
          reader.onload = (event:any) => { // called once readAsDataURL is completed
            this.imgPreview = event.target.result;
          }
          // this.imgPreview='/assets/images/pdfimg.png';
          this.fileuploadflag=true;
        }else{
          this.imgPreview='/assets/images/imgpreview.jpg';
          this.fileuploadflag=true;
        }
     
        event.target.value = '';
        // var reader = new FileReader();
  
        // reader.readAsDataURL(event.target.files[0]); 
  
        // reader.onload = (event:any) => { 
        //   this.fileurl = event.target.result;
        //   console.log(this.fileurl);
        // }
      }
      
    }
    isFileImage(file) {
      return file && file['type'].split('/')[0] === 'image';
  }

  scrollToBottom() {
    setTimeout(function () {
      var objDiv = document.getElementById("chatMessages");
      objDiv.scrollTop = objDiv.scrollHeight;
    }, 500);
  }

acceptInvite(){
  let formData = {
    conversation_id: this.selectedConversation
  }
  console.log('accept',formData);
  this.userService.acceptMessage(formData).subscribe(data => {
    if(data['status']){
      this.toasterService.pop('success', data['message']);
      this.conversations=[];
      this.getConversations();
    }
    else
    this.toasterService.pop('success', data['message']);
  })
}

declineInvite(){
  let formData = {
    conversation_id: this.selectedConversation
  }
  console.log('refuse',formData);
  this.userService.refuseMessage(formData).subscribe(data => {
    if(data['status']){
      this.toasterService.pop('success', data['message']);
      this.conversations=[];
      this.getConversations();
    }
    else
    this.toasterService.pop('success', data['message']);
  })
}



}
