import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FreelancerProjectsReports'
})
export class FreelancerProjectsReportsPipe implements PipeTransform {

  transform(items: any[], searchText: string): any {
    // console.log('items array',items);
    // console.log('search txt',searchText);
    if (!items) return [];
    if (!searchText) return items;
   
    return items.filter((item) => {
      // if(!item.employer_job.name){
      //   console.log(item.employer_job.name,searchText);
      // }
     
      return items.some(y => {
        return String(item.id).includes(searchText) || String(item.createdAt).toLowerCase().includes(searchText.toLowerCase())  || String(item.employer_job.name).toLowerCase().includes(searchText.toLowerCase()) || String(item.employer.firstname).toLowerCase().includes(searchText.toLowerCase()) || String(item.employer.lastname).toLowerCase().includes(searchText.toLowerCase());
      })
    })
  }
}
