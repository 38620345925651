import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel, IMyDefaultMonth, IMyRangeDateSelection } from 'angular-mydatepicker';
import { EmployerserviceService } from '../../services/employer-service/employerservice.service';
@Component({
  selector: 'app-call-edit',
  templateUrl: './call-edit.component.html',
  styleUrls: ['./call-edit.component.scss']
})
export class CallEditComponent implements OnInit {
  @Input() public reshedulData;
  @Input() public dataSendBy;
  rescheduledForm: FormGroup;
  scheduleAvailableDatesOne: any;
  scheddateMax:string;
  avialableDateFor=[];
  caluculateDate;
  showmodal:boolean=false;
  today;
  today1;
  scheduleAvailableDates=[];
  rescheduledTime=[];
  myDpOptions: IAngularMyDpOptions = {
    stylesData: {
      selector: 'dp1',
      styles: `
      .dp1 .yogaDates {
        background-color: #f26f66;
        box-shadow: inset 0 0 0 1px #66afe9;
        font-weight: bold;
        border-radius: 8px;
     }
     .dp1 .karateDates {
      background-color: #71f071;
        box-shadow: inset 0 0 0 1px #66afe9;
        font-weight: bold;
        border-radius: 8px;
   }
   .dp1 .myDpDisabled {
    background-color: #f26f66;
    color: black
    box-shadow: inset 0 0 0 1px #66afe9;
    font-weight: bold;
    border-radius: 8px;
}
      `
    },
    dateRange: false,
    dateFormat: 'yyyy-mm-dd',
    disableDates: [],
    disableSince: { year: 0, month: 0, day: 0 },
    enableDates: [{ year: 0, month: 0, day: 0 }],
    disableUntil: { year: 0, month: 0, day: 0 },
    highlightDates: [],
    sunHighlight: false,
    markDates: [
      {
        dates: [],
        styleClass: 'karateDates'
      }]
  };
  constructor(private employeerService:EmployerserviceService,private formBuilder: FormBuilder,public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.showmodal=false;
    this.avialableDateFor=[];
    this.scheduleAvailableDatesOne=[];
    this.today = (new Date()).toISOString().split('T')[0];
     this.today1 = new Date()
    //  this.myDpOptions.disableUntil= {year: 2023, month: 4, day: 21}
    //   this.myDpOptions.disableSince= {year: 2023, month: 4, day: 21}
    this.rescheduledTime=[];
//console.log("reshedulData",this.reshedulData, this.reshedulData.freelancer_id);
    let obj = {
      "user_id": this.reshedulData.freelancer_id
    }
    console.log(obj)

    // this.http.post(environment.apiUrl + 'user/getUserAvailabilities', obj)
    this.employeerService.getUserAvailabilities(obj).subscribe(data => {
      console.log("UUUUUU",data);
      if (data['status'] == true) {
        let result = data['data']
        this.scheduleAvailableDates = result
        // this.caluculateDate.forEach(element => {
        //   if (element.is_urgently == false) {
        //     let dateTest = element.date.split('T')[0]
        //     element.date = dateTest
        //     // let fromTest = element.from.split('T')[1]
        //     // element.from = fromTest
        //     // let toTest = element.to.split('T')[1]
        //     // element.to = toTest
        //     let TestDate = dateTest.split('-')
        //     // this.TodayDateRemove.push(dateTest)
        //     let todayDateone = { year: +TestDate[0], month: +TestDate[1], day: +TestDate[2] }
        //     // this.TestingDates.push(todayDateone)
        //     this.scheduleAvailableDates.push(element)
        //   }
        // });
        this.scheduleAvailableDates.forEach(element => {
          if (element.is_urgently == false && element.date >= this.today) {
                let dateTest = element.date.split('T')[0]
            element.date = dateTest
            // this.DateRequired.push(element)
            let test = element.date.split('-')
            let todayDateone = { year: +test[0], month: +test[1], day: +test[2] }
            console.log("**",test,todayDateone);
            this.avialableDateFor.push(todayDateone);
            console.log("***",this.avialableDateFor);
          }
        });
        this.scheduleAvailableDatesOne = this.scheduleAvailableDates;
        console.log(this.scheduleAvailableDates,this.scheduleAvailableDatesOne)
        const schedmaxDate = new Date(
          Math.max(
            ...this.scheduleAvailableDatesOne.map(element => {
              return new Date(element.date);
            }),
          ),
        );
        this.scheddateMax = this.convertdate(schedmaxDate)
        let currentDate = this.scheddateMax.split('-');
        let todayDate = { year: +currentDate[0], month: +currentDate[1], day: +currentDate[2] }
       // let xx={year: this.today1.getFullYear(), month: this.today1.getMonth()+1, day: this.today1.getDate()-1}
     console.log("&&",this.scheddateMax,todayDate,this.avialableDateFor)
    // debugger
        this.myDpOptions.disableUntil = { year: +currentDate[0], month: +currentDate[1], day: +currentDate[2] };
        this.myDpOptions.disableSince = { year: +currentDate[0], month: +currentDate[1], day: +currentDate[2] };
        this.myDpOptions.enableDates = this.avialableDateFor;
        this.myDpOptions.markDates[0].dates = this.avialableDateFor
        console.log(this.myDpOptions)
        this.showmodal=true;
      }
    })


    this.rescheduledForm = this.formBuilder.group({
      scheduleDate: ['', Validators.required],
      slotTime:[''],
      reason: [''],
    });
  }
  convertdate(maxDate) {
    var dateMaximum = new Date(maxDate),
      mnth = ("0" + (dateMaximum.getMonth() + 1)).slice(-2),
      day = ("0" + dateMaximum.getDate()).slice(-2);
    return [dateMaximum.getFullYear(), mnth, day].join("-");
  }
  onDateChanged(e, i) {
    this.rescheduledTime=[];
    this.scheduleAvailableDates.forEach(element=>{
      if(element.date==e.singleDate.formatted){
        this.rescheduledTime.push(element);
      }
    })
    console.log("&&",e, i,this.scheduleAvailableDates,this.rescheduledTime)
  }
  urgentTotal(){

  }
  submitData(){
    
    let d={
      "freelancer_id": this.reshedulData.freelancer_id,
      "employer_id": this.reshedulData.employer_id,
      "availability_id":this.rescheduledForm.value.slotTime,
      "hourly_rate": this.reshedulData.hourly_rate,
      "payment_id": this.reshedulData.payment_id,
      "reason": "testing bookingggggggggg ",
      "paid_by": "wallet",
      "is_urgently": this.reshedulData.is_urgently,
      "rescheduled_by": this.dataSendBy,
      "old_room_id":this.reshedulData.id,
      "old_availability_id":this.reshedulData.user_availability.id,
      "job_id":this.reshedulData.job_id,
      "invitation_id" :this.reshedulData.invitation_id
    }
    console.log("clicked",this.rescheduledForm,d);
    this.employeerService.reScheduleAppointmentRoom(d).subscribe(res=>{
      console.log("reschedule",res);
      this.activeModal.close(res);
    })
  }
}
