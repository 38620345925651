import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectsListing'
})
export class ProjectsListingPipe implements PipeTransform {

  transform(items: any[], searchText: string): any {
    // console.log('items array',items);
    // console.log('search txt',searchText);
    if (!items) return [];
    if (!searchText) return items;
    
    return items.filter((item) => {
      return items.some(y => {
        return String(item.name).toLowerCase().includes(searchText.toLowerCase()) || String(item.employer_profile.name).toLowerCase().includes(searchText.toLowerCase()) || String(item.project_code).toLowerCase().includes(searchText.toLowerCase());
      })
    })
  }
}
