import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jobsListing'
})
export class JobsListingPipe implements PipeTransform {

  transform(items: any[], searchText: string): any {
    if (!items) return [];
    if (!searchText) return items;

    return items.filter((item) => {
      return items.some(y => {
        return String(item.name).toLowerCase().includes(searchText.toLowerCase()) || String(item.reference_code).toLowerCase().includes(searchText.toLowerCase());
      })
    })
  }

}
