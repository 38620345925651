import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-all-projects',
  templateUrl: './all-projects.component.html',
  styleUrls: ['./all-projects.component.scss']
})
export class AllProjectsComponent implements OnInit {

  allProjectsData=[];

  isDesc: boolean = false;
  column: string = 'id';
  direction: number;

  page = 1;
  count = 0;
  pageSize = 10;
  pageSizes = [10, 25, 50, 100, 150];
  term: string;

  projectTypeList = ['posted', 'draft', 'contracted']
  type ='';

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private titleService: Title,
    private router: Router
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Projects | Remotelly');
    this.getAllProjects();
  }

  getAllProjects(){
    console.log(this.type);
    this.userService.getAllProjects(this.type)
    .subscribe(data => {
      if(data['status']){
        this.allProjectsData = data['data'];
        console.log(this.allProjectsData);
      }
    })
  }

  filterDataAccordingToProjectType(val){
    this.type = val;
    console.log("selected type",this.type);
    this.getAllProjects()
  }

  sort(property) {
    console.log(property);
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    this.direction = this.isDesc ? 1 : -1;
  }

  handlePageChange(event): void {
    window.scroll(0, 0);
    this.page = event;
    this.getAllPage();
  }

  handlePageSizeChange(event): void {
    this.pageSize = event.target.value;
    this.page = 1;
    this.getAllPage();
  }

  getAllPage() {
    let offset = (this.page - 1) * this.pageSize;
    const params = this.getRequestParams(this.term);
    this.userService.getAllPage(offset, this.pageSize, params)
      .subscribe(result => {
        console.log("result");
      })
  }

  getRequestParams(searchText): any {
    let params = {};

    if (searchText) {
      params[`searchText`] = searchText;
    }
    else {
      params[`searchText`] = 0;
    }


    return params;
  }

  searchData(event) {
    //  this.term=event.target.value
    this.page = 1;
    //  console.log("d",this.term)
    //  this.getAllPage();
  }

  replaceContent(html: string) {
    const tmp = document.createElement('DIV');
    let textData = '';
    if (html.length > 50) {
      textData = html.slice(0, 50)
    } else {
      textData = html
    }
    tmp.innerHTML = textData;
    return tmp.textContent || tmp.innerText || '';
  }

  projectDetails(data){
    console.log(data);
    let id = data.id;
    let employerId = data.employer_id;
    let freelancerId ='';
    if(data.user_contract){
      freelancerId = data.user_contract.freelancer_id;
      this.router.navigate([`admin/projects/details/${id}/${employerId}/${freelancerId}`]);
    }else{
      this.router.navigate([`admin/projects/details/${id}/${employerId}`]);
    }
  }

}
