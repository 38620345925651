// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  // apiUrl: 'http://localhost:8080/api/v1/',
    // socket_url : 'http://localhost:8080',
 // apiUrl: 'https://remotelly.stagingshop.com:8080/api/v1/',
  apiUrl: 'https://remotelly.com:8081/api/v1/',
  //s3_url: 'https://allam-stg.s3.ap-south-1.amazonaws.com/',
  s3_url: 'https://remotelly.s3.us-east-2.amazonaws.com/',
  // socket_url: 'https://remotelly.stagingshop.com:8080',
  socket_url: 'https://remotelly.com:8081',
  agora: {
    appId: '407deb8eaa634e0482b0f5481eefd1e9'
  },
  // Your web app's Firebase configuration
  firebaseConfig : {
    apiKey: "AIzaSyDcjmsA78bNKDDWeFgCOZdHmMyVqD-J7nk",
    authDomain: "remotelly-dc224.firebaseapp.com",
    projectId: "remotelly-dc224",
    storageBucket: "remotelly-dc224.appspot.com",
    messagingSenderId: "668189550926",
    appId: "1:668189550926:web:54f969fa5a1f06a3c9561f",
    measurementId: "G-WGVZ2W7LLR",
    vapidKey:"BEOCWDW2pvaZEZ02bx33etSYufJy40PRUgKX7YqBJSwm2xYNGKfSO3ekkfSISi27e-nIl7qEbM9dZeNy3muUaN8"
  }
  /* firebaseConfig: {
    apiKey: "AIzaSyDNp_CQAuckpD-v1MpWm1uJ6zHSJBVpRV0",
    authDomain: "remotelly-77d67.firebaseapp.com",
    projectId: "remotelly-77d67",
    storageBucket: "remotelly-77d67.appspot.com",
    messagingSenderId: "947816834099",
    appId: "1:947816834099:web:decbcd2bc05e479a4b6f46",
    measurementId: "G-L1B407C4LL",
    vapidKey:"BBW4OAxR25FreWNh5FPRIrgGmJM-B3lkKkNK8Qv0L_YvfCs8RSHoYq4uwFqtYJYiBNUkyA9TE4kJ_LKCwmluL6Q"
  } */
};
