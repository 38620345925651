import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { UserService } from '../../../services/user.service';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { FreelancerserviceService } from '../../../services/freelance-service/freelancerservice.service';
@Component({
  selector: 'app-work-submissions',
  templateUrl: './work-submissions.component.html',
  styleUrls: ['./work-submissions.component.scss']
})
export class WorkSubmissionsComponent implements OnInit {

  user_id: any;
  contractId: any;
  jobId: any;
  freelancerId: any;
  employerId: any;

  allSubmittedWorks: any = [];
  submittedWorkDetails: any;
  s3_url = environment.s3_url;
  uploadedFiles: any = [];
  fileLinkUrlData: any = '';
  fileUrlLink: any = [];
  reSubmitForm: FormGroup;
  fileUpload: boolean = false;
  resumeLoading: boolean = false;
  submitWorkId: any = '';
  projectDetails: any;
  offerDetails: any;
  redirect_url: string = 'freelancer';
  currnetUserRole = '';
  payType: any;
  hourlyPay:any;
  constructor(
    private freelancerService: FreelancerserviceService,
    private activeRouter: ActivatedRoute,
    private toasterService: ToasterService,
    private fb: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    let userDataCurrent = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = userDataCurrent.id;
    this.contractId = this.activeRouter.snapshot.params['contractId'];
    this.jobId = this.activeRouter.snapshot.params['jobId'];
    this.employerId = this.activeRouter.snapshot.params['employerId'];
    this.freelancerId = this.activeRouter.snapshot.params['freelancerId'];
    if (userDataCurrent && userDataCurrent['data'])
      this.currnetUserRole = userDataCurrent['data']['roles'][0];

    if (this.currnetUserRole == 'ROLE_FREELANCER AGENCY') {
      this.redirect_url = 'freelancer-agency';
    } else if (this.currnetUserRole == 'ROLE_EMPLOYER') {
      this.redirect_url = 'employer';
    } else {
      this.redirect_url = 'freelancer';
    }
    this.getProjectDetails();
    this.getAllSubmittedWork();
    this.reSubmitForm = this.fb.group({
      submit_work_id: [''],
      contract_id: [''],
      hours_worked: [{ value: '', disabled: true }],
      amount: [{ value: '', disabled: true }],
      description: [''],
      files: [null, Validators.required],
      title: [''],
      file_url: [''],
      from_date: [{ value: '' }],
      from_time: [{ value: '' }],
      to_date: [{ value: '' }],
      to_time: [{ value: '' }]
    })

  }

  get f() { return this.reSubmitForm.controls; }

  getProjectDetails() {
    //console.log('project api');
    let formData = {
      job_id: this.jobId,
      freelancer_id: this.freelancerId,
      employer_id: this.employerId
    }
    // this.http.post(environment.apiUrl + 'freelancer/get-project-details', formData)
    this.freelancerService.getProjectPreview(formData)
    .subscribe(data => {
      if (data['status'] == true) {
        //console.log('project details',data['data']);
        this.offerDetails = data['data'];
        this.payType = this.offerDetails.final_hire.payment_option;
        //console.log('paytype',this.payType);
      }
    })
  }

  getAllSubmittedWork() {
    let formData = {
      contract_id: this.contractId
    }
    this.freelancerService.getAllSubmittedWork(formData).subscribe(data => {
      if (data['status']) {
        this.allSubmittedWorks = data['data'];
        // console.log('submitted work data', data['data']);
        // this.allSubmittedWorks.user_re_submit_works.sort(function(a, b) {
        //   return a - b;
        // });
      }
    })
  }

  getSubmittedDetails(id) {
    this.submitWorkId = id;
    let formData = {
      submit_work_id: id
    }
    this.freelancerService.getAllSubmittedDetails(formData).subscribe(data => {
      if (data['status']) {
        this.submittedWorkDetails = data['data'];
        console.log(this.submittedWorkDetails)
        this.hourlyPay=this.submittedWorkDetails.user_contract.final_hire.rate;
        var local = moment(this.submittedWorkDetails.from_time).local().format('YYYY-MM-DD HH:mm:ss');
        console.log(local);
        // let files:any = [];
        // files = this.submittedWorkDetails.file
        //let model: IMyDateModel = {isRange: false, singleDate: {jsDate: new Date()}, dateRange: null};
        let model: IMyDateModel = { isRange: false, singleDate: { jsDate: new Date(moment(this.submittedWorkDetails.from_time).local().format('YYYY-MM-DD')) }, dateRange: null };
        let model1: IMyDateModel = { isRange: false, singleDate: { jsDate: new Date(moment(this.submittedWorkDetails.to_time).local().format('YYYY-MM-DD')) }, dateRange: null };
        this.reSubmitForm.patchValue({
          title: this.submittedWorkDetails.title,
          amount: this.submittedWorkDetails.amount,
          description: this.submittedWorkDetails.message,
          from_date: model,
          from_time: moment(this.submittedWorkDetails.from_time).local().format('HH:mm:ss'),
          to_date: model1,
          to_time: moment(this.submittedWorkDetails.to_time).local().format('HH:mm:ss'),

          // files: this.submittedWorkDetails.file,
          // file_url: this.submittedWorkDetails.file_url,
          hours_worked: this.submittedWorkDetails.hours_worked
        })
        console.log(this.reSubmitForm);
        //console.log('resubmit work', this.f.amount.value);
        //console.log('submitted details', data['data']);
        document.getElementById('resubmit').click();
      }
    })
  }

  uploadFile(event) {
    if (event.target.files && event.target.files[0]) {
      const file = (event.target as HTMLInputElement).files[0];
      if (file.size / 1024 / 1024 > 5) {
        this.toasterService.pop('error', 'file size cannot be more than 5MB');
        return;
      }
      var filesAmount = event.target.files.length;
      for (let file of event.target.files) {

        let reader = new FileReader();
        reader.onload = (e: any) => {
          //console.log('e.target.result', e.target.result);
          this.uploadedFiles.push(
            //this.createItem({
            file,
            // url: e.target.result, //Base64 string for preview image
            //})
          );
          this.toasterService.pop('success', 'File uploaded');
        };
        reader.readAsDataURL(file);
      }
      this.reSubmitForm.patchValue({
        files: this.uploadedFiles
      });
    }
  }

  removeFile(ind) {
    //console.log(ind);
    //console.log(this.uploadedFiles);
    this.uploadedFiles.splice(ind, 1);
    //console.log('array',this.uploadedFiles);
    this.toasterService.pop('info', 'File removed!.')


  }

  addFileLink() {
    //console.log('url',this.fileLinkUrlData);
    if (this.fileLinkUrlData != '') {
      this.fileUrlLink.push(this.fileLinkUrlData);
      this.fileLinkUrlData = '';
      // (document.getElementById('addLink') as HTMLFormElement).value = ''; 
    }
    //console.log('file url array add',this.fileUrlLink);
  }

  removeFileLink(ind) {
    this.fileUrlLink.splice(ind, 1);
    //console.log('file url array remove',this.fileUrlLink);
  }

  resumeLoadingCancel() {
    this.resumeLoading = false;
    document.getElementById('resubmitdismiss').click();
  }

  reSubmitWork() {
    console.log('form of submit', this.reSubmitForm.value);
    let local = moment(this.reSubmitForm.value.from_date.singleDate.jsDate).local().format('YYYY-MM-DD');
    let sTime = local+ " "
      + this.reSubmitForm.value.from_time;
    let start_time = moment.utc(moment(sTime)).format()
    let local1 = moment(this.reSubmitForm.value.to_date.singleDate.jsDate).local().format('YYYY-MM-DD');
    let eTime = local1+ " "
      + this.reSubmitForm.value.to_time;
    let end_time = moment.utc(moment(eTime)).format()
    console.log("&&&",sTime,eTime,start_time, end_time);
    this.resumeLoading = true;
    this.reSubmitForm.patchValue({
      submit_work_id: this.submitWorkId,
      contract_id: this.contractId,
      amount: this.submittedWorkDetails.amount,
    })

    if (this.f.files.value == null) {
      this.toasterService.pop('error', 'Please upload files!');
      this.fileUpload = true;
      this.resumeLoading = false;
      return;
    }
    let formData: any = new FormData();
    for (var i = 0; i < this.uploadedFiles.length; i++) {
      //console.log(this.uploadedFiles[i]); 
      formData.append("files", this.uploadedFiles[i]);
    }
    if (this.submittedWorkDetails.user_re_submit_works.length == 0) {
      formData.append("previous_submission_id", this.submittedWorkDetails.id);
      formData.append("submit_type", "submit");
    } else {
      let lastIndex = this.submittedWorkDetails.user_re_submit_works.length - 1;
      let lastArrayWork = this.submittedWorkDetails.user_re_submit_works[lastIndex]
      formData.append("previous_submission_id", lastArrayWork.id);
      formData.append("submit_type", "re-submit");
    }
    formData.append("file_url", JSON.stringify(this.fileUrlLink));
    formData.append("submit_work_id", this.f.submit_work_id.value);
    formData.append("contract_id", this.f.contract_id.value);
    formData.append("hours_worked", this.f.hours_worked.value);
    formData.append("amount", this.f.amount.value);
    formData.append("description", this.f.description.value);
    formData.append("title", this.f.title.value);
    formData.append("from_time", moment.utc(moment(start_time)).format());
    formData.append("to_time", moment.utc(moment(end_time)).format());

    //console.log('resubmit form data', this.reSubmitForm.value);
    this.freelancerService.reSubmitBySubmitWorkId(formData).subscribe(data => {
      if (data['status']) {
        this.toasterService.pop('success', data['message']);
        document.getElementById('resubmitdismiss').click();
        this.getAllSubmittedWork();
        this.resumeLoading = false;
        window.scroll(0, 0);
      } else {
        this.toasterService.pop('error', data['message']);
        this.resumeLoading = false;
        window.scroll(0, 0);
      }
    })
  }

  reviewAndPay() {
    this.router.navigate([`/employer/reviewPay/${this.contractId}/${this.payType}`]);
  }

}