import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {NavigationEnd, Router} from '@angular/router';
import {MenuItems} from '../../shared/backend-sidebar/menu-items';

import { AuthenticationService } from '../../services';
import { User } from '../../models';

@Component({
  selector: 'app-backend-layout',
  templateUrl: './backend-layout.component.html',
  styleUrls: ['./backend-layout.component.scss'],
  animations: [
    trigger('mobileMenuTop', [
      state('no-block, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('yes-block',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('no-block <=> yes-block', [
        animate('400ms ease-in-out')
      ])
    ]),
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
    trigger('slideOnOff', [
      state('on', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      state('off', style({
        transform: 'translate3d(100%, 0, 0)'
      })),
      transition('on => off', animate('400ms ease-in-out')),
      transition('off => on', animate('400ms ease-in-out'))
    ]),
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({opacity: 0}),
        animate('400ms ease-in-out', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({transform: 'translate(0)'}),
        animate('400ms ease-in-out', style({opacity: 0}))
      ])
    ])
  ]
})
export class BackendLayoutComponent implements OnInit {

  currentUser: User;
  isAuth:boolean;
  global_user_name:string;
  global_username:string;
  title = 'Welcome ! Allam App';

  navType: string; /* st1, st2(default), st3, st4 */
  themeLayout: string; /* vertical(default) */
  layoutType: string; /* dark, light */
  verticalPlacement: string; /* left(default), right */
  verticalLayout: string; /* wide(default), box */
  deviceType: string; /* desktop(default), tablet, mobile */
  verticalNavType: string; /* expanded(default), offcanvas */
  verticalEffect: string; /* shrink(default), push, overlay */
  vNavigationView: string; /* view1(default) */
  pcodedHeaderPosition: string; /* fixed(default), relative*/
  pcodedSidebarPosition: string; /* fixed(default), absolute*/
  headerTheme: string; /* theme1(default), theme2, theme3, theme4, theme5, theme6 */
  logoTheme: string; /* theme1(default), theme2, theme3, theme4, theme5, theme6 */

  innerHeight: string;
  windowWidth: number;

  toggleOn: boolean;

  headerFixedMargin: string;
  navBarTheme: string; /* theme1, themelight1(default)*/
  activeItemTheme: string; /* theme1, theme2, theme3, theme4(default), ..., theme11, theme12 */

  isCollapsedMobile: string;
  isCollapsedSideBar: string;

  chatToggle: string;
  chatToggleInverse: string;
  chatInnerToggle: string;
  chatInnerToggleInverse: string;

  menuTitleTheme: string; /* theme1, theme2, theme3, theme4, theme5(default), theme6 */
  itemBorder: boolean;
  itemBorderStyle: string; /* none(default), solid, dotted, dashed */
  subItemBorder: boolean;
  subItemIcon: string; /* style1, style2, style3, style4, style5, style6(default) */
  dropDownIcon: string; /* style1(default), style2, style3 */
  configOpenRightBar: string;
  isSidebarChecked: boolean;
  isHeaderChecked: boolean;

  @ViewChild('searchFriends', /* TODO: add static flag */ {static: false}) search_friends: ElementRef;

  public config: any;

  loginUser:any;

  constructor(public menuItems: MenuItems,private router: Router,private authenticationService: AuthenticationService) {
  this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.navType = 'st5';
    this.themeLayout = 'vertical';
    this.vNavigationView = 'view1';
    this.verticalPlacement = 'left';
    this.verticalLayout = 'wide';
    this.deviceType = 'desktop';
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.pcodedHeaderPosition = 'fixed';
    this.pcodedSidebarPosition = 'fixed';
    this.headerTheme = 'theme1';
    this.logoTheme = 'theme1';

    this.toggleOn = true;

    this.headerFixedMargin = '80px';
    this.navBarTheme = 'themelight1';
    this.activeItemTheme = 'theme4';

    this.isCollapsedMobile = 'no-block';
    this.isCollapsedSideBar = 'no-block';

    this.chatToggle = 'out';
    this.chatToggleInverse = 'in';
    this.chatInnerToggle = 'off';
    this.chatInnerToggleInverse = 'on';

    this.menuTitleTheme = 'theme5';
    this.itemBorder = true;
    this.itemBorderStyle = 'none';
    this.subItemBorder = true;
    this.subItemIcon = 'style6';
    this.dropDownIcon = 'style1';
    this.isSidebarChecked = true;
    this.isHeaderChecked = true;

    const scrollHeight = window.screen.height - 150;
    this.innerHeight = scrollHeight + 'px';
    this.windowWidth = window.innerWidth;
    this.setMenuAttributes(this.windowWidth);

    // dark
    /*this.setLayoutType('dark');
    this.headerTheme = 'theme5';
    this.logoTheme = 'theme5';*/

    // light-dark
    /*this.setLayoutType('dark');
    this.setNavBarTheme('themelight1');
    this.navType = 'st2';*/

    // dark-light
    // this.setNavBarTheme('theme1');
    // this.navType = 'st3';

  }

  ngOnInit() {
    this.setBackgroundPattern('pattern2');
   // this.checkPermission();

  }

  onResize(event) {
    this.innerHeight = event.target.innerHeight + 'px';
    /* menu responsive */
    this.windowWidth = event.target.innerWidth;
    let reSizeFlag = true;
    if (this.deviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 1024) {
      reSizeFlag = false;
    } else if (this.deviceType === 'mobile' && this.windowWidth < 768) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  setMenuAttributes(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.deviceType = 'tablet';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'push';
    } else if (windowWidth < 768) {
      this.deviceType = 'mobile';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
    } else {
      this.deviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
  }

  toggleOpened() {
    if (this.windowWidth < 768) {
      this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
    }
    this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
  }

  onClickedOutside(e: Event) {
    if (this.windowWidth < 768 && this.toggleOn && this.verticalNavType !== 'offcanvas') {
      this.toggleOn = true;
      this.verticalNavType = 'offcanvas';
    }
  }

  onMobileMenu() {
    this.isCollapsedMobile = this.isCollapsedMobile === 'yes-block' ? 'no-block' : 'yes-block';
  }

  toggleChat() {
    this.chatToggle = this.chatToggle === 'out' ? 'in' : 'out';
    this.chatToggleInverse = this.chatToggleInverse === 'out' ? 'in' : 'out';
    this.chatInnerToggle = 'off';
    this.chatInnerToggleInverse = 'off';
  }

  toggleChatInner() {
    this.chatInnerToggle = this.chatInnerToggle === 'off' ? 'on' : 'off';
    this.chatInnerToggleInverse = this.chatInnerToggleInverse === 'off' ? 'on' : 'off';
  }

  searchFriendList(e: Event) {
    const search = (this.search_friends.nativeElement.value).toLowerCase();
    let search_input: string;
    let search_parent: any;
    const friendList = document.querySelectorAll('.userlist-box .media-body .chat-header');
    Array.prototype.forEach.call(friendList, function(elements, index) {
      search_input = (elements.innerHTML).toLowerCase();
      search_parent = (elements.parentNode).parentNode;
      if (search_input.indexOf(search) !== -1) {
        search_parent.classList.add('show');
        search_parent.classList.remove('hide');
      } else {
        search_parent.classList.add('hide');
        search_parent.classList.remove('show');
      }
    });
  }

  toggleOpenedSidebar() {
    this.isCollapsedSideBar = this.isCollapsedSideBar === 'yes-block' ? 'no-block' : 'yes-block';
  }

  toggleRightbar() {
    this.configOpenRightBar = this.configOpenRightBar === 'open' ? '' : 'open';
  }

  setSidebarPosition() {
    this.isSidebarChecked = !this.isSidebarChecked;
    this.pcodedSidebarPosition = this.isSidebarChecked === true ? 'fixed' : 'absolute';
  }

  setHeaderPosition() {
    this.isHeaderChecked = !this.isHeaderChecked;
    this.pcodedHeaderPosition = this.isHeaderChecked === true ? 'fixed' : 'relative';
    this.headerFixedMargin = this.isHeaderChecked === true ? '80px' : '';
  }

  setBackgroundPattern(pattern) {
    document.querySelector('body').setAttribute('themebg-pattern', pattern);
  }

  setLayoutType(type: string) {
    this.layoutType = type;
    if (type === 'dark') {
      this.headerTheme = 'theme6';
      this.navBarTheme = 'theme1';
      this.logoTheme = 'theme6';
      document.querySelector('body').classList.add('dark');
    } else {
      this.headerTheme = 'theme1';
      this.navBarTheme = 'themelight1';
      this.logoTheme = 'theme1';
      document.querySelector('body').classList.remove('dark');
    }
  }

  setNavBarTheme(theme: string) {
    if (theme === 'themelight1') {
      this.navBarTheme = 'themelight1';
    } else {
      this.navBarTheme = 'theme1';
    }
  }

    public logout() {
        this.authenticationService.logout();
        this.router.navigate(['authentication/login']);
    }

    checkPermission(menu=''){
        this.loginUser      = this.authenticationService.currentUserValue;
        this.loginUser      = JSON.parse(this.loginUser);
        console.log(this.loginUser);
        //const userRoleId    = this.loginUser['user'][0]['role_id'];
        //const userPermission = this.loginUser.permission;
        //this.global_user_name = this.loginUser.user[0].first_name+' '+this.loginUser.user[0].last_name;
        this.global_username = this.loginUser.username;
 
        this.isAuth=false;
        /* userPermission.forEach( (permission, index) => {
           if(permission.name==menu) {
             this.isAuth=true;
            }
        }); */


       if(this.isAuth){
         //console.log('--exits');
       }
       /* if(userRoleId==1){
        this.isAuth=true;
       } */

      //for super admin hide menu////
      /* if(menu=='view-study-exam' && userRoleId==1){
        this.isAuth=false;
      }
      if(menu=="study-exam-approval" && userRoleId==1){
        this.isAuth=false;
      }
      if(menu=="view-special" && userRoleId==1){
        this.isAuth=false;
      }
      if(menu=="view-league" && userRoleId==1){
        this.isAuth=false;
      }
      if(menu=="view-daily" && userRoleId==1){
        this.isAuth=false;
      } 
      if(menu=="view-broadcaster" && userRoleId==1){
        this.isAuth=false;
      } 
      if(menu=="view-referendum" && userRoleId==1){
        this.isAuth=false;
      }
      if(menu=="view-category" && userRoleId==1){
        this.isAuth=false;
      }
      if(menu=="view-header" && userRoleId==1){
        this.isAuth=false;
      }
      if(menu=="view-gc" && userRoleId==1){
        //this.isAuth=false;
      }
      if(menu=="view-leaderboard" && userRoleId==1){
        this.isAuth=false;
      } */
      //console.log('---->'+menu+'<---------');
      ////////////////////////////
       return this.isAuth;
    }

     dashboard_url(){

      /* this.loginUser      = this.authenticationService.currentUserValue;
       this.loginUser      = JSON.parse(this.loginUser);
       */
       //const userRoleId    = this.loginUser.user[0].role_id;

       var url='/';
        
       /* if(userRoleId==1){
        url='/';
       }
       else if(userRoleId==2){
        url='/category';
       }
       else if(userRoleId==3){
        url='/dashboard';
       }
       else if(userRoleId==4){
        url='/study-exam';
       }
       else if(userRoleId==5){
        url='/dashboard';
       }
       else if(userRoleId==6){
        url='/teacher-supervisor';
       }
       else if(userRoleId==9){
        url='/broadcasting';
       }
       else{
         url='/';
       } */

       return url;
    }

}
