import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';

import { AuthenticationService } from './services';
import { User } from './models';
import { NgxSpinnerService } from "ngx-spinner";
import { ToasterConfig } from 'angular2-toaster';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from "../environments/environment";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit 
{
  currentUser: User;
  title = 'Welcome ! Allam App';
  config: any;
  message:any = null;


  constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      
    }

    public logout() {
        this.authenticationService.logout();
        this.router.navigate(['authentication/login']);
    }
    

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.config = new ToasterConfig({
      positionClass: "toast-top-right",
      timeout: 3000,
      newestOnTop: true,
      tapToDismiss: true,
      preventDuplicates: true,
      animation: "fade",
      limit: 3,
    });

    this.requestPermission();
    this.listen();

  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, 
     { vapidKey: environment.firebaseConfig.vapidKey}).then(
       (currentToken) => {
         if (currentToken) {
           console.log("Hurraaa!!! we got the token.....");
           console.log(currentToken);
           /* this.userService.saveDeviceToken({"user_id": this.user_id, "device_token": currentToken}).subscribe(data =>{
            if(data['status']){
            }
          }); */
         } else {
           console.log('No registration token available. Request permission to generate one.');
         }
     }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message=payload;
    });
  }

 
}
