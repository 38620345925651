import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Signup } from '../../models';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FreelancerserviceService {

  constructor(private http: HttpClient) { }
  getPendingOffers(formData) {
    return this.http.post(environment.apiUrl + 'user/get-pending-offer', formData);
  }
  getaccepteOffer(formData){
  return this.http.post(environment.apiUrl + 'user/accepteOffer', formData);
  }
  getdeclineOffer(formData){
    return this.http.post(environment.apiUrl + 'user/declineOffer', formData);
    }
    submitwork(formData) {
      return this.http.post(environment.apiUrl + 'user/submit_work', formData);
    }
    getActiveContracts(formData) {
      return this.http.post(environment.apiUrl + 'user/getactivecontracts', formData);
    }
    getContractListById(formData) {
      return this.http.post(environment.apiUrl + 'user/get-contractById/', formData);
    }
    getUserCardDetails(userId) {
      return this.http.get(environment.apiUrl + 'user/get_user_allbilling_card/' + userId + '/' + 100);
    }
    getAllAppointmentInvitation(formData) {
      return this.http.post(environment.apiUrl + 'user/getFreelancerAllInvitaionAppointmentRoom', formData);
    }
    acceptVideoCallRequest(formData) {
      return this.http.post(environment.apiUrl + 'user/acceptVideocallRequest', formData);
    }
    refuseVideoCallRequest(formData) {
      return this.http.post(environment.apiUrl + 'user/refuseVideocallRequest', formData);
    }
    refundPayment(formData) {
      return this.http.post(environment.apiUrl + 'employer/refundPayment', formData);
    }
    getTotalTimeData(data:any){
      return this.http.post(environment.apiUrl + 'user/get-total-submit-work-time', data)
    }
    getAllOffers(formData) {
      return this.http.post(environment.apiUrl + 'user/get-latest-offer', formData);
    }
    acceptOffers(final_hire_id, freelancer_id, employer_id, job_id) {
    //  debugger
      let formData = {
        final_hire_id: final_hire_id,
        freelancer_id: freelancer_id,
        employer_id: employer_id,
        job_id: job_id,
  
      }
      return this.http.post(environment.apiUrl + 'user/adduserContract', formData);
    }
  declineoffer(formData) {
    return this.http.post(environment.apiUrl + 'user/declineOffer', formData);
  }
  getjobById(id: string) {
    return this.http.get(environment.apiUrl + 'user/getuserjob_id/' + id);
  }
  getproposalById(id: string) {
    return this.http.get(environment.apiUrl + 'user/getproposalById/' + id);
  }
  withdrawproposal(formData) {
    return this.http.post(environment.apiUrl + 'user/withdraw-proposal', formData)
  }
  userverifyjobseekerchangedemail(obj){
    return  this.http.post(environment.apiUrl + 'user/verify-jobseeker-changed-email', obj);
  }
  updateproposal(id, Data): Observable<any> {
    console.log('Job', Data);
    return this.http.patch(environment.apiUrl + 'user/edit-proposal/' + id, Data);
  }
  saveJob(id, userID): Observable<any> {
    let formData = {
      secondary_job_id: id,
      user_id: userID
    }
    return this.http.post(environment.apiUrl + 'user/save-secondary-job', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  dislikeJob(formData) {
    return this.http.post(environment.apiUrl + 'user/jobs/dislike', formData);
  }
  getDislikedJobsList(formData) {
    return this.http.post(environment.apiUrl + 'user/jobs/get-disliked', formData);
  }
  applyJob(id, user_id, file, employer_id, jobData): Observable<any> {
    console.log('job', jobData);

    var formData: any = new FormData();
    // formData.append("resume", jobData.resume_file);
    formData.append("secondary_job_id", id);
    formData.append("employer_id", employer_id);
    formData.append("user_id", user_id);
    formData.append("answers", JSON.stringify(jobData));
    // formData.append("exp_years", jobData.experience);
    // formData.append("designation", jobData.designation),
    // formData.append("mobile_number", jobData.contact_number),
    // formData.append("country_code",  jobData.country_code),
    // formData.append("country", jobData.country),
    // formData.append("salary_amount_min",  jobData.min_salary),
    // formData.append("salary_amount_max",  jobData.max_salary)

    return this.http.post<any>(environment.apiUrl + 'user/apply-for-secondary-job', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  submitproposal(formData) {
    console.log('service',formData);
    let headers = new Headers();
headers.append('Content-Type', 'multipart/form-data;boundary='+Math.random());
headers.append('Accept', 'application/json');
    return this.http.post(environment.apiUrl + 'user/apply_job', formData);
  }
  unsaveJob(formData) {
    return this.http.post(environment.apiUrl + 'user/unsave-secondary-job', formData)
  }
  getSavedJobs(userId) {
    let formData = {
      user_id: userId
    }
    return this.http.post<any>(environment.apiUrl + 'user/get-saved-secondary-jobs', formData);
  }
  removeSecondaryJobFromDislike(formData) {
    return this.http.post(environment.apiUrl + 'user/remove-secondary-job-from-dislike', formData);
  }
  // getSearchedProject(params: any) {
  //   return this.http.post(environment.apiUrl + 'user/get_searchedprojects', params);
  // }
  getAllUserJobs() {
    return this.http.get(environment.apiUrl + 'user/get-jobs-list');
  }
  getskillbasedjobsdata(userId) {
    return this.http.get(environment.apiUrl + 'user/get-job-suggestions/' + userId);
  }
  // searchproject(formData) {
  //   return this.http.post(environment.apiUrl + 'user/search-project', formData);
  // }
  getbestmatchjobsdata(userId) {
    return this.http.get(environment.apiUrl + 'user/get-best-jobs/' + userId);
  }
  saveSecondaryJob(formData) {
    return this.http.post(environment.apiUrl + 'user/save-secondary-job', formData);
  }
  unsaveSecondaryJob(formData) {
    return this.http.post(environment.apiUrl + 'user/unsave-secondary-job', formData);
  }
  getSavedAndDislikedSecondaryJobs(formData) {
    return this.http.post(environment.apiUrl + 'user/get-saved-disliked-secondary-jobs', formData);
  }
  dislikeSecondaryJob(formData) {
    return this.http.post(environment.apiUrl + 'user/dislike-secondary-job', formData);
  }
  getAllAppliedJobs(user_id) {
    return this.http.get(environment.apiUrl + 'user/getallappliedjobs/' + user_id);
  }
  startConversation(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/start-conversation', formData);
  }
  changeDisputeStatus(formData) {
    return this.http.post(environment.apiUrl + 'user/change_dispute_status', formData);
  }
  getProjectPreview(formData) {
    return this.http.post(environment.apiUrl + 'freelancer/get-project-details', formData);
  }
  getRemotellyPercentage(){
    return this.http.get(environment.apiUrl+'masterdata/get_service_cost/'+'1')
  }
  getuadduserdispute(formData) {
    return this.http.post(environment.apiUrl + 'user/add_user_dispute', formData);
  }
  getCertificateDetails(formData) {
    return this.http.post(environment.apiUrl + 'user/get-certificate', formData);
  }
  editCertificateDetails(formData) {
    return this.http.post(environment.apiUrl + 'user/edit-certificate', formData);
  }
  getOtherExperience(formData) {
    return this.http.post(environment.apiUrl + 'user/get-other-experience', formData);
  }

  editOtherExperience(formData) {
    return this.http.post(environment.apiUrl + 'user/edit-other-experience', formData);
  }
  getPortfolioDetails(formData) {
    return this.http.post(environment.apiUrl + 'user/get-portfolio', formData);
  }

  editPortfolioDetails(formData) {
    return this.http.post(environment.apiUrl + 'user/edit-portfolio', formData);
  }
  userPortfolio(user_id, portfolioData): Observable<any> {
    console.log('Portfolio', portfolioData);
    let skills = '';
    if (portfolioData.skills != '' || portfolioData.skills != null)
      skills = JSON.stringify(portfolioData.skills);
    var formData: any = new FormData();
    formData.append("project_title", portfolioData.project_title),
      formData.append("project_overview", portfolioData.project_overview),
      formData.append("contract_project", portfolioData.contract_project),
      formData.append("category", portfolioData.category),
      formData.append("sub_category", portfolioData.sub_category),
      formData.append("completion_date", portfolioData.completion_date),
      formData.append("start_date", portfolioData.start_date),
      formData.append("skills", skills),
      formData.append("thumbnails", portfolioData.thumbnails),
      formData.append("project_file", portfolioData.project_file),
      formData.append("user_id", user_id);
    console.log(JSON.stringify(portfolioData));
    return this.http.post<any>(environment.apiUrl + 'user/user_portfolio', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  getSecondaryJobDetails(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-secondary-job-details', formData);
  }
  applyForSecondaryJob(formValues, job_id, freelancer_id, employer_id) {
    let formData = new FormData();
    formData.append("secondary_job_id", job_id);
    formData.append("freelancer_id", freelancer_id);
    formData.append("employer_id", employer_id);
    formData.append("file", formValues['file']);
    formData.append("exp_years", formValues['exp_years']);
    formData.append("exp_months", formValues['exp_months']);
    formData.append("designation", formValues['designation']);
    formData.append("salary_currency", formValues['salary_currency']);
    formData.append("salary_amount", formValues['salary_amount']);
    formData.append("country", formValues['country']);
    formData.append("country_code", formValues['country_code']);
    formData.append("skills", formValues['skills']);
    return this.http.post(environment.apiUrl + 'user/apply-for-secondary-job', formData);
  }
  getSavedSecondaryJobs(formData) {
    return this.http.post(environment.apiUrl + 'user/get-saved-secondary-jobs', formData);
  }
  getAppliedSecondaryJobs(formData) {
    return this.http.post(environment.apiUrl + 'user/get-applied-secondary-jobs', formData);
  }
  sendMessage(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/send', formData);
  }
  getContractMilestones(formData) {
    return this.http.post(environment.apiUrl + 'user/get-contract-milestones', formData);
  }
  sendMessageWithAttachment(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/send-attachment', formData);
  }
  endContract(formData) {
    return this.http.post(environment.apiUrl + 'user/end-contract', formData);
  }
  getAllContracts(formData) {
    return this.http.post(environment.apiUrl + 'user/getallcontracts', formData);
  }
  proposeNewContract(formData) {
    return this.http.post(environment.apiUrl + 'user/send-new-contract', formData);
  }
  getOriginalproposal(formData) {
    return this.http.post(environment.apiUrl + 'user/get-Originalproposal', formData);
  }
  userCertification(user_id, certificationData): Observable<any> {
    //console.log('userCertification', certificationData);
    // var expiration_status = false;
    // if(certificationData.expiration_date != null || certificationData.expiration_date != ''){
    //   expiration_status = false;
    // }else{
    //   expiration_status = true;
    // }
    var formData: any = new FormData();
    formData.append("certificate_type", certificationData.certificate_type),
      formData.append("certificate_name", certificationData.certificate_name),
      formData.append("provider", certificationData.provider),
      formData.append("description", certificationData.description),
      formData.append("certificate_url", certificationData.website_url),
      formData.append("expiration_date", certificationData.expiration_date),
      formData.append("certificate_upload", certificationData.certificate_file),
      formData.append("user_id", user_id),
      formData.append("no_expiration_date", certificationData.no_expiration_date)

    // console.log(JSON.stringify(certificationData));
    return this.http.post<any>(environment.apiUrl + 'user/user_certificate', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  getAllAdditionalDetails(userId) {
    return this.http.get(environment.apiUrl + 'user/getAll-additional_details/' + userId);
  }
  deleteUserCertification(formData) {
    return this.http.post(environment.apiUrl + 'user/remove-certificate', formData);
  }
  userVideo(user_id, videoData): Observable<any> {
    console.log('videoData', videoData);
    videoData["user_id"] = user_id;
    console.log(JSON.stringify(videoData));
    return this.http.post<any>(environment.apiUrl + 'user/user_video', videoData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  userOtherExperience(user_id, experienceData): Observable<any> {
    console.log('experienceData', experienceData);
    experienceData["user_id"] = user_id;
    console.log(JSON.stringify(experienceData));
    return this.http.post<any>(environment.apiUrl + 'user/user_other_experience', experienceData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  getAllEmployment(userId) {
    return this.http.get(environment.apiUrl + 'user/getAll-employment_details/' + userId);
  }
  userEmploymentSave(user_id, employmentData): Observable<any> {
    employmentData["user_id"] = user_id;
    return this.http.post<any>(environment.apiUrl + 'user/user_saveemployment', employmentData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  getVideoDetails(id: string) {
    return this.http.get(environment.apiUrl + 'user/get_video/' + id);
  }
  UpdateVideoDetails(formData) {
    return this.http.post(environment.apiUrl + 'user/edit_video', formData);
  }
  getUsersAllTimeWork(formData:any){
    return this.http.post(environment.apiUrl + 'user/get-user-all-working-time', formData);
  }
  getUserProjects(userId:string){
    return this.http.post(environment.apiUrl + 'user/get-projects', { id: userId });
  }
  viewProposal(formData) {
    return this.http.post(environment.apiUrl + 'user/view-all-proposals', formData);
  }
  viewArchiveProposal(formData) {
    return this.http.post(environment.apiUrl + 'user/view-archived-proposals', formData);
  }
  ArchiveProposal(formData) {
    return this.http.post(environment.apiUrl + 'user/archive-proposal', formData);
  }
  ArchiveInterview(formData) {
    return this.http.post(environment.apiUrl + 'user/archive-interview', formData);
  }
  uploadUserId(formData) {
    return this.http.post(environment.apiUrl + 'user/upload_id', formData);
  }
    //verification preferences

    getUserVerificationPreferences(formData) {
      return this.http.post(environment.apiUrl + 'user/get_verification_preferences', formData);
    }
  changeUserPassword(signup: Signup) {
    return this.http.post(environment.apiUrl + 'user/change_password_1', signup);
  }
  setAuthenticatorVerification(formData) {
    return this.http.post(environment.apiUrl + 'user/set_authenticator_verification', formData);
  }
  setTextVerification(formData) {
    return this.http.post(environment.apiUrl + 'user/set_text_verification', formData);
  }
  
  setSecurityVerification(formData) {
    return this.http.post(environment.apiUrl + 'user/set_security_verification', formData);
  }
  setFirstPreferenceAndScenario(formData) {
    return this.http.post(environment.apiUrl + 'user/set_preference_and_scenario', formData);
  }
  
  getUserTaxInfo(formData) {
    return this.http.post(environment.apiUrl + 'user/tax/get', formData);
  }

  setUserTaxResidence(formData) {
    return this.http.post(environment.apiUrl + 'user/tax/set_residence', formData);
  }
  setUserTaxGst(formData) {
    return this.http.post(environment.apiUrl + 'user/tax/set_gst', formData);
  }

  setUserTaxPanAadhar(formData) {
    return this.http.post(environment.apiUrl + 'user/tax/set_pan_aadhar', formData);
  }
  setUserTaxWbbin(formData) {
    return this.http.post(environment.apiUrl + 'user/tax/set_wbbin', formData);
  }
  setDefaultTeamForUser(formData) {
    return this.http.post(environment.apiUrl + 'teams/set-default', formData);
  }
  getTeamsListForUsers(formData) {
    return this.http.post(environment.apiUrl + 'teams/get', formData);
  }
  getDefaultTeamForUser(formData) {
    return this.http.post(environment.apiUrl + 'teams/get-default', formData);
  }
  createAvailabilities(formData){
    return this.http.post(environment.apiUrl + 'user/createAvailabilities', formData)
  }
  getUserAvailabilities(formData){
    return this.http.post(environment.apiUrl+ 'user/getUserAvailabilities',formData)
  }
  getFreelancerAllAppointmentRoom(formData){
    return this.http.post(environment.apiUrl + 'user/getFreelancerAllAppointmentRoom', formData)
  }
  gettransactions(obj){
    return this.http.post(environment.apiUrl + 'freelancer/get-transactions', obj)
  }
  getAllSubmittedWork(formData) {
    return this.http.post(environment.apiUrl + 'user/get-all-submitworkByProject', formData);
  }
  getAllSubmittedDetails(formData) {
    return this.http.post(environment.apiUrl + 'user/get-submitworkDetails', formData);
  }
  reSubmitBySubmitWorkId(formData) {
    return this.http.post(environment.apiUrl + 'user/re_submit_work', formData);
  }
  masterdatacountriesweb(){
   return this.http.get(environment.apiUrl + 'masterdata/countries/web')
  }
  usersendemailverificationcode(redirect_url,value){
    let apiHeader: String = '';
    if (redirect_url == 'jobseeker') {
      apiHeader = 'user/send-email-verification_code'
    } else {
      apiHeader = 'user/send-email-verificationlink'
    }
   return this.http.post(environment.apiUrl + apiHeader, value)
  }
  getFreelancerReport(id:any){
    return this.http.get(environment.apiUrl +'user/freelancer-reports/'+id);
  }
  getFreelancerVideoReport(id:any){
    return this.http.get(environment.apiUrl +'user/freelancer-videos-reports/'+id);
  }
}
