import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { BreadcrumbsComponent } from './shared/backend-header/breadcrumbs/breadcrumbs.component';
import { TitleComponent } from './shared/backend-header/title/title.component';
import { AuthComponent } from './layout/auth/auth.component';

import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import additional
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
//import {Format} from "./pipes/format";
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { CategoryService } from './services/category.service';
import { RoleService } from './services/role.service';
import { UserService } from './services';

import { PocquestionService } from './services/studyexam/pocquestion.service';
import { QuestionService } from './services/studyexam/question.service';
import { CurriculumSingleService } from './services/studyexam/curriculum-single.service';
import { CompetitiveSingleService } from './services/studyexam/competitive-single.service';
import { TsupervisorService } from './services/tsupervisor.service';
import { GcService } from './services/gc.service';

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
//import { OwlDateTimeModule, OwlNativeDateTimeModule } from '../../projects/picker/src/public_api';

import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { GlobalHttpInterceptorService } from './http-error.interceptor';
import { AuthInterceptorService } from './auth.interceptor';

import { ImageCropperModule } from 'ngx-image-cropper';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { BackendLayoutComponent } from './layouts/backend-layout/backend-layout.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { EmployerLayoutComponent } from './layouts/employer-layout/employer-layout.component';
import { FreelancerLayoutComponent } from './layouts/freelancer-layout/freelancer-layout.component';
import { NgxSpinnerModule } from "ngx-spinner";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CameraComponent } from './pages/twilio/camera/camera.component';
import { HomeComponent } from './pages/twilio/home/home.component';
import { ParticipantsComponent } from './pages/twilio/participants/participants.component';
import { RoomsComponent } from './pages/twilio/rooms/rooms.component';
import { SettingsComponent } from './pages/twilio/settings/settings.component';
import { DeviceSelectComponent } from './pages/twilio/settings/device-select.component';
import { ResponseManagerComponent } from './pages/employer/response-manager/response-manager.component';
import { TwoDigitDecimaNumberDirective } from './directive/twodigitdecimalnumber.directive';
import { VideoModule } from './pages/video/video.module';
import { VideoChatService } from './services/videochat.service';
import { DeviceService } from './services/device.service';
import { StorageService } from './services/storage.service';
import { LoaderInterceptorService } from './loader.interceptor';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
// import { HomePageComponent } from './pages/home-page/home-page.component';
// import { AngularFireModule } from '@angular/fire';
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from '../environments/environment';
import { initializeApp } from "firebase/app";
initializeApp(environment.firebaseConfig);

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
/* BsDatepickerConfig */
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { setTheme } from 'ngx-bootstrap/utils';
setTheme('bs4'); // or 'bs4'

import { CardDetailsService } from './services/card-details.service';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { UserDataService } from './services/user-data.service';
import { AboutUsComponent } from './pages/stationary-pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './pages/stationary-pages/privacy-policy/privacy-policy.component';
import { WorkSubmissionsComponent } from './pages/freelancer/work-submissions/work-submissions.component';
import { WhyRemotellyComponent } from './pages/stationary-pages/why-remotelly/why-remotelly.component';
import { ViewBlogDetailsComponent } from './pages/stationary-pages/blogs/view-blog-details/view-blog-details.component';
import { ProjectWorkHistoryComponent } from './pages/freelancer/project-work-history/project-work-history.component';
import { PipesModule } from './pipes/pipes.module';
import { FreelancerserviceService } from './services/freelance-service/freelancerservice.service';
import { FreelanceragencyserviceService } from './services/freelanceagency-service/freelanceragencyservice.service';
import { EmployerserviceService } from './services/employer-service/employerservice.service';
import { AdminService } from './services/admin-service/admin.service';
import { WorkdiaryComponent } from './pages/freelancer/workdiary/workdiary.component';
import { PaymentComponent } from './pages/admin-panel/payment/payment.component';
import { AllProjectsComponent } from './pages/admin-panel/projects/all-projects/all-projects.component';
import { DetailsComponent } from './pages/admin-panel/projects/details/details.component';
import { AllJobsComponent } from './pages/admin-panel/jobs/all-jobs/all-jobs.component';
import { JobDetailsComponent } from './pages/admin-panel/jobs/job-details/job-details.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    BreadcrumbsComponent,
    TitleComponent,
    AuthComponent,
    DefaultLayoutComponent,
    BackendLayoutComponent,
    PageNotFoundComponent,
    EmployerLayoutComponent,
    FreelancerLayoutComponent,
    CameraComponent,
    HomeComponent,
    ParticipantsComponent,
    RoomsComponent,
    SettingsComponent,
    DeviceSelectComponent,
    // ResponseManagerComponent,
    TwoDigitDecimaNumberDirective,
    AboutUsComponent,
    PrivacyPolicyComponent,
    WorkSubmissionsComponent,
    WhyRemotellyComponent,
    ViewBlogDetailsComponent,
    ProjectWorkHistoryComponent,
    WorkdiaryComponent,
    PaymentComponent,
    AllProjectsComponent,
    DetailsComponent,
    AllJobsComponent,
    JobDetailsComponent,
    // HomePageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    BsDropdownModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    ToasterModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    RichTextEditorAllModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ImageCropperModule,
    VideoModule,
    NgxSpinnerModule,
    AngularMyDatePickerModule,
    BsDatepickerModule.forRoot(),
    PickerModule,
    PipesModule,
    NgImageFullscreenViewModule
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    // AngularFireMessagingModule
  ],

  schemas: [NO_ERRORS_SCHEMA],
  providers: [CategoryService,
    RoleService,
    ToasterService,
    UserService,
    FreelancerserviceService,
    FreelanceragencyserviceService,
    EmployerserviceService,
    AdminService,
    QuestionService,
    PocquestionService,
    CurriculumSingleService,
    CompetitiveSingleService,
    TsupervisorService,
    GcService, DatePipe, UserDataService,
    // { provide: HTTP_INTERCEPTORS, 
    //   useClass: GlobalHttpInterceptorService, 
    //   multi: true  
    // },  
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    DeviceService, VideoChatService, StorageService, CardDetailsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
