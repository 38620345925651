import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { catchError, tap, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';


@Injectable()
export class LoaderInterceptorService implements HttpInterceptor {
    private count: number = 0;
    whitelist: any = [
        '/user/get-project-details',
        '/notification/user-notifications',
        'notification/view-countunread',
        'user/createNotes',
        'user/updatePersonalNotes',
        'user/getPersonalNotes',
        'messages/get-countunreadbyreceiver',
        'messages/change-navbar_message_count_to_zero',
        'employer/edit-job-details',
        'user/createAvailabilities',
        'employer/getPaymentActions',
        'user/appointment-details',
        'employer/transferAmountToFreelancer'
    ]
    constructor(
        public router: Router,
        private activeRouter: ActivatedRoute,
        private spinner: NgxSpinnerService
    ) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // console.log("spinner started!", req)
        //console.log("spinner started!")
        // var url = this.router.url.split('?')[0] ;
        // var splitUrl = url.split('/')
        // // var afterSplitUrl = splitUrl[1]
        // //const lastUrlSegment = this.router.url.split('?')[0].split('/').push()
        // //console.log(splitUrl)
        // if((splitUrl[1] == 'freelancer' || splitUrl[1] == 'employer') && splitUrl[2] == 'video'){
        //     return;
        // }
        // if(!req.url.includes('user/get-project-details'))`
        // console.log('current url', req.url, this.whitelist.find(v => (req.url).includes(v)))
        if (!this.whitelist.find(v => (req.url).includes(v)))
            // if(!this.whitelist.includes(req.url))
            this.spinner.show();
        this.count = this.count + 1;
        // console.log('count ++',this.count);
        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt != null) {
                        // here we are hide the loader flag
                        // console.log("spinner stopped!")
                        this.spinner.hide();
                        this.count = this.count - 1;
                        // console.log('count --',this.count);
                    }
                }
            }), catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    try {
                        // if we are getting the erorr we also have to hide the loader 
                        console.log("spinner stopped inside error!")
                        this.spinner.hide();
                    } catch (e) {
                        //console.log(e)
                    }
                    //log error 
                }
                return Observable.throw(err.statusText);
                //return throwError(err.statusText);
            }));
    }
}