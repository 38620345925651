import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../services/admin-service/admin.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-why-remotelly',
  templateUrl: './why-remotelly.component.html',
  styleUrls: ['./why-remotelly.component.scss']
})
export class WhyRemotellyComponent implements OnInit {

  constructor(
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.adminService.getWhyRemotellyContent().subscribe(data=>{
      if(data['status']){
        let whyRemotelly = data['data'];
        document.getElementById('whyRemotelly').innerHTML = whyRemotelly.content
      }
    })
  }


}
