import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../services/user.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  projectId:any;
  employerId:any;
  freelancerId:any;

  projectDetailsData:any;

  s3_url = environment.s3_url;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Project Details | Remotelly');
    this.projectId = this.route.snapshot.params['id'];
    this.employerId = this.route.snapshot.params['employerId'];
    this.freelancerId = this.route.snapshot.params['freelancerId'];
    console.log("id here:",this.projectId);
    console.log("emp here:", this.employerId);
    console.log("free here:", this.freelancerId);
    this.getProjectDetails();
  }

  getProjectDetails(){
    console.log('project call here');

    if (!this.freelancerId){
      this.freelancerId = null
    }

    let formData = {
      job_id: this.projectId,
      freelancer_id: this.freelancerId,
      employer_id: this.employerId
    }

    this.userService.getProjectPreview(formData)
    .subscribe(data => {
      if(data['status']){
        console.log(data['data']);
        this.projectDetailsData = data['data'];
      }else{
        console.log('error here', data['message']);
      }
    })
  }


}
