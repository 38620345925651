import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Signup } from '../models';
import { Users } from '../models';
import { formatDate } from '@angular/common';
import { type } from 'os';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get(environment.apiUrl + 'user/get_all');
  }

  isEmaiIDExist(email: string) {
    return this.http.get(environment.apiUrl + 'user/check_email/' + email);
  }

  //pagination and searching
  getAllPage(offset: number, page: number, params: any) {
    return this.http.get(environment.apiUrl + 'user/get_all_page/' + offset + '/' + page, { params });
  }

  signup(signup) {
    console.log('signup', signup);

    // const headers = new HttpHeaders({ // Angular's HttpHeader
    //     'Content-Type': 'multipart/form-data',
    //     'Accept': 'application/json', // some google searches suggested i drop this but it still doesnt work
    //   });
    const HttpUploadOptions = {
      //headers: new HttpHeaders({ "Accept": "application/json" })
      headers: new HttpHeaders({ 'Content-Type': '' })
    }

    //   var formData: any = new FormData();
    //   formData.append("roles", signup.register_as);
    //   //formData.append("user_avatar", signup.user_avatar);
    //   formData.append("username", signup.username);
    //   formData.append("email", signup.email);
    //   //formData.append("mobile", signup.mobile);
    //   formData.append("country", signup.country);
    //   //formData.append("website", signup.website);
    //   formData.append("password", signup.password);
    //   console.log(JSON.stringify(formData));
    signup['phonenumber'] = signup.mobile;
    signup['roles'] = signup.register_as;
    return this.http.post<any>(environment.apiUrl + 'auth/signup', signup)
  }
  verifyWhatsapp(formData) {
    return this.http.post<any>(environment.apiUrl + 'auth/verify_otp', formData);
  }
  verifyEmailotp(formData) {
    return this.http.post<any>(environment.apiUrl + 'auth/verify_email_otp', formData);
  }
  sendWhatsappOtp(formData) {
    return this.http.post<any>(environment.apiUrl + 'user/send-whatsapp-otp', formData);
  }
  userverifywhatsappotp(value){
    return this.http.post(environment.apiUrl + 'user/verify-whatsapp-otp',value)
  }
  usersendemailverificationlink(value){
    return this.http.post(environment.apiUrl + 'user/send-email-verificationlink',value)
  }
  paymentFreelancer(user_details) {
    var type = '';
    if (user_details.role_id == '3' || user_details.role_id == '11') {
      type = 'freelancer';
    } else {
      type = 'employer';
    }
    let formData = {
      "email": user_details.email,
      "name": user_details.firstname + ' ' + user_details.lastname,
      "country_code": user_details.whatsappcountry,
      "number": user_details.phonenumber,
      "freelancer_id": user_details.id,
      "employer_id": user_details.id,
      "type": type
    }
    return this.http.post<any>(environment.apiUrl + 'employer/createAsCustomerForFreelancer', formData);
  }

  verifyEmail(id: string) {
    return this.http.post<any>(environment.apiUrl + 'auth/verify_email', { id: id });
  }

  verifyCheckEmail(id: string) {
    return this.http.get(environment.apiUrl + 'user/verify_check_email/' + id);
  }
  resendEmailVerification(formData) {
    return this.http.post<any>(environment.apiUrl + 'auth/resend_verifyemail', formData);
  }
  resendEmailOtp(formData) {
    return this.http.post<any>(environment.apiUrl + 'auth/resend_email_otp', formData);
  }

  forgotWhatsapp(formObject) {
    let formData = {
      whatsappcountry: formObject.whatsappcountry,
      phonenumber: formObject.mobile
    }
    return this.http.post(environment.apiUrl + 'auth/send_otp_atlogin', formData);
  }
  validateWhatsapp(formData) {
    /* let formData = {whatsappcountry : formObject.whatsappcountry,
      phonenumber : formObject.phonenumber, otp_code: formObject.otp_code} */
    return this.http.post(environment.apiUrl + 'auth/verify_otp_atlogin', formData);
  }
  forgotEmail(formObject) {
    let formData = { email: formObject.email }
    return this.http.post(environment.apiUrl + 'auth/send_email_otp_atlogin', formData);
  }
  validateEmail(formData) {
    /* let formData = {whatsappcountry : formObject.whatsappcountry,
      phonenumber : formObject.phonenumber, otp_code: formObject.otp_code} */
    return this.http.post(environment.apiUrl + 'auth/verify_email_otp_atlogin', formData);
  }

  forgot(email: string) {
    return this.http.get(environment.apiUrl + 'user/forgot_password/' + email);
  }

  resetPassword(signup: Signup) {
    return this.http.patch(environment.apiUrl + 'user/reset_password', signup);
  }

  updateStatus(status, id:string) {
    return this.http.post(environment.apiUrl + 'user/update_status/' + id, status);
  }

  userResume(user_id, resumeData): Observable<any> {
    console.log('resume', resumeData);

    var formData: any = new FormData();
    formData.append("resume", resumeData.resume);
    formData.append("user_id", user_id);
    //   console.log(JSON.stringify(formData));
    return this.http.post<any>(environment.apiUrl + 'user/resume', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  userApplication(user_id, resumeData): Observable<any> {
    console.log('resume', resumeData);
    // var formData: any = new FormData();
    // formData.append("resume", resumeData.resume);
    resumeData["user_id"] = user_id;
    console.log(JSON.stringify(resumeData));
    return this.http.post<any>(environment.apiUrl + 'user/user_application', resumeData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  userPortfolio(user_id, portfolioData): Observable<any> {
    console.log('Portfolio', portfolioData);
    let skills = '';
    if (portfolioData.skills != '' || portfolioData.skills != null)
      skills = JSON.stringify(portfolioData.skills);
    var formData: any = new FormData();
    formData.append("project_title", portfolioData.project_title),
      formData.append("project_overview", portfolioData.project_overview),
      formData.append("contract_project", portfolioData.contract_project),
      formData.append("category", portfolioData.category),
      formData.append("sub_category", portfolioData.sub_category),
      formData.append("completion_date", portfolioData.completion_date),
      formData.append("start_date", portfolioData.start_date),
      formData.append("skills", skills),
      formData.append("thumbnails", portfolioData.thumbnails),
      formData.append("project_file", portfolioData.project_file),
      formData.append("user_id", user_id);
    console.log(JSON.stringify(portfolioData));
    return this.http.post<any>(environment.apiUrl + 'user/user_portfolio', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  userCertification(user_id, certificationData): Observable<any> {
    //console.log('userCertification', certificationData);
    // var expiration_status = false;
    // if(certificationData.expiration_date != null || certificationData.expiration_date != ''){
    //   expiration_status = false;
    // }else{
    //   expiration_status = true;
    // }
    var formData: any = new FormData();
    formData.append("certificate_type", certificationData.certificate_type),
      formData.append("certificate_name", certificationData.certificate_name),
      formData.append("provider", certificationData.provider),
      formData.append("description", certificationData.description),
      formData.append("certificate_url", certificationData.website_url),
      formData.append("expiration_date", certificationData.expiration_date),
      formData.append("certificate_upload", certificationData.certificate_file),
      formData.append("user_id", user_id),
      formData.append("no_expiration_date", certificationData.no_expiration_date)

    // console.log(JSON.stringify(certificationData));
    return this.http.post<any>(environment.apiUrl + 'user/user_certificate', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  userVideo(user_id, videoData): Observable<any> {
    console.log('videoData', videoData);
    videoData["user_id"] = user_id;
    console.log(JSON.stringify(videoData));
    return this.http.post<any>(environment.apiUrl + 'user/user_video', videoData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  userOtherExperience(user_id, experienceData): Observable<any> {
    console.log('experienceData', experienceData);
    experienceData["user_id"] = user_id;
    console.log(JSON.stringify(experienceData));
    return this.http.post<any>(environment.apiUrl + 'user/user_other_experience', experienceData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  userDetailSave(user_id, detailData): Observable<any> {
    detailData["user_id"] = user_id;
    return this.http.post<any>(environment.apiUrl + 'user/user_savedetail', detailData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  usersubCategorySave(user_id, subCategoryData): Observable<any> {
    subCategoryData["user_id"] = user_id;
    return this.http.post<any>(environment.apiUrl + 'user/user_savesubcategory', subCategoryData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  userProfile(user_id): Observable<any> {
    return this.http.get<any>(environment.apiUrl + 'user/user_profile/' + user_id, {
      reportProgress: true,
      observe: 'events'
    })
  }

  userEducationSave(user_id, educationData): Observable<any> {
    educationData["user_id"] = user_id;
    return this.http.post<any>(environment.apiUrl + 'user/user_saveeducation', educationData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  userEmploymentSave(user_id, employmentData): Observable<any> {
    employmentData["user_id"] = user_id;
    return this.http.post<any>(environment.apiUrl + 'user/user_saveemployment', employmentData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  userLanguageSave(user_id, languageData): Observable<any> {
    languageData["user_id"] = user_id;
    return this.http.post<any>(environment.apiUrl + 'user/user_savelanguage', languageData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  userAddressSave(user_id, addressData): Observable<any> {
    addressData["user_id"] = user_id;
    addressData["postal_code"] = addressData['zip'];
    return this.http.post<any>(environment.apiUrl + 'user/user_saveaddress', addressData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  employerAddressSave(user_id, addressData): Observable<any> {
    addressData["user_id"] = user_id;
    addressData["postal_code"] = addressData['zip'];
    return this.http.post<any>(environment.apiUrl + 'employer/employer_saveaddress', addressData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  userRatesSave(user_id, ratesData): Observable<any> {
    ratesData["user_id"] = user_id;
    // user/user_savehourrate -- this is old api url
    return this.http.post<any>(environment.apiUrl + 'user/user_savehourly_rate', ratesData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  userSalarySave(user_id, ratesData): Observable<any> {
    ratesData["user_id"] = user_id;
    // user/user_savehourrate -- this is old api url
    return this.http.post<any>(environment.apiUrl + 'user/user_savesalary', ratesData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  nationalitySave(user_id, nationalityData): Observable<any> {
    nationalityData["user_id"] = user_id;
    return this.http.post<any>(environment.apiUrl + 'user/user_savenationality', nationalityData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  useruserdeactivate(obj){
  return this.http.post(environment.apiUrl + 'user/user_deactivate', obj)
}
deleteuserbillingcard(deleteCardByID,obj){
  return this.http.post(environment.apiUrl + 'user/delete_user_billing_card/' + deleteCardByID, obj)
}
  userDeactivate(user_id, deactivateData): Observable<any> {
    deactivateData["user_id"] = user_id;
    return this.http.post<any>(environment.apiUrl + 'user/user_deactivate', deactivateData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  userProfileUpdate(user_id, profileData): Observable<any> {
    console.log('profile', profileData);
    // var formData: any = new FormData();
    // formData.append("title", profileData.title);
    // formData.append("user_id", user_id);
    let formData = {
      title: profileData.title,
      user_id: user_id
    }
    return this.http.post<any>(environment.apiUrl + 'user/user_updateprofile', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  userJobSave(user_id, jobData): Observable<any> {
    console.log('job', jobData);
    let formData = {
      job_type: jobData.job_type,
      service_type: jobData.service_type,
      job_name: jobData.job_name,
      description: jobData.description,
      project_type: jobData.project_type,
      project_describe: jobData.project_describe,
      work_used: jobData.work_used,
      skills: jobData.skills,
      project_lifecycle: jobData.project_lifecycle,
      number_of_freelancer: jobData.number_of_freelancer,
      user_id: user_id
    }
    //debugger;
    return this.http.post<any>(environment.apiUrl + 'user/user_postjob', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  getjobById(id: string) {
    return this.http.get(environment.apiUrl + 'user/getuserjob_id/' + id);
  }
  getjobByIdNew(id: string) {
    let formData = { job_id: id }
    return this.http.post<any>(environment.apiUrl + 'employer/get-secondary-job-details', formData);
  }
  // checkUserJobApplied(id: string, user_id: string) {
  //   let formData = { secondary_job_id: id, user_id: user_id }
  //   return this.http.post<any>(environment.apiUrl + 'user/check-user-job_applied', formData);
  // }

  // getJobseekerById(id: string) {
  //   return this.http.get(environment.apiUrl + 'user/user_by_id/' + id);
  // }


  updateJobById(id, userId, Data): Observable<any> {
    console.log('Job', Data);
    let formData = {
      job_type: Data.job_type,
      service_type: Data.service_type,
      job_name: Data.job_name,
      project_type: Data.project_type,
      description: Data.description,
      project_describe: Data.project_describe,
      work_used: Data.work_used,
      skills: Data.skills,
      project_lifecycle: Data.project_lifecycle,
      number_of_freelancer: Data.number_of_freelancer,
      id: id,
      user_id: userId
    }
    return this.http.post<any>(environment.apiUrl + 'user/update_postjob/' + id, formData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  // createmilestone(user_id, jobData): Observable<any> {
  //   console.log('job', jobData);
  //   let formData = {
  //     totalnumberofmilestone: jobData.totalnumberofmilestone,
  //     fixed_rate: jobData.fixed_rate,
  //     milestoneNumber: jobData.milestoneNumber,
  //     paypermilestone: jobData.paypermilestone,
  //     start_date: jobData.start_date,
  //     end_date: jobData.end_date,
  //     descrpition: jobData.descrpition,
  //     job_id: jobData.job_id,
  //     user_id: user_id
  //   }
  //   return this.http.post<any>(environment.apiUrl + 'user/createmilestone', formData, {
  //     reportProgress: true,
  //     observe: 'events'
  //   })
  // }

  // getmilestonebyid(id) {
  //   return this.http.get(environment.apiUrl + 'user/getmilestonebyid/' + id);
  // }

  // getUserMilestones(user_id) {
  //   return this.http.get(environment.apiUrl + 'user/getmilestone/' + user_id);
  // }

  userHoursWorked(user_id) {
    return this.http.get(environment.apiUrl + 'user/get-hours_worked/' + user_id);
  }

  // updateMilestoneById(id, userId, Data): Observable<any> {
  //   console.log('Job', Data);
  //   let formData = {
  //     totalnumberofmilestone: Data.totalnumberofmilestone,
  //     fixed_rate: Data.fixed_rate,
  //     milestoneNumber: Data.milestoneNumber,
  //     paypermilestone: Data.paypermilestone,
  //     description: Data.description,
  //     start_date: Data.start_date,
  //     end_date: Data.end_date,
  //     job_id: Data.job_id,
  //     user_id: userId
  //   }
  //   return this.http.post<any>(environment.apiUrl + 'user/update_milestone/' + id, formData, {
  //     reportProgress: true,
  //     observe: 'events'
  //   })
  // }

  // applyJob(id, user_id, file, employer_id, jobData): Observable<any> {
  //   console.log('job', jobData);

  //   var formData: any = new FormData();
  //   // formData.append("resume", jobData.resume_file);
  //   formData.append("secondary_job_id", id);
  //   formData.append("employer_id", employer_id);
  //   formData.append("user_id", user_id);
  //   formData.append("answers", JSON.stringify(jobData));
  //   // formData.append("exp_years", jobData.experience);
  //   // formData.append("designation", jobData.designation),
  //   // formData.append("mobile_number", jobData.contact_number),
  //   // formData.append("country_code",  jobData.country_code),
  //   // formData.append("country", jobData.country),
  //   // formData.append("salary_amount_min",  jobData.min_salary),
  //   // formData.append("salary_amount_max",  jobData.max_salary)

  //   return this.http.post<any>(environment.apiUrl + 'user/apply-for-secondary-job', formData, {
  //     reportProgress: true,
  //     observe: 'events'
  //   })
  // }

  submitproposal(formData) {
    return this.http.post(environment.apiUrl + 'user/apply_job', formData);
  }

  // submitwork(formData) {
  //   return this.http.post(environment.apiUrl + 'user/submit_work', formData);
  // }

  updateproposal(id, Data): Observable<any> {
    console.log('Job', Data);
    return this.http.patch(environment.apiUrl + 'user/edit-proposal/' + id, Data);
  }

  // getAllAppliedJobs(user_id) {
  //   return this.http.get(environment.apiUrl + 'user/getallappliedjobs/' + user_id);
  // }

  userProfileSave(user_id, profileData): Observable<any> {
    /* let service_id = null;
    if(profileData.service_id == undefined || profileData.service_id ==''){
      service_id = null
    }else{
      service_id = profileData.service_id
    } */
    console.log('profile', profileData);
    var formData: any = new FormData();
    formData.append("profile_image", profileData.profile_image);
    formData.append("title", profileData.title);
    formData.append("prof_overview", profileData.prof_overview);
    formData.append("user_id", user_id);
    formData.append("service_id", profileData.service_id);
    formData.append("services", JSON.stringify(profileData.services));
    formData.append("skills", JSON.stringify(profileData.skills));
    formData.append("experience", profileData.experience);
    formData.append("gender", profileData.gender),
      formData.append("functional_area", profileData.functional_area),
      formData.append("industry_id", profileData.industry_id),
      formData.append("birth_date", profileData.birth_date),
      formData.append("visa_status", profileData.visa_status),
      formData.append("driving_license", profileData.driving_license)
    return this.http.post<any>(environment.apiUrl + 'user/user_saveprofile', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  agencyProfileSave(user_id, profileData): Observable<any> {
    console.log('profile', profileData);
    var formData: any = new FormData();
    formData.append("profile_image", profileData.profile_image);
    formData.append("title", profileData.title);
    formData.append("prof_overview", profileData.prof_overview);
    formData.append("user_id", user_id);

    formData.append("services", JSON.stringify(profileData.services));
    formData.append("experience", profileData.experience);

    return this.http.post<any>(environment.apiUrl + 'user/agency_saveprofile', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  employerProfileSave(user_id, profileData, employerType): Observable<any> {
    console.log('profile', profileData);
    var formData: any = new FormData();
    formData.append("profile_image", profileData.profile_image);
    formData.append("name", profileData.name);
    formData.append("website_url", profileData.website_url);
    formData.append("user_id", user_id);
    formData.append("employer_email", profileData.employer_email);
    formData.append("country_code", profileData.country_code);
    formData.append("company_number", profileData.company_number);
    formData.append("employer_overview", profileData.prof_overview);
    formData.append("employer_type", Number(employerType))
    return this.http.post<any>(environment.apiUrl + 'employer/employer_saveprofile', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  employerProfileUpdate(user_id, profileData, employerType): Observable<any> {
    console.log('profile', profileData);
    var formData: any = new FormData();
    formData.append("profile_image", profileData.profile_image);
    formData.append("name", profileData.name);
    formData.append("website_url", profileData.website_url);
    formData.append("user_id", user_id);
    formData.append("employer_email", profileData.employer_email);
    formData.append("country_code", profileData.country_code);
    formData.append("company_number", profileData.company_number);
    formData.append("employer_overview", profileData.prof_overview);
    formData.append("country_name", profileData.country_name);
    
    // formData.append("employer_type", Number(employerType))
    return this.http.post<any>(environment.apiUrl + 'employer/update_employer_profile', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  employerBannerSave(user_id, profileData): Observable<any> {
    console.log('profile', profileData);
    var formData: any = new FormData();
    formData.append("banner", profileData.profile_image);
    formData.append("user_id", user_id);
    return this.http.post<any>(environment.apiUrl + 'employer/employer_savebanner', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  experienceLevelSave(user_id, experienceLevelData): Observable<any> {
    console.log('experienceLevel', experienceLevelData);
    experienceLevelData["user_id"] = user_id;
    // var formData: any = new FormData();
    // formData.append("user_id", user_id);
    // formData.append("experience_level", experienceLevelData.expLevel);
    return this.http.post<any>(environment.apiUrl + 'user/user_saveexperience_level', experienceLevelData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  employerTypeSave(user_id, employerTypeData): Observable<any> {
    console.log('employerType', employerTypeData);
    employerTypeData["user_id"] = user_id;
    // var formData: any = new FormData();
    // formData.append("user_id", user_id);
    // formData.append("experience_level", employerTypeData.expLevel);
    return this.http.post<any>(environment.apiUrl + 'employer/user_saveemployer_type', employerTypeData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  /*************** */
  addUser(user: Users) {
    console.log('user', user);
    return this.http.post<any>(environment.apiUrl + 'auth/add_user', { firstname: user.firstname, password: user.password, confirm_password: user.confirm_password, lastname: user.lastname, roles: user.register_as, email: user.email, whatsapp: user.whatsapp, country: user.country }
    )
  }

  // addUser(signup: Signup)
  // {
  //   return this.http.post(environment.apiUrl+'user/add_user', signup);
  // }

  userById(id: string) {
    return this.http.get(environment.apiUrl + 'user/user_by_id/' + id);
  }
  masterdatatimezoneweb(){
  return this.http.get(environment.apiUrl + 'masterdata/timezone/web')
}
  generatePassword(signup: Signup) {
    return this.http.patch(environment.apiUrl + 'user/generate_password', signup);
  }

  changePassword(signup: Signup) {
    console.log(signup);
    return this.http.patch(environment.apiUrl + 'user/change_password', signup);
  }

  updateUserById(user: Users, id) {
    return this.http.patch(environment.apiUrl + 'user/update_user_by_id/' + id, user);
  }

  updateUser(user: Signup, id) {
    return this.http.patch(environment.apiUrl + 'user/update_user/' + id, user);
  }
  updateUser1(user, id) {
    return this.http.patch(environment.apiUrl + 'user/update_user/' + id, user);
  }
  delete(id: number) {
    //return this.http.delete(environment.apiUrl+'user/delete/' + id);
  }

  getCountry() {
    return this.http.get(environment.apiUrl + 'masterdata/get_all_country');
  }


  getRole() {
    return this.http.get(environment.apiUrl + 'auth/roles');
  }

  getRequiredRoles() {
    return this.http.get(environment.apiUrl + 'auth/get-roles1');
  }


  changeUserPassword(signup: Signup) {
    return this.http.post(environment.apiUrl + 'user/change_password_1', signup);
  }






  // getAgencyTeamList(formData) {
  //   return this.http.post(environment.apiUrl + 'freelancer/get-agency-teamlist', formData);
  // }

  // getAllAgencyProjects(formData) {
  //   return this.http.post(environment.apiUrl + 'freelancer/getAll-agency-project', formData);
  // }

  setNoticationSettings(formData) {
    return this.http.post(environment.apiUrl + 'user/set_notifcation_settings', formData);
  }
  usergetuserbillingcard(cardID){
  return this.http.get(environment.apiUrl + 'user/get_user_billing_card/' + cardID)
}
  getNoticationSettings(formData) {
    return this.http.post(environment.apiUrl + 'user/get_notifcation_settings', formData);
  }
  usergetsettings(obj){
  return this.http.post(environment.apiUrl + 'user/get_settings', obj)
}
usersavesettings(data){
  return  this.http.post(environment.apiUrl + 'user/save_settings', data)
}
  savebankDetails(formData) {
    return this.http.post(environment.apiUrl + 'user/save_bank_details', formData);
  }
  getuserbankdetails(id){
    return this.http.get(environment.apiUrl + 'user/get_user_bank_details/' + id)
  }
  updatebankdetails(formData){
    return this.http.post(environment.apiUrl + 'user/update_bank_details', formData)
  }
  deleteuserbankdetails(bankID,obj){
    return this.http.post(environment.apiUrl + 'user/delete_user_bank_details/' + bankID, obj)
  }
  getuserallbankdetails(user_id){
    return this.http.get(environment.apiUrl + 'user/get_user_allbank_details/' + user_id + '/' + 10)
  }
  sendInviteEmails(formData) {
    return this.http.post(environment.apiUrl + 'user/send_invite_email', formData);
  }

  // getPortfolioDetails(formData) {
  //   return this.http.post(environment.apiUrl + 'user/get-portfolio', formData);
  // }

  // editPortfolioDetails(formData) {
  //   return this.http.post(environment.apiUrl + 'user/edit-portfolio', formData);
  // }

  // getOtherExperience(formData) {
  //   return this.http.post(environment.apiUrl + 'user/get-other-experience', formData);
  // }

  // editOtherExperience(formData) {
  //   return this.http.post(environment.apiUrl + 'user/edit-other-experience', formData);
  // }

  // getCertificateDetails(formData) {
  //   return this.http.post(environment.apiUrl + 'user/get-certificate', formData);
  // }

  // editCertificateDetails(formData) {
  //   return this.http.post(environment.apiUrl + 'user/edit-certificate', formData);
  // }

  getAllServices() {
    return this.http.get(environment.apiUrl + 'masterdata/get_all_services');
  }
  getAllWorks() {
    return this.http.get(environment.apiUrl + 'masterdata/get_all_work');
  }
  getAllProjectType() {
    return this.http.get(environment.apiUrl + 'masterdata/get_all_project_type');
  }
  getAllProjectdescribe() {
    return this.http.get(environment.apiUrl + 'masterdata/get_all_project_describe');
  }
  getAllJobType() {
    return this.http.get(environment.apiUrl + 'masterdata/get_all_job_type');
  }
  getAllProjectLifecycle() {
    return this.http.get(environment.apiUrl + 'masterdata/get_all_project_lifecycle');
  }

  deleteUserPortfolio(formData) {
    return this.http.post(environment.apiUrl + 'user/remove-portfolio', formData);
  }

  deleteUserCertification(formData) {
    return this.http.post(environment.apiUrl + 'user/remove-certificate', formData);
  }

  deleteUserEmploymentHistory(formData) {
    return this.http.post(environment.apiUrl + 'user/remove-employment-history', formData);
  }

  deleteUserOtherExperience(formData) {
    return this.http.post(environment.apiUrl + 'user/remove-other-experience', formData);
  }

  getEmploymentDetails(formData) {
    return this.http.post(environment.apiUrl + 'user/get-employment-details', formData);
  }

  editEmploymentDetails(id, employmentData) {
    employmentData['id'] = id;
    return this.http.patch(environment.apiUrl + 'user/edit-employment-details', employmentData);
  }

  editTitleAndOverview(id, data) {
    data['id'] = id;
    return this.http.patch(environment.apiUrl + 'user/edit-title-overview', data);
  }

  editAvailabilityAndLocation(id, data) {
    data['id'] = id;
    return this.http.patch(environment.apiUrl + 'user/edit-availability-location', data);
  }

  editRateAndContact(id, data) {
    data['id'] = id;
    return this.http.patch(environment.apiUrl + 'user/edit-rate-contact', data);
  } getAllEducation
    (userId) {
    return this.http.get(environment.apiUrl + 'user/getAll-education_details/' + userId);
  }
  getAllEmployment(userId) {
    return this.http.get(environment.apiUrl + 'user/getAll-employment_details/' + userId);
  }
  getAllPortfolioDetails(userId) {
    return this.http.get(environment.apiUrl + 'user/getAll-portfolio_details/' + userId);
  }
  getAllSettingDetails(userId) {
    return this.http.get(environment.apiUrl + 'user/get-setting_details/' + userId);
  }
  getAllAdditionalDetails(userId) {
    return this.http.get(environment.apiUrl + 'user/getAll-additional_details/' + userId);
  }

  getEducationDetails(formData) {
    return this.http.post(environment.apiUrl + 'user/get-education-details', formData);
  }
  getproposalById(id: string) {
    return this.http.get(environment.apiUrl + 'user/getproposalById/' + id);
  }

  editEducationDetails(id, data) {
    data['id'] = id;
    return this.http.patch(environment.apiUrl + 'user/edit-education-details', data);
  }

  deleteUserEducation(formData) {
    return this.http.post(environment.apiUrl + 'user/remove-education-details', formData);
  }

  addUserLanguage(id, formData) {
    formData['user_id'] = id;
    return this.http.post(environment.apiUrl + 'user/add-language', formData);
  }

  deleteUserLanguage(formData) {
    return this.http.post(environment.apiUrl + 'user/remove-language', formData);
  }

  getUserProfileProgress(formData) {
    return this.http.post(environment.apiUrl + 'user/get-progress', formData);
  }

  getUserLanguageDetails(formData) {
    return this.http.post(environment.apiUrl + 'user/get-language-details', formData);
  }

  editUserLanguageDetails(id, formData) {
    formData['id'] = id;
    return this.http.post(environment.apiUrl + 'user/edit-language-details', formData);
  }

  updateProfilePic(user_id, profileData): Observable<any> {
    console.log('profile', profileData);
    var formData: any = new FormData();
    formData.append("profile_image", profileData.profile_image);
    formData.append("user_id", user_id);
    return this.http.post<any>(environment.apiUrl + 'user/change-profile-picture', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  companyUserSignup(signup, role, linked_user_id) {
    const HttpUploadOptions = {
      headers: new HttpHeaders({ 'Content-Type': '' })
    }
    return this.http.post<any>(environment.apiUrl + 'auth/coumpanyuser/signup', { username: signup.username, password: signup.password, roles: role, email: signup.email, country: signup.country, whatsapp: signup.whatsapp, whatsappcountry: signup.whatsappcountry, linked_user_id: linked_user_id }
    )
  }

  getUserRole(formData) {
    return this.http.post(environment.apiUrl + 'user/get-role', formData);
  }

  searchCv(formData, languages) {
    formData['languages'] = languages;
    return this.http.post(environment.apiUrl + 'user/search-cv', formData);
  }

  //pagination and searching
  getSearchedProject(params: any) {
    return this.http.post(environment.apiUrl + 'user/get_searchedprojects', params);
  }

  searchproject(formData) {
    return this.http.post(environment.apiUrl + 'user/search-project', formData);
  }

  withdrawproposal(formData) {
    return this.http.post(environment.apiUrl + 'user/withdraw-proposal', formData)
  }

  getSavedJobs(userId) {
    let formData = {
      user_id: userId
    }
    return this.http.post<any>(environment.apiUrl + 'user/get-saved-secondary-jobs', formData);
  }

  saveJob(id, userID): Observable<any> {
    let formData = {
      secondary_job_id: id,
      user_id: userID
    }
    return this.http.post(environment.apiUrl + 'user/save-secondary-job', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }
  unsaveJob(formData) {
    return this.http.post(environment.apiUrl + 'user/unsave-secondary-job', formData)
  }

  editUserSkills(skills, user_id) {
    let formData = {
      user_id,

      skills
    }
    return this.http.post(environment.apiUrl + 'user/edit-skills', formData);
  }

  getAllUserJobs() {
    return this.http.get(environment.apiUrl + 'user/get-jobs-list');
  }

  getbestmatchjobsdata(userId) {
    return this.http.get(environment.apiUrl + 'user/get-best-jobs/' + userId);
  }

  getskillbasedjobsdata(userId) {
    return this.http.get(environment.apiUrl + 'user/get-job-suggestions/' + userId);
  }

  // getUserJobs(user_id) {
  //   return this.http.get(environment.apiUrl + 'user/getjobs/' + user_id);
  // }

  // deleteJob(formData) {
  //   return this.http.post(environment.apiUrl + 'user/remove-postjob', formData);
  // }

  deleteSecondaryJob(formData) {
    return this.http.post(environment.apiUrl + 'user/remove-secondary-job', formData);
  }

  employerProfile(user_id): Observable<any> {
    // user/employer_profile
    return this.http.get<any>(environment.apiUrl + 'user/employer_profile/' + user_id, {
      reportProgress: true,
      observe: 'events'
    })
  }
  employerJobDetails(employer_id): Observable<any> {
    let formData = { employer_id: employer_id }
    return this.http.post<any>(environment.apiUrl + 'employer/get-employer-secondary-job-list', formData, {
      reportProgress: true,
      observe: 'events'
    })
  }

  getEmployerProfileProgress(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-progress', formData);
  }



  startConversation(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/start-conversation', formData);
  }

  getAllConversations(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/get-conversation', formData);
  }
  getOneConversations(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/get-one-conversation', formData);
  }

  getAllFreelancerConversations(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/get-conversation-freelancer', formData);
  }

  getAllEmployerConversations(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/get-conversation-employer', formData);
  }

  sendMessage(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/send', formData);
  }

  sendMessageWithAttachment(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/send-attachment', formData);
  }

  getConversationMessages(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/get-messages-for-conversation', formData);
  }

  // getAllJobProposals(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/get-proposals', formData);
  // }

  // hireFreelancer(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/hire-freelancer', formData);
  // }

  // getAllHires(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/get-hire-list', formData);
  // }

  getProposalDetails(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-proposal-details', formData);
  }

  postJob(formData) {
    return this.http.post(environment.apiUrl + 'employer/post-job', formData);
  }

  postJobAndRemoveDraft(formData) {
    return this.http.post(environment.apiUrl + 'employer/post-job-remove-draft', formData);
  }

  postDraftJob(formData) {
    return this.http.post(environment.apiUrl + 'employer/draft-job', formData);
  }

  addJobQuestions(formData) {
    return this.http.post(environment.apiUrl + 'employer/post-job-questions', formData);
  }

  addJobPreference(formData, job_id) {
    formData['job_id'] = job_id;
    return this.http.post(environment.apiUrl + 'employer/post-job-preference', formData);
  }

  // getAllJobs(formData){
  //   return this.http.post(environment.apiUrl+'employer/get-jobs',formData);
  // }

  // getAllDrafts(formData){
  //   return this.http.post(environment.apiUrl+'employer/get-drafts',formData);
  // }

  getAllDraftsProjectJobs(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-drafts-jobs-projects', formData);
  }

  viewProposal(formData) {
    return this.http.post(environment.apiUrl + 'user/view-all-proposals', formData);
  }
  // viewArchiveProposal(formData) {
  //   return this.http.post(environment.apiUrl + 'user/view-archived-proposals', formData);
  // }
  // ArchiveProposal(formData) {
  //   return this.http.post(environment.apiUrl + 'user/archive-proposal', formData);
  // }
  // ArchiveInterview(formData) {
  //   return this.http.post(environment.apiUrl + 'user/archive-interview', formData);
  // }

  getDislikedJobsList(formData) {
    return this.http.post(environment.apiUrl + 'user/jobs/get-disliked', formData);
  }

  dislikeJob(formData) {
    return this.http.post(environment.apiUrl + 'user/jobs/dislike', formData);
  }

  getJobDetails(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-job-details', formData);
  }

  editJobDetails(formData) {
    return this.http.post(environment.apiUrl + 'employer/edit-job-details', formData);
  }

  saveFreelancerProfile(formData) {
    return this.http.post(environment.apiUrl + 'employer/save-profile', formData);
  }

  getSavedFreelancerProfiles(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-profiles', formData);
  }

  getPostedJobDetails(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-job-details', formData);
  }
  // getFreelancerList(jobid) {
  //   return this.http.get(environment.apiUrl + 'employer/get-freelancer-recommendation/' + jobid);
  // }

  // getReviewProposalslist(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/get-job-proposals', formData);
  // }

  // searchFreelancerToInvite(params) {
  //   return this.http.get(environment.apiUrl + 'employer/search-freelancer', { params });
  // }

  // savefreelancerprofle(id, userID, jobId): Observable<any> {
  //   let formData = {
  //     freelancer_id: id,
  //     employer_id: userID,
  //     job_id: jobId
  //   }
  //   return this.http.post(environment.apiUrl + 'employer/save-freelancer', formData)
  // }


  // sendJobInviteToFreelancer(id, userID, jobid, data): Observable<any> {
  //   let formData = {
  //     freelancer_id: id,
  //     employer_id: userID,
  //     job_id: jobid,
  //     message: data.message
  //   }
  //   return this.http.post(environment.apiUrl + 'employer/invite-freelancer', formData, {
  //     reportProgress: true,
  //     observe: 'events'
  //   })
  // }

  // removeFromSavedFreelancer(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/remove-saved-freelancer', formData)
  // }

  // getSavedFreelancerList(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/get-saved-freelancers', formData);
  // }
  // getFreelancerListById(id) {
  //   return this.http.get(environment.apiUrl + 'user/user_by_id/' + id);
  // }

  // getActiveInvitedFreelancersList(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/view-sent-active-invites', formData);
  // }

  // getDataForPostJob(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/get-data-for-postjob', formData);
  // }

  // getContractListById(formData) {
  //   return this.http.post(environment.apiUrl + 'user/get-contractById/', formData);
  // }

  postSecondaryJob(formData, emp_id, job_location, nationality, current_location, education, questions) {
    formData['employer_id'] = emp_id;
    formData['job_locations'] = job_location;
    formData['nationalities'] = nationality;
    formData['current_location'] = current_location;
    formData['education'] = education;
    formData['questions'] = questions;
    return this.http.post(environment.apiUrl + 'employer/post-secondary-job', formData);
  }

  saveSecondaryJobAsDraft(formData, emp_id, job_location, nationality, current_location, education, questions) {
    formData['employer_id'] = emp_id;
    formData['job_locations'] = job_location;
    formData['nationalities'] = nationality;
    formData['current_location'] = current_location;
    formData['education'] = education;
    formData['questions'] = questions;
    return this.http.post(environment.apiUrl + 'employer/draft-secondary-job', formData);
  }

  saveSecondaryJobFromDraft(formData, emp_id, job_id, job_location, nationality, current_location, education, questions) {
    formData['employer_id'] = emp_id;
    formData['job_id'] = job_id;
    formData['job_locations'] = job_location;
    formData['nationalities'] = nationality;
    formData['current_location'] = current_location;
    formData['education'] = education;
    formData['questions'] = questions;
    return this.http.post(environment.apiUrl + 'employer/post-secondary-job-from-draft', formData);
  }

  getSecondaryJobDetails(formData) {
    return this.http.post(environment.apiUrl + 'employer/get-secondary-job-details', formData);
  }

  editSecondaryJobDetails(formData, emp_id, job_id, job_location, nationality, current_location, education, questions) {
    formData['employer_id'] = emp_id;
    formData['job_id'] = job_id;
    formData['job_locations'] = job_location;
    formData['nationalities'] = nationality;
    formData['current_location'] = current_location;
    formData['education'] = education;
    formData['questions'] = questions;
    return this.http.post(environment.apiUrl + 'employer/edit-secondary-job-details', formData);
  }

  // finalHireFreelancer(formData, milestone_list, job_id, freelancer_id, employer_id) {
  //   formData['milestones_list'] = milestone_list;
  //   formData['job_id'] = job_id;
  //   formData['freelancer_id'] = freelancer_id;
  //   formData['employer_id'] = employer_id;
  //   return this.http.post(environment.apiUrl + 'employer/hire-freelancer-final', formData);
  // }

  // getJobAndUserDetails(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/get-project-user-details', formData);
  // }

  // getHiresForAJob(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/get-hires-for-job', formData);
  // }

  // getJobStats(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/get-job-stats', formData);
  // }

  getActiveContracts(formData) {
    return this.http.post(environment.apiUrl + 'user/getactivecontracts', formData);
  }




  // applyForSecondaryJob(formValues, job_id, freelancer_id, employer_id) {
  //   let formData = new FormData();
  //   formData.append("secondary_job_id", job_id);
  //   formData.append("freelancer_id", freelancer_id);
  //   formData.append("employer_id", employer_id);
  //   formData.append("file", formValues['file']);
  //   formData.append("exp_years", formValues['exp_years']);
  //   formData.append("exp_months", formValues['exp_months']);
  //   formData.append("designation", formValues['designation']);
  //   formData.append("salary_currency", formValues['salary_currency']);
  //   formData.append("salary_amount", formValues['salary_amount']);
  //   formData.append("country", formValues['country']);
  //   formData.append("country_code", formValues['country_code']);
  //   formData.append("skills", formValues['skills']);
  //   return this.http.post(environment.apiUrl + 'user/apply-for-secondary-job', formData);
  // }

  toggleProjectStatus(formData) {
    return this.http.post(environment.apiUrl + 'employer/toggle-project-status', formData);
  }

  getAllContracts(formData) {
    return this.http.post(environment.apiUrl + 'user/getallcontracts', formData);
  }

  // getAllOffers(formData) {
  //   return this.http.post(environment.apiUrl + 'user/get-latest-offer', formData);
  // }

  getMilestoneById(formData) {
    return this.http.post(environment.apiUrl + 'user/get-milestoneById', formData);
  }

  /*  acceptOffers(id, freelancer_id,employer_id, job_id)  : Observable<any> {
    let formData = {
      final_hire_id : id,
      freelancer_id : freelancer_id,
      employer_id : employer_id,
      job_id : job_id,
      
    }
    return this.http.post<any>(environment.apiUrl+'user/adduserContract',  formData, {
      reportProgress: true,
      observe: 'events'
    } )
   }*/

  // acceptOffers(final_hire_id, freelancer_id, employer_id, job_id) {
  //   debugger
  //   let formData = {
  //     final_hire_id: final_hire_id,
  //     freelancer_id: freelancer_id,
  //     employer_id: employer_id,
  //     job_id: job_id,

  //   }
  //   return this.http.post(environment.apiUrl + 'user/adduserContract', formData);
  // }

  // declineoffer(formData) {
  //   return this.http.post(environment.apiUrl + 'user/declineOffer', formData);
  // }

  saveSecondaryJob(formData) {
    return this.http.post(environment.apiUrl + 'user/save-secondary-job', formData);
  }

  unsaveSecondaryJob(formData) {
    return this.http.post(environment.apiUrl + 'user/unsave-secondary-job', formData);
  }

  getSavedAndDislikedSecondaryJobs(formData) {
    return this.http.post(environment.apiUrl + 'user/get-saved-disliked-secondary-jobs', formData);
  }

  dislikeSecondaryJob(formData) {
    return this.http.post(environment.apiUrl + 'user/dislike-secondary-job', formData);
  }

  removeSecondaryJobFromDislike(formData) {
    return this.http.post(environment.apiUrl + 'user/remove-secondary-job-from-dislike', formData);
  }

  // getContractMilestones(formData) {
  //   return this.http.post(environment.apiUrl + 'user/get-contract-milestones', formData);
  // }

  // getOriginalproposal(formData) {
  //   return this.http.post(environment.apiUrl + 'user/get-Originalproposal', formData);
  // }

  endContract(formData) {
    return this.http.post(environment.apiUrl + 'user/end-contract', formData);
  }

  // proposeNewContract(formData) {
  //   return this.http.post(environment.apiUrl + 'user/send-new-contract', formData);
  // }
  getSavedSecondaryJobs(formData) {
    return this.http.post(environment.apiUrl + 'user/get-saved-secondary-jobs', formData);
  }
  getAppliedSecondaryJobs(formData) {
    return this.http.post(environment.apiUrl + 'user/get-applied-secondary-jobs', formData);
  }

  // getEmployerContracts(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/get-all-contracts', formData);
  // }
  // getPendingOffers(formData) {
  //   return this.http.post(environment.apiUrl + 'user/get-pending-offer', formData);
  // }
  // getMessageProposalsList(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/get-job-messaged-proposals', formData);
  // }

  // postEmployerProjectDetails(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/post-job', formData);
  // }

  // addProjectQuestions(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/post-job-questions', formData);
  // }

  // addProjectPreferences(formData) {
  //   return this.http.post(environment.apiUrl + 'employer/post-job-preference', formData);
  // }

  getProjectPreview(formData) {
    return this.http.post(environment.apiUrl + 'freelancer/get-project-details', formData);
  }
  getAllCompany() {
    return this.http.get(environment.apiUrl + 'employer/get_all_company');
  }
  getAllJob(user_id) {
    return this.http.get(environment.apiUrl + 'user/get-secondary-job-suggestions/' + user_id, {
      reportProgress: true,
      observe: 'events'
    });
  }
  getUserSkills(user_id) {
    return this.http.get(environment.apiUrl + 'user/get-user_skills/' + user_id);
  }

  // getProjectDetails(){
  //   return this.projectDetails
  // }

  // setProjectDetails(val){
  //   this.projectDetails = val;
  // }

  getSearchJob(objRequest) {
    return this.http.post(environment.apiUrl + 'user/search', objRequest);
  }
  getFilterJobs(objRequest) {
    return this.http.post(environment.apiUrl + 'user/filterJobs', objRequest);
  }



  contactUs(formData) {
    return this.http.post(environment.apiUrl + 'user/add_contact_us_details', formData);
  }

  // getAllAppointmentInvitation(formData) {
  //   return this.http.post(environment.apiUrl + 'user/getFreelancerAllInvitaionAppointmentRoom', formData);
  // }

  // acceptVideoCallRequest(formData) {
  //   return this.http.post(environment.apiUrl + 'user/acceptVideocallRequest', formData);
  // }

  refuseVideoCallRequest(formData) {
    return this.http.post(environment.apiUrl + 'user/refuseVideocallRequest', formData);
  }

  acceptMessage(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/acceptinvite', formData);
  }

  refuseMessage(formData) {
    return this.http.post(environment.apiUrl + 'user/messages/declineinvite', formData);
  }

  getNotes(formData) {
    return this.http.post(environment.apiUrl + 'user/getPersonalNotes', formData);
  }

  createNotes(formData) {
    return this.http.post(environment.apiUrl + 'user/createNotes', formData);
  }

  updateNotes(formData) {
    return this.http.post(environment.apiUrl + 'user/updatePersonalNotes', formData);
  }

  getTopCountryJobs() {
    return this.http.get(environment.apiUrl + 'homepage/get-top-countrybyjob');
  }
  getTopSkilJobs() {
    return this.http.get(environment.apiUrl + 'homepage/get-top-skillsbyjob');
  }
  getFreelancerListJobs() {
    return this.http.get(environment.apiUrl + 'homepage/get-freelancerlist-byproject');
  }

  createInstruments(formData) {
    return this.http.post(environment.apiUrl + 'employer/createInstruments', formData);
  }

  refundPayment(formData) {
    return this.http.post(environment.apiUrl + 'employer/refundPayment', formData);
  }


  getUserCardDetails(userId) {
    return this.http.get(environment.apiUrl + 'user/get_user_allbilling_card/' + userId + '/' + 100);
  }
  competitionapiupload(formData){
    return this.http.post(environment.apiUrl+'competition/api/upload', formData)
  }
  SubscribeEmail(formData) {
    return this.http.post(environment.apiUrl + 'user/add-subscribed-user', formData);
  }
  getTopSearchJob(formData) {
    return this.http.post(environment.apiUrl + 'jobseeker/gettopdata', formData);
  }
  getHeaderJobSearch(formData) {
    return this.http.post(environment.apiUrl + 'jobseeker/header-job-search', formData);
  }
  saveDeviceToken(formData) {
    return this.http.post(environment.apiUrl + 'user/update-deviceToken', formData);
  }
  getUserNotification(userId) {
    return this.http.get(environment.apiUrl + 'notification/user-notifications/' + userId);
  }
  getUserNotificationCount(userId) {
    return this.http.get(environment.apiUrl + 'notification/view-countunread/' + userId);
  }
  markAllNotification(userId) {
    return this.http.get(environment.apiUrl + 'notification/markAllRead/' + userId);
  }

  getCountUnreadByUser(userId) {
    return this.http.get(environment.apiUrl + 'messages/get-countunreadbyreceiver/' + userId);
  }

  changeMessageCountToZero(formData) {
    return this.http.post(environment.apiUrl + 'messages/change-navbar_message_count_to_zero', formData);
  }

  markAllAsRead(formData) {
    return this.http.post(environment.apiUrl + 'messages/markall_messages_as_read', formData);
  }

  changeDisputeStatus(formData) {
    return this.http.post(environment.apiUrl + 'user/change_dispute_status', formData);
  }

  // rejectSubmittedWork(formData) {
  //   return this.http.post(environment.apiUrl + 'user/reject_submit_work', formData);
  // }
  getUserId(profile_id: string) {
    return this.http.post<any>(environment.apiUrl + 'user/get-user_details_by_profileId', { profile_id: profile_id });
  }

  // informatic pages api's

  // informatic pages api's end

  // getPaymentActions(paymentId){
  //   return this.http.post(environment.apiUrl + 'employer/getPaymentActions', { paymentId: paymentId });
  // }

  // submitWorkStatus(formData){
  //   return this.http.post(environment.apiUrl + 'user/submit_work_status', formData);
  // }

  // transferAmountToFreelancer(formData){
  //   return this.http.post(environment.apiUrl + 'employer/transferAmountToFreelancer', formData);
  // }
  // getTotalTimeData(data:any){
  //   return this.http.post(environment.apiUrl + 'user/get-total-submit-work-time', data)
  // }
  getuserfeedbackslist(){
  return this.http.get(environment.apiUrl + 'user/get_user_feedbacks_list');
}
getallusercount(){
  return this.http.get(environment.apiUrl + 'user/get-all-user_count');
}
savebillingcard(value){
  return  this.http.post(environment.apiUrl + 'user/save_billing_card', value)
}
updatebillingcard(formData){
  return this.http.post(environment.apiUrl + 'user/update_billing_card', formData);
}
employerdeleteInstrument(data){
  return this.http.post(environment.apiUrl + 'employer/deleteInstrument',data)
}

userappointmentdetails(obj){
  return this.http.post(environment.apiUrl+ 'user/appointment-details',obj)
}
notificationdeleteall(id){
  return this.http.get(environment.apiUrl + 'notification/delete-all/' + id)
}

statusChange(id:string, formData:Object){
  return this.http.patch(environment.apiUrl + "user/update_user/" + id, formData);
}

getProjectScreenShots(formData:object){
  return this.http.post(environment.apiUrl + 'user/activity/get-project-screenshots', formData)
}

getTotalProjectWorkingTime(formData:object){
  return this.http.post(environment.apiUrl + "user/get-total-project-working-time", formData);
}

acceptRescheduledVideocallRequest(formData:object){
  return this.http.post(environment.apiUrl + 'user/acceptRescheduledVideocallRequest', formData);
} 

refuseRescheduledVideocallRequest(formData: object) {
  return this.http.post(environment.apiUrl + 'user/refuseRescheduledVideocallRequest', formData);
}

getAllProjects(type:string){
  return this.http.get(environment.apiUrl + 'admin/projects/get-all-projects?type='+''+type);
}

getAllJobs(type: string) {
  return this.http.get(environment.apiUrl + 'admin/jobs/get-all-jobs?type=' + '' + type);
}

}
