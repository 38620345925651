import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Signup } from '../../models';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class FreelanceragencyserviceService {

  constructor(private http: HttpClient) { }
  acceptteaminvitation(formData){
   return this.http.post(environment.apiUrl + 'freelancer/accept-team-invitation', formData)
  }
  sendteaminvitaion(value){
    return this.http.post(environment.apiUrl + 'freelancer/send-team-invitaion',value);
  }
  getAllAgencyProjects(formData) {
    return this.http.post(environment.apiUrl + 'freelancer/getAll-agency-project', formData);
  }
  getAgencyTeamList(formData) {
    return this.http.post(environment.apiUrl + 'freelancer/get-agency-teamlist', formData);
  }
  searchfreelancertoinvite1(value){
    return this.http.post(environment.apiUrl + 'freelancer/search-freelancer-toinvite',value)
  }
  getagencyinvitationlist(data){
    return this.http.post(environment.apiUrl + 'freelancer/get-agency-invitationlist',data)
  }
  deletefreelancerfromteam(data){
    return this.http.post(environment.apiUrl + 'freelancer/delete-freelancer-fromteam', data)
  }
  masterdatacountriesweb(){
   return this.http.get(environment.apiUrl + 'masterdata/countries/web')
  }
}
