import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { AdminService } from '../../../services/admin-service/admin.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  serviceCost:number;
  updateServiceCharge =false;
  extraCost: number;
  updateExtraCost = false;

  constructor(
    private adminService: AdminService,
    private toaster: ToasterService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.getServiceCost();
    this.titleService.setTitle('Payment | Remotelly');
  }

  getServiceCost(){
    this.adminService.getServiceCost().subscribe(data => {
      if(data['status']){
        this.serviceCost = data['data']['service_charge'];
        this.extraCost = data['data']['extra_cost'];
        console.log('service charge', this.serviceCost);
      }
    })
  }

  updateServiceCost(){
    this.updateServiceCharge = true;
    this.updateExtraCost = true;
    if (this.serviceCost <= 0){
      this.toaster.pop('error', 'Service charge should be greater than zero!...');
      this.updateServiceCharge = false;
      return;
    }
    let formData ={
      id: 1,
      service_charge: this.serviceCost,
      extra_cost: this.extraCost
    }
    console.log('data for update',formData);
    this.adminService.updateServiceCost(formData).subscribe(data => {
      if(data['status']){
        this.toaster.pop("success", data['message']);
        this.updateServiceCharge = false;
        this.updateExtraCost = false;
        this.getServiceCost();

      }else{
        this.toaster.pop("error", data['message']);
        this.updateServiceCharge = false;
      }
    })
  }

}
