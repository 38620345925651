import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../../services/user.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit {

  s3_url = environment.s3_url;

  jobId='';
  jobDetailsData: any;


  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Job Details | Remotelly');
    this.jobId = this.route.snapshot.params['id'];
    this.getJobDetails();
  }

  getJobDetails(){
    console.log("job Id:",this.jobId)
    this.userService.getjobByIdNew(this.jobId)
    .subscribe(data=> {
      if(data['status']){
        this.jobDetailsData = data['data'];
        console.log("job details:", this.jobDetailsData);
      }
    })
  }

}
