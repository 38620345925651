import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminService } from '../../../../services/admin-service/admin.service';
import { environment } from '../../../../../environments/environment';
import { UserService } from '../../../../services/user.service';

@Component({
  selector: 'app-view-blog-details',
  templateUrl: './view-blog-details.component.html',
  styleUrls: ['./view-blog-details.component.scss']
})
export class ViewBlogDetailsComponent implements OnInit {

  blogData: any;
  url = environment.s3_url

  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    let id = this.route.snapshot.params['id']
    console.log('blog id:', id);
    this.getDetails(id);
    this.spinner.show('viewBlog');

  }

  getDetails(id: string) {
    this.adminService.getBlogDetails({ id: id })
      .subscribe(response => {
        if (response['status']) {
          this.blogData = response['data'];
          this.spinner.hide('viewBlog');
        } else {
          this.spinner.hide('viewBlog');
        }
      })
  }

}
